import { useSelector } from "react-redux"
import { FcGoogle } from "react-icons/fc"

const SocialAccount = () => {
    const { user } = useSelector((state) => state.auth)

    const handleClick = () => {
        window.location.href = process.env.REACT_APP_API_GOOGLE_OAUTH2_ACCOUNTCHANGE_URL
    }
    return (
        <div>
            <div className="text-sm">
                <div className="flex flex-wrap max-smallest:flex-col smallest:justify-between gap-x-3 gap-y-4">
                    <div className="text-versich-dark-blue text-sm" style={{ overflowWrap: "anywhere" }}>
                        <span className="font-medium opacity-80">Social Account</span>
                        <div className="flex max-smaller:flex-col smaller:items-center justify-center gap-2.5 mt-2.5">
                            <FcGoogle className="w-6 h-6 sm401:w-8 sm401:h-8" />
                            <div className="font-semibold">{user.email}</div>
                        </div>
                    </div>
                    <div className="flex items-end">
                        <button
                            className="bg-white hover:bg-sky-50 hover:text-versich-blue-hover text-versich-blue font-medium py-2 px-3 border border-versich-blue hover:border-versich-blue-hover rounded-lg"
                            type="button"
                            onClick={handleClick}
                        >
                            <span className="text-xs">Change account</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SocialAccount