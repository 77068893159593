import { createAsyncThunk } from "@reduxjs/toolkit"

import * as api from "../../api"
import apiErrorParser from "../../utils/apiErrorParser"

export const freelancerVerifyAskForReviewLinkAsync = createAsyncThunk("account/freelancer/verifyAskForReviewLink", async ({ token, csrfToken }) => {
    try {
        const { data } = await api.freelancerVerifyAskForReviewLink({ token, csrfmiddlewaretoken: csrfToken })
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})