import { useSelector, useDispatch } from "react-redux"
import { useForm, FormProvider } from "react-hook-form"
import { useState, useEffect } from "react"

import curvedEditIcon from "../../../../assets/svgs/curved-edit.svg"
import facebookIcon from "../../../../assets/social-icons/facebook_color.svg"
import xIcon from "../../../../assets/social-icons/x_color.svg"
import linkedinIcon from "../../../../assets/social-icons/linkedin_color.svg"
import pinterestIcon from "../../../../assets/social-icons/pinterest_color.svg"
import tiktokIcon from "../../../../assets/social-icons/tiktok_color.svg"
import instagramIcon from "../../../../assets/social-icons/instagram_color.svg"
import DialogBox, { closeDialog, getDialog, openDialog } from "../../../DialogBox"
import FormLoader from "../../../loader/FormLoader"
import Close from "../../../svgs/Close"
import CSRFTokenField from "../../../Inputs/CSRFTokenField"
import InputText from "../../../Inputs/InputTextField"
import toastify from "../../../../utils/toastify"
import { businessSocialProfilesUpdateAsync } from "../../../../slices/business/social-profiles"
import { TOASTTYPE_ERROR } from "../../../../assets/constants"

const SocialProfiles = () => {
    const { user } = useSelector((state) => state.auth)
    const methods = useForm()

    const [csrfToken, setCsrfToken] = useState("")
    const [facebook, setFacebook] = useState("")
    const [xLink, setXlink] = useState("")
    const [linkedin, setLinkedin] = useState("")
    const [pinterest, setPinterest] = useState("")
    const [tiktok, setTiktok] = useState("")
    const [instagram, setInstagram] = useState("")
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    // Pre-fill Form
    const resetForm = () => {
        setFacebook(user.business.socialProfiles.data.facebook || "")
        setXlink(user.business.socialProfiles.data.x || "")
        setLinkedin(user.business.socialProfiles.data.linkedin || "")
        setPinterest(user.business.socialProfiles.data.pinterest || "")
        setTiktok(user.business.socialProfiles.data.tiktok || "")
        setInstagram(user.business.socialProfiles.data.instagram || "")
    }
    useEffect(() => {
        resetForm()
    }, [user])

    // Close dialog
    const customCloseDialog = (e) => {
        resetForm()
        closeDialog(e)
    }

    const dispatch = useDispatch()
    const onSubmit = async (data, event) => {
        try {
            const isValid = await methods.trigger()
            if (!isValid) {
                toastify("Form validation failed", TOASTTYPE_ERROR)
                return methods.reset()
            }
            const formData = {
                facebook: data.facebook,
                x: data.xLink,
                linkedin: data.linkedin,
                pinterest: data.pinterest,
                tiktok: data.tiktok,
                instagram: data.instagram,
                token: csrfToken,
            }
            setLoading(true)
            await dispatch(businessSocialProfilesUpdateAsync(formData))
                .unwrap()
                .then(() => {
                    const dialog = getDialog(event.target)
                    if (dialog) {
                        closeDialog(null, dialog)
                    }
                    methods.reset()
                    return setLoading(false)
                })
                .catch(error => {
                    const data = JSON.parse(error?.message)
                    return setErrorMsg(data.message)
                })
                .finally(() => setLoading(false))
        } catch (error) {
            console.error("Form submission error:", error)
        }
    }
    return (
        <div className="bg-white text-start p-4 sm:p-5 shadow-card5 rounded-lg">
            <div className="font-medium flex max-sm401:flex-col sm401:justify-between gap-x-3 gap-y-1.5 py-3 border-b border-[#EFEEEE99]">
                <span className="text-versich-dark-blue text-xl">Social Profiles</span>
                <button className="inline-flex items-center gap-1 hover:opacity-80" onClick={openDialog} data-parent-id={socialProfilesEditDialog}>
                    <img className="w-[18px] h-[18px]" src={curvedEditIcon} alt="" data-parent-id={socialProfilesEditDialog} />
                    <span className="text-versich-light-blue text-xs" data-parent-id={socialProfilesEditDialog}>Edit</span>
                </button>
            </div>
            <div className="text-versich-dark-blue my-5 space-y-8">
                <div className="grid grid-cols-1 sm:max-md:grid-cols-2 lg900:max-xl1080:grid-cols-2 xl:grid-cols-2 gap-x-3 gap-y-6">
                    <div className="text-sm">
                        <p className="font-medium opacity-80 mb-[7px]">Facebook</p>
                        <div className="font-semibold">{user.business.socialProfiles.data.facebook || "-"}</div>
                    </div>
                    <div className="text-sm">
                        <p className="font-medium opacity-80 mb-[7px]">X</p>
                        <div className="font-semibold">{user.business.socialProfiles.data.x || "-"}</div>
                    </div>
                </div>
                <div className="grid grid-cols-1 sm:max-md:grid-cols-2 lg900:max-xl1080:grid-cols-2 xl:grid-cols-2 gap-x-3 gap-y-6">
                    <div className="text-sm">
                        <p className="font-medium opacity-80 mb-[7px]">Linkedin</p>
                        <div className="font-semibold">{user.business.socialProfiles.data.linkedin || "-"}</div>
                    </div>
                    <div className="text-sm">
                        <p className="font-medium opacity-80 mb-[7px]">Pinterest</p>
                        <div className="font-semibold">{user.business.socialProfiles.data.pinterest || "-"}</div>
                    </div>
                </div>
                <div className="grid grid-cols-1 sm:max-md:grid-cols-2 lg900:max-xl1080:grid-cols-2 xl:grid-cols-2 gap-x-3 gap-y-6">
                    <div className="text-sm">
                        <p className="font-medium opacity-80 mb-[7px]">Tiktok</p>
                        <div className="font-semibold">{user.business.socialProfiles.data.tiktok || "-"}</div>
                    </div>
                    <div className="text-sm">
                        <p className="font-medium opacity-80 mb-[7px]">Instagram</p>
                        <div className="font-semibold">{user.business.socialProfiles.data.instagram || "-"}</div>
                    </div>
                </div>
            </div>
            <DialogBox
                id={socialProfilesEditDialog}
                className="backdrop:bg-[#1717253D] backdrop:backdrop-blur-sm max-md:max-w-lg lg:w-3/4 2xl:!w-2/3 !max-w-4xl"
                toastStacked={false}
                hideClose={true}
                customCloseEvent={customCloseDialog}
            >
                <div className="space-y-[34px] p-2.5 md:p-4">
                    <div className="flex gap-1.5">
                        <div className="text-versich-dark-blue flex-1">
                            <div className="text-xl font-semibold">Social Profiles</div>
                            <div className="text-sm mt-0.5">Manage your business social profiles</div>
                        </div>
                        <div id="dialog_closebtn" className="text-right">
                            <button type="button" className="m-1 p-1" onClick={customCloseDialog} data-parent-id={socialProfilesEditDialog}>
                                <Close className="w-4 h-4" />
                            </button>
                        </div>
                    </div>
                    <FormProvider {...methods}>
                        <form className="space-y-[34px]" method="dialog" onSubmit={methods.handleSubmit(onSubmit)} data-parent-id={socialProfilesEditDialog}>
                            <div className="space-y-3">
                                <div className="grid grid-cols-1 gap-x-10 gap-5 md:grid-cols-2">
                                    <div className="flex items-center gap-3">
                                        <img className="w-[30px] h-[30px]" src={facebookIcon} alt="" />
                                        <div className="flex-1">
                                            <InputText name="facebook" inputType="url" placeholder="Enter Facebook Link" onChange={(e) => setFacebook(e.target.value)} defaultValue={facebook} />
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-3">
                                        <img className="w-[30px] h-[30px]" src={xIcon} alt="" />
                                        <div className="flex-1">
                                            <InputText name="xLink" inputType="url" placeholder="Enter X Link" onChange={(e) => setXlink(e.target.value)} defaultValue={xLink} />
                                        </div>
                                    </div>
                                </div>
                                <div className="grid grid-cols-1 gap-x-10 gap-5 md:grid-cols-2">
                                    <div className="flex items-center gap-3">
                                        <img className="w-[30px] h-[30px]" src={linkedinIcon} alt="" />
                                        <div className="flex-1">
                                            <InputText name="linkedin" inputType="url" placeholder="Enter Linkedin Link" onChange={(e) => setLinkedin(e.target.value)} defaultValue={linkedin} />
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-3">
                                        <img className="w-[30px] h-[30px]" src={pinterestIcon} alt="" />
                                        <div className="flex-1">
                                            <InputText name="pinterest" inputType="url" placeholder="Enter Pinterest Link" onChange={(e) => setPinterest(e.target.value)} defaultValue={pinterest} />
                                        </div>
                                    </div>
                                </div>
                                <div className="grid grid-cols-1 gap-x-10 gap-5 md:grid-cols-2">
                                    <div className="flex items-center gap-3">
                                        <img className="w-[30px] h-[30px]" src={tiktokIcon} alt="" />
                                        <div className="flex-1">
                                            <InputText name="tiktok" inputType="url" placeholder="Enter Tiktok Link" onChange={(e) => setTiktok(e.target.value)} defaultValue={tiktok} />
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-3">
                                        <img className="w-[30px] h-[30px]" src={instagramIcon} alt="" />
                                        <div className="flex-1">
                                            <InputText name="instagram" inputType="url" placeholder="Enter Instagram Link" onChange={(e) => setInstagram(e.target.value)} defaultValue={instagram} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                            {errorMsg && <div className="text-sm text-red-500 text-left mt-8 mb-4">{errorMsg}</div>}
                            <div className="text-sm font-semibold w-full flex max-sm401:flex-col justify-end gap-3 md:gap-5">
                                <button className="bg-white hover:opacity-80 text-versich-light-blue border border-versich-light-blue py-2.5 md:py-3 px-8 md:px-10 rounded-lg" type="button" onClick={customCloseDialog} data-parent-id={socialProfilesEditDialog}>Cancel</button>
                                <button className="bg-versich-blue hover:bg-versich-blue-hover disabled:bg-versich-blue-hover text-white disabled:opacity-70 py-2.5 md:py-3 px-8 md:px-10 rounded-lg disabled:cursor-not-allowed" type="submit">Update</button>
                            </div>
                        </form>
                    </FormProvider>
                </div>
                {loading && <FormLoader />}
            </DialogBox>
        </div>
    )
}
const socialProfilesEditDialog = "socialprofiles-edit-dialog"
export default SocialProfiles