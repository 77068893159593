
const People = ({ className }) => {
    return (
        <svg className={className} width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.0958 6.49461C13.0461 6.48751 12.9964 6.48751 12.9466 6.49461C11.8455 6.45909 10.9717 5.55686 10.9717 4.44861C10.9717 3.31905 11.8881 2.39551 13.0248 2.39551C14.1543 2.39551 15.0779 3.31195 15.0779 4.44861C15.0708 5.55686 14.197 6.45909 13.0958 6.49461Z" />
            <path d="M15.4117 11.4177C14.616 11.9505 13.5007 12.1494 12.4706 12.0144C12.7405 11.4319 12.8826 10.7854 12.8897 10.1034C12.8897 9.39299 12.7334 8.7181 12.4351 8.12845C13.4865 7.98637 14.6018 8.18528 15.4046 8.7181C16.5271 9.45693 16.5271 10.6717 15.4117 11.4177Z" />
            <path d="M5.21715 6.49461C5.26688 6.48751 5.3166 6.48751 5.36633 6.49461C6.46748 6.45909 7.34129 5.55686 7.34129 4.44861C7.34129 3.31195 6.42485 2.39551 5.28819 2.39551C4.15862 2.39551 3.24219 3.31195 3.24219 4.44861C3.24219 5.55686 4.116 6.45909 5.21715 6.49461Z" />
            <path d="M5.29552 10.1035C5.29552 10.7926 5.44471 11.4462 5.71466 12.0359C4.71298 12.1424 3.66866 11.9293 2.90141 11.4249C1.77896 10.679 1.77896 9.46417 2.90141 8.71823C3.66156 8.20673 4.73429 8.00071 5.74308 8.11438C5.45181 8.71113 5.29552 9.38602 5.29552 10.1035Z" />
            <path d="M9.2525 12.2487C9.19566 12.2416 9.13172 12.2416 9.06779 12.2487C7.76062 12.2061 6.71631 11.1334 6.71631 9.812C6.72341 8.46221 7.81035 7.36816 9.16725 7.36816C10.517 7.36816 11.6111 8.46221 11.6111 9.812C11.604 11.1334 10.5668 12.2061 9.2525 12.2487Z" />
            <path d="M6.94371 13.7192C5.87098 14.4367 5.87098 15.616 6.94371 16.3264C8.16562 17.1434 10.169 17.1434 11.3909 16.3264C12.4636 15.6089 12.4636 14.4296 11.3909 13.7192C10.1761 12.9022 8.17273 12.9022 6.94371 13.7192Z" />
        </svg>

    )
}
export default People