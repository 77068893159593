import Meta from "../Meta"
import SideNavbar from "../Navbar/DashSideNavbar"
import Header from "./Header/Header"
import { SS_VERIFICATION_STATUS } from "../../assets/constants"

const metaData = {
    title: "Dashboard",
    description: "VersiMarket user dashboard",
    url: `${process.env.REACT_APP_CLIENT_URL}/dashboard/`
}

const DashboardLayout = ({ children, metadata = metaData, activePage, navlinks }) => {

    // Clean session cookie
    window.sessionStorage.removeItem(SS_VERIFICATION_STATUS.key)
    return (
        <div className="bg-[#FBFBFF] flex">
            <Meta data={metadata} />
            <SideNavbar navlinks={navlinks} activePage={activePage} />
            <div className="w-full h-svh flex flex-col">
                <Header />
                <div className="flex-grow w-full h-full pt-2.5 px-[14px] pb-5 space-y-2.5 overflow-y-auto">
                    {children}
                </div>
            </div>
        </div>
    )
}
export default DashboardLayout