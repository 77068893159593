import { useDispatch } from "react-redux"
import { useNavigate, Link } from "react-router-dom"

import logo from "../../assets/logo.png"
import settingIcon from "../../assets/icons/dashboard/setting.svg"
import logoutIcon from "../../assets/icons/dashboard/login.svg"
import { logoutUser } from "../../actions/auth"
import { homePath, accountSettingsPath } from "../../assets/constants"

const Navlink = ({ section, activePage, onClick }) => {
    return (
        <div className={`mb-1 cursor-pointer rounded-lg relative group/navmenu-item ${activePage === section.name ? "bg-[#F0F2FF]" : ""}`}>
            <div className="flex flex-row justify-between items-center py-2.5 px-4 cursor-pointer" onClick={onClick}>
                <div className="flex flex-row justify-center items-center">
                    <span className="mr-3">
                        <img
                            className={activePage === section.name ? "" : "brightness-0 opacity-50 group-hover/navmenu-item:brightness-100 group-hover/navmenu-item:opacity-100"}
                            src={section.image}
                            alt={section.name}
                            width={18}
                            height={18}
                        />
                    </span>
                    <span className={`font-medium text-sm ${activePage === section.name ? "text-versich-light-blue" : "text-[#475569] group-hover/navmenu-item:text-versich-light-blue"}`}>
                        {section.name}
                    </span>
                </div>
            </div>
        </div>
    )
}

const DashSideNavbar = ({ navlinks, activePage }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const handleLogout = () => {
        dispatch(logoutUser())
    }
    return (<>
        <div id="dh-sidenavbar" className="bg-white min-w-[280px] h-auto min-h-svh text-zinc-700 py-5 px-5 flex flex-col gap-7 relative shadow-thin">
            <div>
                <Link to={homePath} className="flex items-center justify-center">
                    <img src={logo} alt="logo" className="w-10 md:w-12 xl:w-14" />
                    &nbsp;
                    <span className="font-bold text-lg md:text-xl text-versich-dark-blue">VersiMarket</span>
                </Link>
            </div>
            <div className="flex flex-col overflow-y-auto">
                <div className="w-full">
                    {Object.keys(navlinks).map((section, index) =>
                        section !== "Others" && (
                            <Navlink section={navlinks[section]} activePage={activePage} onClick={() => navigate(navlinks[section].url)} key={index} />
                        ))}
                </div>
                <div className="bg-[#E2E8F0] w-[95%] h-[1px] my-4 mx-auto" />
                <div className="w-full">
                    <div className="text-[#94A3B8] text-xs font-medium text-left mb-1 py-1.5 px-4">Others</div>
                    <Navlink section={{ name: "Account Settings", image: settingIcon }} activePage={activePage} onClick={() => navigate(accountSettingsPath)} />
                    <Navlink section={{ name: "Logout", image: logoutIcon }} activePage={activePage} onClick={() => handleLogout()} />
                </div>
            </div>
        </div>
        <div id="dh-sidenavbar-float" />
    </>
    )
}
export default DashSideNavbar