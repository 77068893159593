import { createAsyncThunk } from "@reduxjs/toolkit"

import * as api from "../../api"
import { getUser } from "../../actions/auth"
import apiErrorParser from "../../utils/apiErrorParser"

export const businessReviewAndReferencesSetAsync = createAsyncThunk("account/business/reviewAndReferencesSet",
    async ({ firstname, lastname, companyname, email, review, ratings, token }, { dispatch }) => {
        try {
            const { data } = await api.businessReviewAndReferencesSet({ firstname, lastname, companyname, email, review, ratings, csrfmiddlewaretoken: token })
            if (data.status !== "success") {
                throw Error(JSON.stringify(data))
            }
            await dispatch(getUser())
            return data
        }
        catch (error) {
            throw apiErrorParser(error)
        }
    })

export const businessVerifyAskForReviewLinkAsync = createAsyncThunk("account/business/verifyAskForReviewLink", async ({ token, csrfToken }) => {
    try {
        const { data } = await api.businessVerifyAskForReviewLink({ token, csrfmiddlewaretoken: csrfToken })
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})