import homeIcon from "../../assets/icons/dashboard/home.svg"
import folderIcon from "../../assets/icons/dashboard/folder.svg"
import chatIcon from "../../assets/icons/dashboard/chat.svg"
import documentIcon from "../../assets/icons/dashboard/document.svg"
import {
    dashboardPath, allMessagePath, allRequestPath, privateServicesPath,
    buyVersiGemsPath, businessPricingPath,
} from "../../assets/constants"

export const ClientMenu = {
    "dashboard": {
        "id": 1,
        "name": "Dashboard",
        "url": dashboardPath,
        "image": homeIcon,
    },
    "myservices": {
        "id": 2,
        "name": "My Requests",
        "url": allRequestPath,
        "image": documentIcon,
    },
}

export const FreelancerMenu = {
    "dashboard": {
        "id": 1,
        "name": "Dashboard",
        "url": dashboardPath,
        "image": homeIcon,
    },
    "myservices": {
        "id": 2,
        "name": "My Services",
        "url": privateServicesPath,
        "image": folderIcon,
    },
    "messages": {
        "id": 3,
        "name": "Messages",
        "url": allMessagePath,
        "image": chatIcon,
    },
    "versigems": {
        "id": 4,
        "name": "VersiGems",
        "url": buyVersiGemsPath,
        "image": documentIcon,
    },
}

export const BusinessMenu = {
    "dashboard": {
        "id": 1,
        "name": "Dashboard",
        "url": dashboardPath,
        "image": homeIcon,
    },
    "messages": {
        "id": 3,
        "name": "Messages",
        "url": allMessagePath,
        "image": chatIcon,
    },
    "versigems": {
        "id": 4,
        "name": "Pricing",
        "url": businessPricingPath,
        "image": documentIcon,
    },
}