import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"

import ServicesList from "../../assets/ServicesList"
import AddPlus from "../svgs/AddPlus"
import TextFieldInput from "./TextFieldInput"
import TextAreaLimitFieldInput from "./TextAreaLimitFieldInput"
import DropdownFieldInput from "./DropdownFieldInput"
import DateRangeFieldInput from "./DateRangeFieldInput"
import ImagePreviewInput from "./ImagePreviewInput"
import { freelancerServiceProjectAllProjectSizeAsync } from "../../slices/freelancer/serviceProject"
import { clearBlobImages } from "./utils"

const ServiceDetailsTile = ({ data, submitted, setDetail }) => {
    const [projectName, setProjectName] = useState(data?.project?.projectName || "")
    const [projectNameError, setProjectNameError] = useState(false)
    const [desc, setDesc] = useState(data?.project?.description || null)
    const [descError, setDescError] = useState(false)
    const [clientName, setClientName] = useState(data?.project?.clientName || "")
    const [clientNameError, setClientNameError] = useState(false)
    const [projectSizeOptions, setProjectSizeOptions] = useState([])
    const [projectSize, setProjectSize] = useState(data?.project?.projectSize?.uid || "")
    const [projectSizeError, setProjectSizeError] = useState(false)
    const [projectSchedule, setProjectSchedule] = useState({
        startDate: data?.project?.projectStart || null,
        endDate: data?.project?.projectEnd || null
    })
    const [projectScheduleError, setProjectScheduleError] = useState(false)
    const [images, setImages] = useState([])
    const [previewUrls, setPreviewUrls] = useState(data?.project?.images || [])

    // Set Icon & Color
    const [service, setService] = useState(null)
    useEffect(() => {
        const services = ServicesList.filter(s => s.uid === data.uid)
        if (services.length > 0) {
            setService(services[0])
        }
    }, [data])

    // Load dropdown values
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(freelancerServiceProjectAllProjectSizeAsync(`?orderby=id`))
            .unwrap()
            .then((data) => {
                setProjectSizeOptions(data.payload)
            })
    }, [])

    // Track changes in service details
    const updateDetails = (add) => {
        setDetail(preDetail => {
            let details
            if (add) {
                details = {
                    ...preDetail, [data.uid]: {
                        projectname: projectName,
                        description: desc,
                        clientname: clientName,
                        projectsize: projectSize,
                        projectschedule: projectSchedule,
                        images,
                        ...(previewUrls && { urls: previewUrls }),
                    }
                }
            }
            else {
                let d = Object.entries({ ...preDetail }).filter(([key, value]) => key !== data.uid)
                if (d.length > 0) {
                    details = Object.fromEntries(d)
                }
            }
            return details
        })
    }
    useEffect(() => {
        updateDetails(desc !== null && [...images])
    }, [projectName, desc, clientName, projectSize, projectSchedule, images, previewUrls])

    // Toggle service details
    const handleDescDetails = () => {
        if (desc === null) {
            updateDetails(true)
            setDesc("")
        }
        else {
            setDesc(null)
            updateDetails(false)
        }
    }

    useEffect(() => {
        setProjectNameError(submitted && projectName === "")
        setDescError(submitted && desc === "")
        setClientNameError(submitted && clientName === "")
        setProjectSizeError(submitted && projectSize === "")
        setProjectScheduleError(submitted && (projectSchedule.startDate === null || projectSchedule.endDate === null))
    }, [submitted])

    // Clear image urls
    useEffect(() => {
        return (
            clearBlobImages()
        )
    }, [])
    return (
        service &&
        <div className="pt-5 pb-7 border-b border-[#EFEEEE99]">
            <div className={`${service.bgColor} bg-opacity-[0.08] text-start flex max-md:flex-col gap-5 py-4 sm:py-6 px-6 sm:px-12 rounded-lg`}>
                <div>
                    <img className="min-w-10 h-10 smaller:min-w-14 smaller:h-14 drop-shadow contrast-150" src={service.icon} alt={data.title} />
                </div>
                <div className="flex-grow">
                    <h3 className="text-versich-dark-blue font-medium">{data.title}</h3>
                    <p className="text-versich-label text-sm mt-[5px]">You’ve signed up for</p>
                </div>
                <div className="flex items-center">
                    <button className="hover:opacity-80 inline-flex flex-wrap items-center gap-x-1.5 group" type="button" onClick={handleDescDetails}>
                        <AddPlus className={"group-hover:[&>*]:opacity-80 smaller:max-lg:w-5 smaller:max-lg:h-5 w-6 h-6 transition-transform " + (
                            desc !== null ? "[&>*]:stroke-red-500 rotate-45" : "[&>*]:stroke-versich-light-blue"
                        )} />
                        {desc !== null
                            ? <span className="text-red-500 text-sm font-medium">Remove Details</span>
                            : <span className="text-versich-light-blue text-sm font-bold">Add Details</span>
                        }
                    </button>
                </div>
            </div>
            {desc !== null && <>
                <div className="mt-5">
                    <TextFieldInput
                        name="projectName"
                        inputType="text"
                        placeholder="Enter project title"
                        label="Project Name"
                        onChange={(e) => setProjectName(e.target.value)}
                        defaultValue={projectName}
                        required="Project name is required"
                        error={projectNameError}
                    />
                </div>
                <div className="mt-5">
                    <TextAreaLimitFieldInput
                        name="description"
                        label="Description"
                        placeholder="What you did in the project"
                        onChange={(e) => { setDescError(false); setDesc(e.target.value) }}
                        defaultValue={desc}
                        required="Please enter a description"
                        error={descError}
                    />
                </div>
                <div className="grid grid-cols-1 gap-x-3 gap-5 md:grid-cols-2 mt-5">
                    <TextFieldInput
                        name="clientName"
                        inputType="text"
                        placeholder="Enter client name"
                        label="Client Name"
                        onChange={(e) => setClientName(e.target.value)}
                        defaultValue={clientName}
                        required="Client name is required"
                        error={clientNameError}
                    />
                    <DropdownFieldInput
                        name="projectSize"
                        label="Project Size"
                        options={projectSizeOptions}
                        value={projectSize}
                        placeholder="Select"
                        onChange={(e) => setProjectSize(e.target.value)}
                        required="Project Size is required"
                        error={projectSizeError}
                    />
                </div>
                <div className="mt-5">
                    <DateRangeFieldInput
                        name="projectSchedule"
                        label="Project Schedule"
                        value={projectSchedule}
                        placeholder="Select Duration"
                        onChange={val => setProjectSchedule(val)}
                        required="Project Schedule is required"
                        error={projectScheduleError}
                    />
                </div>
                <div className="space-y-1">
                    <div className="text-versich-dark-blue block font-medium text-start">
                        <span className="text-base inline-block mt-5 mb-2">Project Photos</span>
                        <div className="grid grid-cols-2 lg800:grid-cols-4 gap-3">
                            {[...Array(4)].map((_, index) => (
                                <ImagePreviewInput name="projectphoto" maxSizeInMb={10} defaultUrl={previewUrls[index]} setDefaultUrls={setPreviewUrls} setFiles={setImages} key={index} />
                            ))}
                        </div>
                    </div>
                </div>
            </>}
        </div>
    )
}
export default ServiceDetailsTile