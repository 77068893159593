import { useParams, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useState, useEffect } from "react"
import { BeatLoader } from "react-spinners"

import ErrorPage from "../../components/ErrorPage/ErrorPage"
import Navbar from "../../components/Navbar/Navbar"
import Meta from "../../components/Meta"
import chevronRightIcon from "../../assets/svgs/chevron-right.svg"
import profileIcon from "../../assets/icons/dashboard/profile.svg"
import discoveryIcon from "../../assets/icons/dashboard/discovery.svg"
import chartIcon from "../../assets/icons/dashboard/chart.svg"
import noPictureIcon from "../../assets/images/no-picture-taking.png"
import ImageSlider from "../../components/ImageSlider/ImageSlider"
import ServicesList from "../../assets/ServicesList"
import { freelancerPublicServicesProjectsPath } from "../../assets/constants"
import { getPublicFreelancerServiceProjectDetailsAsync } from "../../slices/freelancer/public"
import { getEventDate } from "../../utils/date"

const AboutCard = ({ data }) => {
    const scheduleDate = getEventDate(data.projectStart, data.projectEnd, true)
    return (
        <div className="bg-white text-start p-4 sm:p-5 border border-[#F8F8F8] rounded-lg">
            <div className="text-versich-dark-blue font-bold pb-4 border-b border-[#EDF0F5]">About</div>
            <div className="mt-5 p-1 space-y-6">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg900:max-xl1080:grid-cols-3 xl:grid-cols-3 gap-x-3 gap-y-6">
                    <div className="flex gap-[26px]">
                        <div>
                            <div className="bg-brandeis-blue/[0.08] inline-flex items-center justify-center min-w-[43px] min-h-[43px] rounded-[10px]">
                                <img className="w-6 h-6" src={profileIcon} alt="" />
                            </div>
                        </div>
                        <div className="text-versich-dark-blue text-sm">
                            <p className="font-semibold mb-[3px]">Client</p>
                            <div>{data.clientName}</div>
                        </div>
                    </div>
                    <div className="flex gap-[26px]">
                        <div>
                            <div className="bg-brandeis-blue/[0.08] inline-flex items-center justify-center min-w-[43px] min-h-[43px] rounded-[10px]">
                                <img className="w-6 h-6" src={discoveryIcon} alt="" />
                            </div>
                        </div>
                        <div className="text-versich-dark-blue text-sm">
                            <p className="font-semibold mb-[3px]">Project Schedule</p>
                            <div>{scheduleDate}</div>
                        </div>
                    </div>
                    <div className="flex gap-[26px]">
                        <div>
                            <div className="bg-brandeis-blue/[0.08] inline-flex items-center justify-center min-w-[43px] min-h-[43px] rounded-[10px]">
                                <img className="w-6 h-6" src={chartIcon} alt="" />
                            </div>
                        </div>
                        <div className="text-versich-dark-blue text-sm">
                            <p className="font-semibold mb-[3px]">Project Size</p>
                            <div>{data.projectSize.title}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const FreelancerServiceProjectDetails = () => {
    const { id, sid, pid } = useParams()
    const [user, setUser] = useState(undefined)
    const [metadata, setMetadata] = useState()

    // Set Service title
    const [service, setService] = useState(null)
    useEffect(() => {
        const services = ServicesList.filter(s => s.uid === sid)
        if (services.length > 0) {
            setService(services[0])
        }
    }, [id])

    const dispatch = useDispatch()
    const fetchFreelancerServiceProjectDetails = async () => {
        dispatch(getPublicFreelancerServiceProjectDetailsAsync(pid))
            .unwrap()
            .then((data) => {
                setUser(data.payload)
                const fullName = data.payload.about.firstName + " " + data.payload.about.lastName
                const projectName = data.payload.project.projectName
                setMetadata({
                    title: `${fullName} - ${projectName} | ${service.name} Project`,
                    description: `${fullName}'s project details of ${projectName} in ${service.name.toLowerCase()} service`,
                    url: `${process.env.REACT_APP_CLIENT_URL}/freelancer/${id}/services/${sid}/projects/${pid}/details/`
                })
            })
            .catch(() => setUser(null))
    }

    useEffect(() => {
        if (!service) {
            return
        }
        setMetadata({
            title: `${service.name} Service Project Details`,
            description: `Public freelancer ${service.name.toLowerCase()} service project details`,
            url: `${process.env.REACT_APP_CLIENT_URL}/freelancer/${id}/services/${sid}/projects/${pid}/details/`
        })
        fetchFreelancerServiceProjectDetails()
    }, [service, id])

    const navigate = useNavigate()
    const freelancerServiceProjectsPath = freelancerPublicServicesProjectsPath.replace(":uid", id).replace(":sid", sid)
    return service && (
        user !== null
            ? <>
                <Navbar />
                {user === undefined
                    ? <div className="w-full h-80 flex justify-center items-center">
                        <BeatLoader loading={true} color="#1D88ED" size={10} />
                    </div>
                    : <>
                        {metadata && <Meta data={metadata} />}
                        <div className="bg-[#F5F7FF] py-8 px-8 md:px-16 lg:px-28 min-h-[calc(100vh-76px)] md:min-h-[calc(100vh-80px)]">
                            <div className="max_screen_w h-full">
                                <div className="flex flex-col gap-y-2.5 h-full">
                                    <div className="space-y-1.5">
                                        <div className="flex justify-start pt-1">
                                            <button className="inline-flex items-center gap-0.5" onClick={() => navigate(freelancerServiceProjectsPath)}>
                                                <img src={chevronRightIcon} alt="" />
                                                <span className="text-versich-light-blue text-sm font-semibold">Back</span>
                                            </button>
                                        </div>
                                        <div className="text-versich-dark-blue text-start flex-1 space-y-[2px]">
                                            <div className="text-lg lg:text-xl font-semibold">{user.project.projectName}</div>
                                            <div className="text-sm">View project breakdown</div>
                                        </div>
                                    </div>
                                    <div className="flex-grow">
                                        <div className="bg-white text-start space-y-5 mb-5 py-6 p-4 sm:p-5 shadow-card4 rounded-lg">
                                            <div className="text-versich-dark-blue space-y-[30px]">
                                                <div className="space-y-[14px] pb-4 border-b border-[#D0D0D04D]">
                                                    <div className="text-lg font-semibold">{user.project.projectName} | {user.project.service.title}</div>
                                                </div>
                                                <div className="text-sm font-normal space-y-3 pb-[30px] border-b border-[#D0D0D04D]">{user.project.description.split("\n").map((desc, index) => <p key={index}>{desc}</p>)}</div>
                                            </div>
                                            <AboutCard data={user.project} />
                                            <div className="grid grid-cols-1 gap-y-5 pb-10">
                                                <div className="text-versich-dark-blue text-lg font-semibold">Images from project</div>
                                                {user.project.images && user.project.images.length > 0
                                                    ? user.project.images.length > 1
                                                        ? <div className="max-w-full xl:max-w-[800px] mx-auto aspect-video rounded-[11px]">
                                                            <ImageSlider images={user.project.images} />
                                                        </div>
                                                        : <div className="w-full h-full max-w-full xl:max-w-[800px] mx-auto rounded-[11px] overflow-hidden">
                                                            <img className="w-full rounded-[11px]" src={user.project.images[0]} />
                                                        </div>
                                                    : <div className="flex flex-col items-center justify-center w-full h-full p-4 py-16 border border-brandeis-blue/[0.08] rounded-[11px]" title="No images found">
                                                        <img className="w-32" src={noPictureIcon} />
                                                        <span className="text-versich-label text-sm">No images found</span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </>
            : <ErrorPage status={404} />
    )
}
export default FreelancerServiceProjectDetails