import { useSelector, useDispatch } from "react-redux"
import { useState, useEffect } from "react"

import curvedEditIcon from "../../../../assets/svgs/curved-edit.svg"
import DialogBox, { closeDialog, getDialog, openDialog } from "../../../DialogBox"
import FormLoader from "../../../loader/FormLoader"
import Close from "../../../svgs/Close"
import CSRFTokenField from "../../../Inputs/CSRFTokenField"
import PasswordField from "../../../Inputs/PasswordField"
import { changePassword } from "../../../../actions/password"
import { TOASTTIMER } from "../../../../assets/constants"
import toastify from "../../../../utils/toastify"

const AccountPassword = () => {
    const { user } = useSelector((state) => state.auth)

    const [csrfToken, setCsrfToken] = useState("")
    const [oldPassword, setOldPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    // Pre-fill Form
    const resetForm = () => {
        setOldPassword("")
        setNewPassword("")
        setConfirmPassword("")
        setErrorMsg("")
    }
    useEffect(() => {
        resetForm()
    }, [user])

    // Close dialog
    const customCloseDialog = (e) => {
        resetForm()
        closeDialog(e)
    }

    const dispatch = useDispatch()
    const handleSubmit = (e) => {
        e.preventDefault()
        const submitBtn = e.target.querySelector("button[type=submit]")
        submitBtn.disabled = true

        if (oldPassword && newPassword && confirmPassword) {
            setLoading(true)
            dispatch(changePassword(oldPassword, newPassword, confirmPassword, csrfToken))
                .then((data) => {
                    const dialog = getDialog(e.target)
                    if (dialog) {
                        closeDialog(null, dialog)
                        return toastify(data.message, data.status, submitBtn)
                    }
                    setLoading(false)
                })
                .catch(error => {
                    const data = JSON.parse(error?.message)
                    setErrorMsg(data.message)
                    setTimeout(() => {
                        resetForm()
                        submitBtn.disabled = false
                    }, TOASTTIMER)
                })
                .finally(() => setLoading(false))
        }
        else {
            setError(true)
            setTimeout(() => {
                setError(false)
                submitBtn.disabled = false
            }, TOASTTIMER)
        }
    }
    return (
        <div>
            <div className="text-sm">
                <div className="flex max-smallest:flex-col smallest:items-start smallest:justify-between gap-x-3 gap-y-1.5">
                    <span className="font-medium opacity-80">Password</span>
                    <button className="font-medium inline-flex items-center gap-1 hover:opacity-80" onClick={openDialog} data-parent-id={accountPasswordEditDialog}>
                        <img className="w-[18px] h-[18px]" src={curvedEditIcon} alt="" data-parent-id={accountPasswordEditDialog} />
                        <span className="text-versich-light-blue text-xs" data-parent-id={accountPasswordEditDialog}>Edit</span>
                    </button>
                </div>
                <div className="text-versich-dark-blue text-sm mt-2.5" style={{ overflowWrap: "anywhere" }}>
                    <div className="font-medium">{"*".repeat(18)}</div>
                </div>
            </div>
            <DialogBox
                id={accountPasswordEditDialog}
                className="backdrop:bg-[#1717253D] backdrop:backdrop-blur-sm max-sm:max-w-lg"
                toastStacked={false}
                hideClose={true}
                customCloseEvent={customCloseDialog}
            >
                <div className="space-y-[34px] p-2.5 md:p-4">
                    <div className="flex gap-1.5">
                        <div className="text-versich-dark-blue flex-1">
                            <div className="text-xl font-semibold">Password Settings</div>
                            <div className="text-sm mt-0.5">Manage your password</div>
                        </div>
                        <div id="dialog_closebtn" className="text-right">
                            <button type="button" className="m-1 p-1" onClick={customCloseDialog} data-parent-id={accountPasswordEditDialog}>
                                <Close className="w-4 h-4" />
                            </button>
                        </div>
                    </div>
                    <form className="space-y-[34px]" method="dialog" onSubmit={handleSubmit} data-parent-id={accountPasswordEditDialog}>
                        <div className="space-y-3">
                            <PasswordField
                                name="password0"
                                label="Old Password"
                                placeholder="e.g. example123&_"
                                onChange={(e) => setOldPassword(e.target.value)}
                                defaultValue={oldPassword}
                                autoComplete="current-password"
                                error={{
                                    "message": "Old Password is required",
                                    "show": error
                                }}
                            />
                            <PasswordField
                                name="password1"
                                label="New Password"
                                placeholder="e.g. example123&_"
                                onChange={(e) => setNewPassword(e.target.value)}
                                defaultValue={newPassword}
                                autoComplete="new-password"
                                error={{
                                    "message": "New Password is required",
                                    "show": error
                                }}
                            />
                            <PasswordField
                                name="password2"
                                label="Confirm Password"
                                placeholder="e.g. example123&_"
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                defaultValue={confirmPassword}
                                autoComplete="new-password"
                                error={{
                                    "message": "Confirm Password is required",
                                    "show": error
                                }}
                            />
                            <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                        </div>
                        {errorMsg && <div className="text-red-500 text-sm text-left mb-4">{errorMsg}</div>}
                        <div className="text-sm font-semibold w-full flex max-sm401:flex-col justify-end gap-3 md:gap-5">
                            <button className="bg-white hover:opacity-80 text-versich-light-blue border border-versich-light-blue py-2.5 md:py-3 px-8 md:px-10 rounded-lg" type="button" onClick={customCloseDialog} data-parent-id={accountPasswordEditDialog}>Cancel</button>
                            <button className="bg-versich-blue hover:bg-versich-blue-hover disabled:bg-versich-blue-hover text-white disabled:opacity-70 py-2.5 md:py-3 px-8 md:px-10 rounded-lg disabled:cursor-not-allowed" type="submit">Update</button>
                        </div>
                    </form>
                </div>
                {loading && <FormLoader />}
            </DialogBox>
        </div>
    )
}
const accountPasswordEditDialog = "accountpassword-edit-dialog"
export default AccountPassword