import { useParams, Link } from "react-router-dom"
import { useForm, FormProvider } from "react-hook-form"
import { useState, useEffect, useRef } from "react"
import { useDispatch } from "react-redux"
import { BeatLoader } from "react-spinners"

import noReviewsIcon from "../../assets/images/no-reviews.png"
import sendIcon from "../../assets/svgs/send.svg"
import ErrorPage from "../../components/ErrorPage/ErrorPage"
import BrandBar from "../../components/Navbar/BrandBar"
import FormLoader from "../../components/loader/FormLoader"
import CSRFTokenField from "../../components/Inputs/CSRFTokenField"
import InputText from "../../components/Inputs/InputTextField"
import TextAreaField from "../../components/Inputs/TextAreaField"
import RatingSelectionField, { ratingOptions } from "../../components/Inputs/RatingSelectionField"
import toastify from "../../utils/toastify"
import { freelancerVerifyAskForReviewLinkAsync } from "../../slices/freelancer/reviewAndReferences"
import { reviewAndReferencesSubmitAsync } from "../../slices/reviewAndReferences"
import { TOASTTYPE_ERROR } from "../../assets/constants"
import { getInitials, titleCase } from "../../utils"

const UserProfile = ({ user }) => {
    const initials = getInitials(user.name)
    const accountType = titleCase(user.accountType)
    const freelancerProfilePath = `/freelancer/${user.profileId}/`
    return (
        <div className="flex justify-start">
            <Link className="inline-flex gap-x-4 items-center my-6 relative cursor-pointer group" to={freelancerProfilePath} target="_blank">
                {/* Avatar */}
                <div className="bg-[#F6F9FF] w-12 h-12 lg:h-16 lg:w-16 rounded-full">
                    {user.profile?.image
                        ? <img className="w-full h-full object-cover rounded-full" src={user.profile.image} alt="" />
                        : <div className="text-versich-darktext-color text-base lg:text-lg font-semibold flex justify-center items-center w-full h-full rounded-full shadow-sm">
                            {initials}
                        </div>
                    }
                </div>
                <div className="flex-1 flex flex-col gap-1 text-start text-versich-dark-blue font-semibold text-base lg:text-lg">
                    <span className="group-hover:text-versich-blue">{user.name}</span>
                    <span className="text-versich-blue-hover text-xs">{accountType}</span>
                </div>
            </Link>
        </div>
    )
}

const LeaveFreelancerAReview = () => {
    const { token } = useParams()
    const methods = useForm()

    const [user, setUser] = useState(undefined)
    const [csrfToken, setCsrfToken] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [review, setReview] = useState("")
    const [ratings, setRatings] = useState(0)
    const [ratingsError, setRatingsError] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const csrfTokenUpdated = useRef(false)

    const dispatch = useDispatch()
    useEffect(() => {
        !csrfTokenUpdated.current
            && token
            && (csrfTokenUpdated.current = csrfToken !== "")
            && dispatch(freelancerVerifyAskForReviewLinkAsync({ token, csrfToken }))
                .unwrap()
                .then(data => setUser(data?.payload))
                .catch(() => setUser(null))
    }, [csrfToken])

    // Pre-fill form
    useEffect(() => {
        if (user?.review) {
            setFirstName(user.review.firstName)
            setLastName(user.review.lastName)
            setCompanyName(user.review.companyName)
            setReview(user.review.review || "")
            setRatings(user.review.ratings || 0)
        }
    }, [user])

    const customHandleSubmit = (e) => {
        e.preventDefault()
        setRatingsError(ratingOptions.includes(ratings) === false)
        methods.handleSubmit(onSubmit)()
    }
    const onSubmit = async (data) => {
        try {
            const isValid = await methods.trigger()
            if (!isValid) {
                toastify("Form validation failed", TOASTTYPE_ERROR)
                return methods.reset()
            }
            if (ratingsError) {
                return methods.reset()
            }
            const formData = {
                firstname: data.firstName,
                lastname: data.lastName,
                companyname: data.companyName,
                review: data.review,
                ratings: ratings,
                token, csrfToken,
            }
            setLoading(true)
            await dispatch(reviewAndReferencesSubmitAsync(formData))
                .unwrap()
                .then(() => {
                    setSubmitted(true)
                    methods.reset()
                })
                .catch(error => {
                    const data = JSON.parse(error?.message)
                    return setErrorMsg(data.message)
                })
                .finally(() => setLoading(false))
        } catch (error) {
            console.error("Form submission error:", error)
        }
    }

    // Track 'user' for profileId
    const [profileId, setProfileId] = useState(null)
    useEffect(() => {
        user?.profileId && setProfileId(user.profileId)
    }, [user])
    return (
        token && user !== null ?
            <FormProvider {...methods}>
                <div className="bg-[#FBFBFF] min-h-svh">
                    <BrandBar />
                    <div className="max_screen_w mb-12 py-8 md:py-10 px-4 md:px-8 lg:px-16">
                        <div className="bg-white w-full max-w-[1080px] mx-auto px-4 smaller:px-8 xl:px-12 py-8 rounded-[11px] shadow-card2">
                            {submitted
                                ? <div className="w-full flex flex-col justify-center items-center">
                                    <div className="space-y-5 pb-6 border-b border-[#EFEEEE99]">
                                        <h1 className="text-versich-dark-blue text-xl sm:text-2xl font-medium flex justify-center">Review Submitted!</h1>
                                    </div>
                                    <div className="p-4 py-12">
                                        <img className="w-[180px] opacity-60" src={noReviewsIcon} alt="no reviews" />
                                    </div>
                                    <p>You have successfully submitted your review.</p>
                                    {profileId &&
                                        <Link
                                            to={`/freelancer/${profileId}/`}
                                            target="_blank"
                                            className="bg-versich-blue hover:bg-versich-blue-hover text-white text-sm font-semibold min-h-12 inline-flex items-center justify-center gap-2 mt-7 mb-4 py-2 lg:py-3 px-3.5 lg:px-5 border-2 border-versich-blue hover:border-versich-blue-hover rounded-[10px]"
                                            type="button"
                                        >
                                            <img src={sendIcon} alt="" />
                                            <span>View Profile</span>
                                        </Link>
                                    }
                                </div>
                                : <>
                                    <div className="space-y-5 pb-6 border-b border-[#EFEEEE99]">
                                        <h1 className="text-versich-dark-blue text-xl sm:text-2xl font-medium flex justify-center">Leave your Review</h1>
                                    </div>
                                    <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                                    {user === undefined ?
                                        <div className="w-full h-80 flex justify-center items-center">
                                            <BeatLoader loading={true} color="#1D88ED" size={10} />
                                        </div>
                                        : <>
                                            <UserProfile user={user} />
                                            <form onSubmit={customHandleSubmit} className="space-y-5">
                                                <div className="grid grid-cols-1 gap-x-3 gap-5 lg:grid-cols-2">
                                                    <InputText name="firstName" inputType="text" placeholder="e.g. Steve" label="First Name" onChange={(e) => setFirstName(e.target.value)} defaultValue={firstName} rules={{ required: "First name is required" }} />
                                                    <InputText name="lastName" inputType="text" placeholder="e.g. Rogers" label="Last Name" onChange={(e) => setLastName(e.target.value)} defaultValue={lastName} rules={{ required: "Last name is required" }} />
                                                </div>
                                                <InputText name="companyName" inputType="text" placeholder="e.g. Business Solution" label="Company Name" onChange={(e) => setCompanyName(e.target.value)} defaultValue={companyName} rules={{ required: "Company name is required" }} />
                                                <TextAreaField
                                                    name="review"
                                                    label="Review"
                                                    placeholder="e.g. Technology consulting with Excellence, Marketing freelancer with digital accuracy"
                                                    onChange={(e) => setReview(e.target.value)}
                                                    defaultValue={review}
                                                    rules={{ required: "Field is required" }}
                                                />
                                                <RatingSelectionField
                                                    name="ratings"
                                                    label="Ratings"
                                                    ratings={ratings}
                                                    setRatings={setRatings}
                                                    error={ratingsError}
                                                    setError={setRatingsError}
                                                />
                                                {errorMsg && <div className="text-sm text-red-500 text-left !mt-10">{errorMsg}</div>}
                                                <div className="text-sm font-semibold w-full flex justify-end gap-5 py-6">
                                                    <button className="bg-versich-blue hover:bg-versich-blue-hover text-white py-2 lg:py-3 px-6 lg:px-10 rounded-lg" type="submit">Submit Review</button>
                                                </div>
                                            </form>
                                        </>
                                    }
                                </>
                            }
                        </div>
                    </div>
                    {loading && <FormLoader />}
                </div>
            </FormProvider>
            : <ErrorPage status={404} />
    )
}
export default LeaveFreelancerAReview
