import { useSelector, useDispatch } from "react-redux"
import { useState, useEffect } from "react"

import curvedEditIcon from "../../../../assets/svgs/curved-edit.svg"
import DialogBox, { closeDialog, getDialog, openDialog } from "../../../DialogBox"
import FormLoader from "../../../loader/FormLoader"
import Close from "../../../svgs/Close"
import CSRFTokenField from "../../../Inputs/CSRFTokenField"
import ServicesList from "../../../../assets/ServicesList"
import { freelancerPrimaryServiceUpdateAsync } from "../../../../slices/freelancer/primaryService"
import { TOASTTIMER } from "../../../../assets/constants"

const PrimaryService = () => {
    const { user } = useSelector((state) => state.auth)

    const [csrfToken, setCsrfToken] = useState("")
    const [selectedService, setSelectedService] = useState("")
    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    // Pre-fill Form
    const resetForm = () => {
        user.primaryService && setSelectedService(user.primaryService.uid)
    }
    useEffect(() => {
        resetForm()
    }, [user])

    // Close dialog
    const customCloseDialog = (e) => {
        resetForm()
        closeDialog(e)
    }

    const dispatch = useDispatch()
    const handleSubmit = async (e) => {
        e.preventDefault()
        if (selectedService) {
            const formData = {
                serviceid: selectedService,
                token: csrfToken,
            }
            setLoading(true)
            await dispatch(freelancerPrimaryServiceUpdateAsync(formData))
                .unwrap()
                .then(() => {
                    const dialog = getDialog(e.target)
                    if (dialog) {
                        closeDialog(null, dialog)
                    }
                    return setLoading(false)
                })
                .catch(error => {
                    const data = JSON.parse(error?.message)
                    return setErrorMsg(data.message)
                })
                .finally(() => setLoading(false))
        }
        else {
            setError(true)
            setTimeout(() => {
                setError(false)
            }, TOASTTIMER)
        }
    }
    return (
        <div>
            <div className="pb-5 border-b border-[#EFEEEE99]/50">
                <div className="text-sm">
                    <div className="font-medium flex max-smallest:flex-col smallest:items-start smallest:justify-between gap-x-3 gap-y-1.5">
                        <span className="opacity-80">Primary Service</span>
                        <button className="inline-flex items-center gap-1 hover:opacity-80" onClick={openDialog} data-parent-id={primaryServiceEditDialog}>
                            <img className="w-[18px] h-[18px]" src={curvedEditIcon} alt="" data-parent-id={primaryServiceEditDialog} />
                            <span className="text-versich-light-blue text-xs" data-parent-id={primaryServiceEditDialog}>Edit</span>
                        </button>
                    </div>
                    <div className="mt-2.5">
                        <span className="bg-brandeis-blue/[0.08] text-versich-light-blue text-sm inline-block py-1.5 px-2.5 rounded-full">{user.primaryService?.title || "-"}</span>
                    </div>
                </div>
            </div>
            <DialogBox
                id={primaryServiceEditDialog}
                className="backdrop:bg-[#1717253D] backdrop:backdrop-blur-sm max-md:max-w-lg lg:w-3/4 2xl:!w-2/5 2xl:!max-w-4xl"
                toastStacked={false}
                hideClose={true}
                customCloseEvent={customCloseDialog}
            >
                <div className="space-y-[34px] p-2.5 md:p-4">
                    <div className="flex gap-1.5">
                        <div className="text-versich-dark-blue flex-1">
                            <div className="text-xl font-semibold">Choose your primary service</div>
                            <div className="text-sm mt-0.5">Manage your primary service</div>
                        </div>
                        <div id="dialog_closebtn" className="text-right">
                            <button type="button" className="m-1 p-1" onClick={customCloseDialog} data-parent-id={primaryServiceEditDialog}>
                                <Close className="w-4 h-4" />
                            </button>
                        </div>
                    </div>
                    <form className="space-y-[34px]" method="dialog" onSubmit={handleSubmit} data-parent-id={primaryServiceEditDialog}>
                        <div className="grid grid-cols-1 sm401:grid-cols-2 lg:grid-cols-3 gap-y-5 sm:gap-y-8 gap-x-5">
                            {ServicesList.map((service) => (
                                <div
                                    key={service.id}
                                    className={`flex flex-col justify-center items-center gap-y-2 py-4 px-3 border-[3px] ${selectedService === service.uid
                                        ? `border-versich-blue/80 bg-versich-blue/15`
                                        : `${service.bgColor} bg-opacity-[0.04] ${service.borderColor} border-opacity-[0.04] hover:${service.borderColor} hover:border-opacity-20`
                                        } cursor-pointer rounded-xl`
                                    }
                                    onClick={() => setSelectedService(service.uid)}
                                >
                                    <input className="appearance-none" name="selectedService" type="radio" value={service.uid} />
                                    <img src={service.icon} alt={service.name} className="w-12 h-12 drop-shadow contrast-150" />
                                    <span className="text-versich-dark-blue text-center text-sm font-medium">{service.name}</span>
                                </div>
                            ))}
                            <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                        </div>
                        {error && <p className="text-red-500 text-sm text-start mb-4">Please select a service.</p>}
                        {errorMsg && <div className="text-red-500 text-sm text-left mb-4">{errorMsg}</div>}
                        <div className="text-sm font-semibold w-full flex max-sm401:flex-col justify-end gap-3 md:gap-5">
                            <button className="bg-white hover:opacity-80 text-versich-light-blue border border-versich-light-blue py-2.5 md:py-3 px-8 md:px-10 rounded-lg" type="button" onClick={customCloseDialog} data-parent-id={primaryServiceEditDialog}>Cancel</button>
                            <button className="bg-versich-blue hover:bg-versich-blue-hover disabled:bg-versich-blue-hover text-white disabled:opacity-70 py-2.5 md:py-3 px-8 md:px-10 rounded-lg disabled:cursor-not-allowed" type="submit">Update</button>
                        </div>
                    </form>
                </div>
                {loading && <FormLoader />}
            </DialogBox>
        </div>
    )
}
const primaryServiceEditDialog = "primaryservice-edit-dialog"
export default PrimaryService