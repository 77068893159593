import { createAsyncThunk } from "@reduxjs/toolkit"

import * as api from "../../api"
import { getUser } from "../../actions/auth"
import apiErrorParser from "../../utils/apiErrorParser"

export const businessLocationSetAsync = createAsyncThunk("account/businessLocationSet",
    async ({
        hqaddressline1,
        hqaddressline2,
        hqphonecode,
        hqphonenumber,
        hqcity,
        hqcountryiso3,
        adaddressline1,
        adaddressline2,
        adphonecode,
        adphonenumber,
        adcity,
        adcountryiso3,
        token,
    }, { dispatch }) => {
        try {
            const { data } = await api.businessLocationSet({
                hqaddressline1,
                hqaddressline2,
                hqphonecode,
                hqphonenumber,
                hqcity,
                hqcountryiso3,
                adaddressline1,
                adaddressline2,
                adphonecode,
                adphonenumber,
                adcity,
                adcountryiso3,
                csrfmiddlewaretoken: token
            })
            if (data.status !== "success") {
                throw Error(JSON.stringify(data))
            }
            await dispatch(getUser())
            return data
        }
        catch (error) {
            throw apiErrorParser(error)
        }
    })

export const businessLocationUpdateAsync = createAsyncThunk("account/businessLocationUpdate",
    async ({
        hqaddressline1,
        hqaddressline2,
        hqphonecode,
        hqphonenumber,
        hqcity,
        hqcountryiso3,
        adaddressline1,
        adaddressline2,
        adphonecode,
        adphonenumber,
        adcity,
        adcountryiso3,
        token,
    }, { dispatch }) => {
        try {
            const { data } = await api.businessLocationUpdate({
                hqaddressline1,
                hqaddressline2,
                hqphonecode,
                hqphonenumber,
                hqcity,
                hqcountryiso3,
                adaddressline1,
                adaddressline2,
                adphonecode,
                adphonenumber,
                adcity,
                adcountryiso3,
                csrfmiddlewaretoken: token
            })
            if (data.status !== "success") {
                throw Error(JSON.stringify(data))
            }
            await dispatch(getUser())
            return data
        }
        catch (error) {
            throw apiErrorParser(error)
        }
    })