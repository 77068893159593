
const TextFieldInput = ({ name, label, placeholder, hint, required, onChange, defaultValue, error, readOnly }) => {
    return (
        <div className="space-y-2">
            {label &&
                <div className="flex flex-wrap gap-2 items-center justify-between">
                    <label htmlFor={name} className="text-versich-dark-blue text-base font-medium">{label}{required && <span className="text-red-500 ml-1">*</span>}</label>
                </div>
            }
            <input
                id={name}
                className={"text-sm font-medium w-full h-10 py-2.5 px-3 border-[1px] shadow-input outline-none rounded-lg "
                    + (error ? "border-red-500 " : "border-gray-300 ")
                    + (readOnly ? "text-versich-light-gray cursor-not-allowed" : "focus-visible:border-versich-blue focus:shadow-inputFocus")
                }
                name={name}
                type="text"
                value={defaultValue}
                placeholder={placeholder}
                onChange={onChange}
                readOnly={readOnly}
            />
            {hint &&
                <div className="text-charcoal text-sm text-left">{hint}</div>
            }
            {error &&
                <div className="text-sm text-red-500 text-left">
                    {required}
                </div>
            }
        </div>
    )
}
export default TextFieldInput
