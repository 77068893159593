import { useSelector, useDispatch } from "react-redux"
import { useForm, FormProvider } from "react-hook-form"
import { useState, useEffect } from "react"

import curvedEditIcon from "../../../../assets/svgs/curved-edit.svg"
import DialogBox, { closeDialog, getDialog, openDialog } from "../../../DialogBox"
import FormLoader from "../../../loader/FormLoader"
import Close from "../../../svgs/Close"
import CSRFTokenField from "../../../Inputs/CSRFTokenField"
import InputText from "../../../Inputs/InputTextField"
import VerificationCodeField from "../../../Inputs/VerificationCodeField"
import { checkEmailAndSendVerificationCodeAsync, verifyCodeAndUpdateEmailAsync } from "../../../../slices/account"
import { TOASTTYPE_ERROR } from "../../../../assets/constants"
import toastify from "../../../../utils/toastify"

const AccountEmail = () => {
    const { user } = useSelector((state) => state.auth)
    const methods = useForm()

    const [csrfToken, setCsrfToken] = useState("")
    const [email, setEmail] = useState("")
    const [newEmail, setNewEmail] = useState(false)
    const [code, setCode] = useState("")
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    // Pre-fill Form
    const resetForm = () => {
        setEmail(user.email || "")
        setNewEmail(false)
        setCode("")
        setErrorMsg("")
    }
    useEffect(() => {
        resetForm()
    }, [user])

    // Close dialog
    const customCloseDialog = (e) => {
        resetForm()
        closeDialog(e)
    }

    const dispatch = useDispatch()
    const onSubmit = async (data, event) => {
        try {
            const isValid = await methods.trigger()
            if (!isValid) {
                toastify("Form validation failed", TOASTTYPE_ERROR)
                return methods.reset()
            }
            const formData = {
                email: data.email,
                token: csrfToken,
            }
            setLoading(true)
            await dispatch(checkEmailAndSendVerificationCodeAsync(formData))
                .unwrap()
                .then((data) => {
                    if (data.shortInfo === "no-change") {
                        const dialog = getDialog(event.target)
                        if (dialog) {
                            closeDialog(null, dialog)
                        }
                    }
                    else {
                        toastify(data.message, data.status, null, accountEmailEditDialog)
                        setNewEmail(true)
                    }
                    methods.reset()
                    setErrorMsg("")
                    return setLoading(false)
                })
                .catch(error => {
                    const data = JSON.parse(error?.message)
                    return setErrorMsg(data.message)
                })
                .finally(() => setLoading(false))
        } catch (error) {
            console.error("Form submission error:", error)
        }
    }

    const verifySubmit = async (data, event) => {
        try {
            const isValid = await methods.trigger()
            if (!isValid) {
                toastify("Form validation failed", TOASTTYPE_ERROR)
                return methods.reset()
            }
            const formData = {
                email: data.email,
                code: data.verificationCode,
                token: csrfToken,
            }
            setLoading(true)
            await dispatch(verifyCodeAndUpdateEmailAsync(formData))
                .unwrap()
                .then(() => {
                    const dialog = getDialog(event.target)
                    if (dialog) {
                        closeDialog(null, dialog)
                    }
                    methods.reset()
                    setErrorMsg("")
                    return setLoading(false)
                })
                .catch(error => {
                    const data = JSON.parse(error?.message)
                    return setErrorMsg(data.message)
                })
                .finally(() => setLoading(false))
        } catch (error) {
            console.error("Form submission error:", error)
        }
    }
    return (
        <div>
            <div className="text-sm">
                <div className="flex max-smallest:flex-col smallest:items-start smallest:justify-between gap-x-3 gap-y-1.5">
                    <span className="font-medium opacity-80">Email</span>
                    <button className="font-medium inline-flex items-center gap-1 hover:opacity-80" onClick={openDialog} data-parent-id={accountEmailEditDialog}>
                        <img className="w-[18px] h-[18px]" src={curvedEditIcon} alt="" data-parent-id={accountEmailEditDialog} />
                        <span className="text-versich-light-blue text-xs" data-parent-id={accountEmailEditDialog}>Edit</span>
                    </button>
                </div>
                <div className="text-versich-dark-blue text-sm mt-2.5" style={{ overflowWrap: "anywhere" }}>
                    <div className="font-semibold">{user.email}</div>
                </div>
            </div>
            <DialogBox
                id={accountEmailEditDialog}
                className="backdrop:bg-[#1717253D] backdrop:backdrop-blur-sm max-sm:max-w-lg"
                toastStacked={false}
                hideClose={true}
                customCloseEvent={customCloseDialog}
            >
                <div className="space-y-[34px] p-2.5 md:p-4">
                    <div className="flex gap-1.5">
                        <div className="text-versich-dark-blue flex-1">
                            <div className="text-xl font-semibold">Authentication Email</div>
                            <div className="text-sm mt-0.5">Manage your primary email</div>
                        </div>
                        <div id="dialog_closebtn" className="text-right">
                            <button type="button" className="m-1 p-1" onClick={customCloseDialog} data-parent-id={accountEmailEditDialog}>
                                <Close className="w-4 h-4" />
                            </button>
                        </div>
                    </div>
                    <FormProvider {...methods}>
                        <form className="space-y-[34px]" method="dialog" onSubmit={methods.handleSubmit(newEmail ? verifySubmit : onSubmit)} data-parent-id={accountEmailEditDialog}>
                            <div className="space-y-3">
                                <InputText
                                    name="email"
                                    inputType="email"
                                    label="Email Address"
                                    placeholder="Enter your email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    defaultValue={email}
                                    rules={{ required: "Email is required" }}
                                    readOnly={newEmail}
                                />
                                {newEmail &&
                                    <div className="!mt-5 !pt-5 sm:!mt-8 sm:pt-8 border-t border-[EFEEEE99]">
                                        <VerificationCodeField
                                            name="verificationCode"
                                            label="Verification Code"
                                            hint="Hint: Enter the verification code received through email"
                                            onChange={(e) => setCode(e.target.value)}
                                            defaultValue={code}
                                            rules={{ required: "Verification code is required" }}
                                            autoFocus={true}
                                        />
                                    </div>
                                }
                                <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                            </div>
                            {errorMsg && <div className="text-red-500 text-sm text-left mb-4">{errorMsg}</div>}
                            <div className="text-sm font-semibold w-full flex max-sm401:flex-col justify-end gap-3 md:gap-5">
                                <button className="bg-white hover:opacity-80 text-versich-light-blue border border-versich-light-blue py-2.5 md:py-3 px-8 md:px-10 rounded-lg" type="button" onClick={customCloseDialog} data-parent-id={accountEmailEditDialog}>Cancel</button>
                                <button className="bg-versich-blue hover:bg-versich-blue-hover disabled:bg-versich-blue-hover text-white disabled:opacity-70 py-2.5 md:py-3 px-8 md:px-10 rounded-lg disabled:cursor-not-allowed" type="submit">Update</button>
                            </div>
                        </form>
                    </FormProvider>
                </div>
                {loading && <FormLoader />}
            </DialogBox>
        </div>
    )
}
const accountEmailEditDialog = "accountemail-edit-dialog"
export default AccountEmail