import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import chevronRightIcon from "../../assets/svgs/chevron-right.svg"
import "./index.css"

const SliderArrow = ({ onClick, direction }) => {
    const isRight = direction === "right"
    return (
        <div className={"bg-[#F7FAFF] hover:bg-white flex items-center justify-center w-8 h-8 absolute z-10 top-[calc(50%-24px)] -translate-y-1/2 rounded-full cursor-pointer" + (isRight ? " right-1" : " left-1")} onClick={onClick}>
            <img className={"w-8 h-8 invert-1 brightness-0" + (isRight ? " ml-0.5 rotate-180" : " mr-0.5")} src={chevronRightIcon} alt="" />
        </div>
    )
}

const ImageSlider = ({ images }) => {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SliderArrow direction="right" />,
        prevArrow: <SliderArrow />,
        appendDots: dots => (
            <div
                style={{
                    marginTop: "12px",
                    position: "static"
                }}
            >
                <ul className="slick-dots-wrapper">{dots}</ul>
            </div>
        ),
    }
    return (
        <Slider {...settings}>
            {images.map((image, index) => (
                <div className="w-full h-full aspect-video rounded-[11px] overflow-hidden" key={index}>
                    <img className="w-full object-contain rounded-[11px]" src={image} />
                </div>
            ))}
        </Slider>
    )
}
export default ImageSlider