import { useSelector, useDispatch } from "react-redux"
import { useState } from "react"

import curvedEditIcon from "../../../../assets/svgs/curved-edit.svg"
import DialogBox, { closeDialog, getDialog, openDialog } from "../../../DialogBox"
import FormLoader from "../../../loader/FormLoader"
import Close from "../../../svgs/Close"
import ComboServicesInputField from "../../../Inputs/ComboServicesInputField"
import CSRFTokenField from "../../../Inputs/CSRFTokenField"
import { freelancerOtherRemainingServicesAsync, freelancerOtherServiceUpdateAsync } from "../../../../slices/freelancer/otherService"
import { TOASTTIMER } from "../../../../assets/constants"

const OtherServices = () => {
    const { user } = useSelector((state) => state.auth)

    const [csrfToken, setCsrfToken] = useState("")
    const [selectedServices, setSelectedServices] = useState("")
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()
    const handleSubmit = async (e) => {
        e.preventDefault()
        if (selectedServices.length > 0) {
            const formData = {
                serviceIds: selectedServices,
                token: csrfToken,
            }
            setLoading(true)
            await dispatch(freelancerOtherServiceUpdateAsync(formData))
                .unwrap()
                .then(() => {
                    const dialog = getDialog(e.target)
                    if (dialog) {
                        closeDialog(null, dialog)
                    }
                    return setLoading(false)
                })
                .catch(error => {
                    const data = JSON.parse(error?.message)
                    return setErrorMsg(data.message)
                })
                .finally(() => setLoading(false))
        }
        else {
            setErrorMsg("No services selected, make sure you select atleast one.")
            setTimeout(() => {
                setErrorMsg("")
            }, TOASTTIMER)
        }
    }
    return (
        <div>
            <div className="pb-5 border-b border-[#EFEEEE99]/50">
                <div className="text-sm">
                    <div className="font-medium flex max-smallest:flex-col smallest:items-start smallest:justify-between gap-x-3 gap-y-1.5">
                        <span className="opacity-80">Other services for more leads</span>
                        <button className="inline-flex items-center gap-1 hover:opacity-80" onClick={openDialog} data-parent-id={otherServicesEditDialog}>
                            <img className="w-[18px] h-[18px]" src={curvedEditIcon} alt="" data-parent-id={otherServicesEditDialog} />
                            <span className="text-versich-light-blue text-xs" data-parent-id={otherServicesEditDialog}>Edit</span>
                        </button>
                    </div>
                    <div className="text-versich-light-blue text-sm flex gap-1 flex-wrap mt-2.5">
                        {user.otherServices && user.otherServices.length > 0
                            ? user.otherServices.map(service => (
                                <span className="bg-brandeis-blue/[0.08] inline-block py-1.5 px-2.5 rounded-full" key={service.uid}>{service.title}</span>
                            ))
                            : <span className="inline-block py-1.5 px-2.5">-</span>
                        }
                    </div>
                </div>
            </div>
            <DialogBox
                id={otherServicesEditDialog}
                className="backdrop:bg-[#1717253D] backdrop:backdrop-blur-sm max-sm:max-w-lg"
                toastStacked={false}
                hideClose={true}
            >
                <div className="space-y-[34px] p-2.5 md:p-4">
                    <div className="flex gap-1.5">
                        <div className="text-versich-dark-blue flex-1">
                            <div className="text-xl font-semibold">Maximize your leads</div>
                            <div className="text-sm mt-0.5">Manage other services you can provide</div>
                        </div>
                        <div id="dialog_closebtn" className="text-right">
                            <button type="button" className="m-1 p-1" onClick={closeDialog} data-parent-id={otherServicesEditDialog}>
                                <Close className="w-4 h-4" />
                            </button>
                        </div>
                    </div>
                    <form className="flex flex-col min-h-60 space-y-[34px]" method="dialog" onSubmit={handleSubmit} data-parent-id={otherServicesEditDialog}>
                        <div className="flex-1">
                            <ComboServicesInputField otherRemainingServicesAsync={freelancerOtherRemainingServicesAsync} onSelect={(e) => setSelectedServices(e)} />
                            <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                        </div>
                        {errorMsg && <div className="text-red-500 text-sm text-left mb-4">{errorMsg}</div>}
                        <div className="text-sm font-semibold w-full flex max-sm401:flex-col justify-end gap-3 md:gap-5">
                            <button className="bg-white hover:opacity-80 text-versich-light-blue border border-versich-light-blue py-2.5 md:py-3 px-8 md:px-10 rounded-lg" type="button" onClick={closeDialog} data-parent-id={otherServicesEditDialog}>Cancel</button>
                            <button className="bg-versich-blue hover:bg-versich-blue-hover disabled:bg-versich-blue-hover text-white disabled:opacity-70 py-2.5 md:py-3 px-8 md:px-10 rounded-lg disabled:cursor-not-allowed" type="submit">Update</button>
                        </div>
                    </form>
                </div>
                {loading && <FormLoader />}
            </DialogBox>
        </div>
    )
}
const otherServicesEditDialog = "otherservices-edit-dialog"
export default OtherServices