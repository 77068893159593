import { useSelector } from "react-redux"

import ClientAccountSettings from "./ClientAccountSettings"
import FreelancerAccountSettings from "./FreelancerAccountSettings"
import BusinessAccountSettings from "./BusinessAccountSettings"
import { CLIENT, FREELANCER, BUSINESS } from "../../../assets/constants"

const metaData = {
    title: "Account Settings",
    url: `${process.env.REACT_APP_CLIENT_URL}/dashboard/accountsettings/`
}

const AccountSettings = ({ metadata = metaData }) => {
    const { user } = useSelector((state) => state.auth)
    return (<>
        {user.accountType === CLIENT && <ClientAccountSettings metadata={metadata} />}
        {user.accountType === FREELANCER && <FreelancerAccountSettings metadata={metadata} />}
        {user.accountType === BUSINESS && <BusinessAccountSettings metadata={metadata} />}
    </>)
}
export default AccountSettings
