import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { useParams, useNavigate, Link } from "react-router-dom"
import { BeatLoader } from "react-spinners"

import ErrorPage from "../../components/ErrorPage/ErrorPage"
import Navbar from "../../components/Navbar/Navbar"
import Meta from "../../components/Meta"
import chevronRightIcon from "../../assets/svgs/chevron-right.svg"
import curvyArrowIcon from "../../assets/svgs/full-curvyarrow-right.svg"
import emptyBoxIcon from "../../assets/images/empty-box.png"
import ServicesList from "../../assets/ServicesList"
import { freelancerProfilePath } from "../../assets/constants"
import { getPublicFreelancerServicesAsync } from "../../slices/freelancer/public"

const ServiceTile = ({ data, profilePath }) => {

    // Set Icon & Color
    const [service, setService] = useState(null)
    useEffect(() => {
        const services = ServicesList.filter(s => s.uid === data.uid)
        if (services.length > 0) {
            setService(services[0])
        }
    }, [data])

    const serviceProjectsPath = `${profilePath}services/:sid/projects/`.replace(":sid", data.uid)
    return (
        service &&
        <Link to={serviceProjectsPath} className="bg-white text-center flex flex-col items-center justify-center gap-3 smaller:gap-5 sm401:aspect-[4/3] py-4 sm:py-6 px-6 sm:px-8 border border-[#F5F5F5] hover:border-blue-100 hover:shadow-ring rounded-lg">
            <div>
                <img className="min-w-10 h-10 smaller:min-w-[50px] smaller:h-[50px] drop-shadow contrast-150" src={service.icon} alt={data.title} />
            </div>
            <div className="text-xs">
                <h3 className="text-versich-dark-blue font-medium">{data.title}</h3>
                <div className="text-versich-label text-[10px] inline-flex items-center gap-[5px] mt-[5px]">
                    View details <img className="w-3 h-3" src={curvyArrowIcon} alt="" />
                </div>
            </div>
        </Link>
    )
}

const FreelancerServices = () => {
    const { id } = useParams()
    const [user, setUser] = useState(undefined)
    const [metadata, setMetadata] = useState()

    const dispatch = useDispatch()
    const fetchFreelancerServices = async (id) => {
        dispatch(getPublicFreelancerServicesAsync(id))
            .unwrap()
            .then((data) => {
                setUser(data.payload)
                const fullName = data.payload.about.firstName + " " + data.payload.about.lastName
                setMetadata({
                    title: fullName + " - Freelancer Services",
                    description: `Public freelancer services of ${fullName}`,
                    url: `${process.env.REACT_APP_CLIENT_URL}/freelancer/${id}/services/`
                })
            })
            .catch(() => setUser(null))
    }
    useEffect(() => {
        setMetadata({
            title: "Freelancer Services",
            description: "Public freelancer services",
            url: `${process.env.REACT_APP_CLIENT_URL}/freelancer/${id}/services/`
        })
        fetchFreelancerServices(id)
    }, [id])

    const navigate = useNavigate()
    const dynamicFreelancerProfilePath = freelancerProfilePath.replace(":uid", id)
    return (
        user !== null
            ? <div className="bg-[#F5F7FF] flex flex-col min-h-svh h-full">
                <Navbar />
                {user === undefined
                    ? <div className="w-full h-80 flex justify-center items-center">
                        <BeatLoader loading={true} color="#1D88ED" size={10} />
                    </div>
                    : <>
                        {metadata && <Meta data={metadata} />}
                        <div className="flex-grow flex flex-col py-8 px-8 md:px-16 lg:px-28">
                            <div className="max_screen_w w-full flex-grow flex flex-col gap-y-2.5">
                                <div className="space-y-1.5">
                                    <div className="flex justify-start pt-1">
                                        <button className="inline-flex items-center gap-0.5" onClick={() => navigate(dynamicFreelancerProfilePath)}>
                                            <img src={chevronRightIcon} alt="" />
                                            <span className="text-versich-light-blue text-sm font-semibold">Back</span>
                                        </button>
                                    </div>
                                    <div className="text-versich-dark-blue text-start space-y-[2px]">
                                        <div className="text-lg lg:text-xl font-semibold">Services</div>
                                        <div className="text-sm">See all services offered by freelancer</div>
                                    </div>
                                </div>
                                <div className="bg-white text-start flex-grow py-6 px-4 sm401:px-6 shadow-card4 rounded-lg">
                                    {user.primaryService
                                        ? <div className="grid grid-cols-1 sm401:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-5">
                                            <ServiceTile data={user.primaryService} profilePath={dynamicFreelancerProfilePath} />
                                            {user.otherServices && user.otherServices.length > 0 && user.otherServices.map(service => (
                                                <ServiceTile data={service} profilePath={dynamicFreelancerProfilePath} key={service.uid} />
                                            ))}
                                        </div>
                                        : <div className="h-full flex flex-col items-center justify-center p-4">
                                            <img className="w-[180px] sm:w-[220px] opacity-60" src={emptyBoxIcon} alt="no services" />
                                            <span className="text-versich-label text-sm">No Services</span>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
            : <ErrorPage status={404} />
    )
}
export default FreelancerServices