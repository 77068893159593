import Datepicker from "react-tailwindcss-datepicker"

const DateRangeFieldInput = ({ label, name, value, placeholder, onChange, required, error }) => {
    return (
        <div className="space-y-2 text-start">
            {label && <label htmlFor={name} className="text-versich-dark-blue text-base font-medium flex">{label}{required && <span className="text-red-500 ml-1">*</span>}</label>}
            <div className="relative">
                <Datepicker
                    inputId={name}
                    primaryColor={"blue"}
                    useRange={false}
                    separator="-"
                    displayFormat="MM/DD/YYYY"
                    inputClassName={"bg-white text-black text-sm font-medium w-full h-10 py-2.5 px-3 border-[1px] focus-visible:border-versich-blue shadow-input focus:shadow-inputFocus outline-none rounded-lg appearance-none "
                        + (error ? "border-red-500" : "border-gray-300")
                    }
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                />
            </div>
            {error &&
                <div className="text-sm text-red-500">{required}</div>
            }
        </div>
    )
}
export default DateRangeFieldInput