import { useSelector, useDispatch } from "react-redux"
import { useForm, FormProvider } from "react-hook-form"
import { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { BsCheck2Circle } from "react-icons/bs"

import curvedEditIcon from "../../../../assets/svgs/curved-edit.svg"
import DialogBox, { closeDialog, getDialog, openDialog } from "../../../DialogBox"
import FormLoader from "../../../loader/FormLoader"
import Close from "../../../svgs/Close"
import CSRFTokenField from "../../../Inputs/CSRFTokenField"
import InputText from "../../../Inputs/InputTextField"
import toastify from "../../../../utils/toastify"
import { personalDetailsUpdateAsync } from "../../../../slices/freelancer/settings"
import { TOASTTYPE_ERROR } from "../../../../assets/constants"

const PersonalDetails = () => {
    const { user } = useSelector((state) => state.auth)
    const methods = useForm()

    const [csrfToken, setCsrfToken] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [profileId, setProfileId] = useState("")
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    // Pre-fill Form
    const resetForm = () => {
        setFirstName(user.about.firstName || "")
        setLastName(user.about.lastName || "")
        setProfileId(user.about.profileId || "")
    }
    useEffect(() => {
        if (!user.about) {
            return
        }
        resetForm()
    }, [user])

    // Close dialog
    const customCloseDialog = (e) => {
        resetForm()
        closeDialog(e)
    }

    const dispatch = useDispatch()
    const onSubmit = async (data, event) => {
        try {
            const isValid = await methods.trigger()
            if (!isValid) {
                toastify("Form validation failed", TOASTTYPE_ERROR)
                return methods.reset()
            }
            const formData = {
                firstname: data.firstName,
                lastname: data.lastName,
                profileid: data.profileId,
                token: csrfToken,
            }
            setLoading(true)
            await dispatch(personalDetailsUpdateAsync(formData))
                .unwrap()
                .then(() => {
                    const dialog = getDialog(event.target)
                    if (dialog) {
                        closeDialog(null, dialog)
                    }
                    methods.reset()
                    return setLoading(false)
                })
                .catch(error => {
                    const data = JSON.parse(error?.message)
                    return setErrorMsg(data.message)
                })
                .finally(() => setLoading(false))
        } catch (error) {
            console.error("Form submission error:", error)
        }
    }
    return (
        <div className="bg-white text-start p-4 sm:p-5 shadow-card5 rounded-lg">
            <div className="font-medium flex max-sm401:flex-col sm401:justify-between gap-x-3 gap-y-1.5 py-3 border-b border-[#EFEEEE99]">
                <span className="text-versich-dark-blue text-xl">Personal Details</span>
                <button className="inline-flex items-center gap-1 hover:opacity-80" onClick={openDialog} data-parent-id={personalDetailsEditDialog}>
                    <img className="w-[18px] h-[18px]" src={curvedEditIcon} alt="" data-parent-id={personalDetailsEditDialog} />
                    <span className="text-versich-light-blue text-xs" data-parent-id={personalDetailsEditDialog}>Edit</span>
                </button>
            </div>
            <div className="text-versich-dark-blue my-5 space-y-8">
                <div className="grid grid-cols-1 sm:max-md:grid-cols-2 lg900:max-xl1080:grid-cols-2 xl:grid-cols-2 gap-x-3 gap-y-6">
                    <div className="text-sm">
                        <p className="font-medium opacity-80 mb-[7px]">First Name</p>
                        <div className="font-semibold">{user.about.firstName}</div>
                    </div>
                    <div className="text-sm">
                        <p className="font-medium opacity-80 mb-[7px]">Last Name</p>
                        <div className="font-semibold">{user.about.lastName || "-"}</div>
                    </div>
                </div>
                <div className="text-sm" style={{ overflowWrap: "anywhere" }}>
                    <p className="font-medium opacity-80 mb-[7px]">Email Address</p>
                    <div className="font-semibold inline-flex flex-wrap items-center gap-1.5">{user.email} {user.verified && <BsCheck2Circle className="text-emerald-600" title="Email verified" />}</div>
                </div>
                <div className="text-sm space-y-[7px]" style={{ overflowWrap: "anywhere" }}>
                    <p className="font-medium opacity-80">Freelancer Id</p>
                    <div className="font-semibold">{user.about.profileId}</div>
                    <div className="text-charcoal text-xs">
                        [Public profile link: <Link className="hover:text-versich-light-blue" to={`${process.env.REACT_APP_CLIENT_URL}/freelancer/${user.about.profileId}/`} target="_blank">{process.env.REACT_APP_CLIENT_URL}/freelancer/{user.about.profileId}/</Link>]
                    </div>
                </div>
            </div>
            <DialogBox
                id={personalDetailsEditDialog}
                className="backdrop:bg-[#1717253D] backdrop:backdrop-blur-sm max-sm:max-w-lg"
                toastStacked={false}
                hideClose={true}
                customCloseEvent={customCloseDialog}
            >
                <div className="space-y-[34px] p-2.5 md:p-4">
                    <div className="flex gap-1.5">
                        <div className="text-versich-dark-blue flex-1">
                            <div className="text-xl font-semibold">Personal Details</div>
                            <div className="text-sm mt-0.5">Manage your personal details</div>
                        </div>
                        <div id="dialog_closebtn" className="text-right">
                            <button type="button" className="m-1 p-1" onClick={customCloseDialog} data-parent-id={personalDetailsEditDialog}>
                                <Close className="w-4 h-4" />
                            </button>
                        </div>
                    </div>
                    <FormProvider {...methods}>
                        <form className="space-y-[34px]" method="dialog" onSubmit={methods.handleSubmit(onSubmit)} data-parent-id={personalDetailsEditDialog}>
                            <div className="space-y-3">
                                <InputText name="firstName" inputType="text" placeholder="e.g. Steve" label="First Name" onChange={(e) => setFirstName(e.target.value)} defaultValue={firstName} rules={{ required: "First name is required" }} />
                                <InputText name="lastName" inputType="text" placeholder="e.g. Rogers" label="Last Name" onChange={(e) => setLastName(e.target.value)} defaultValue={lastName} rules={{ required: "Last name is required" }} />
                                <InputText
                                    name="profileId"
                                    inputType="text"
                                    placeholder="e.g. steverogers"
                                    label="Freelancer Id"
                                    hint={`Your public profile link will look like: ${process.env.REACT_APP_CLIENT_URL}/freelancer/${profileId}`}
                                    onChange={(e) => setProfileId(e.target.value)}
                                    defaultValue={profileId}
                                    rules={{ required: "Freelancer id is required" }}
                                />
                                <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                            </div>
                            {errorMsg && <div className="text-sm text-red-500 text-left mt-8 mb-4">{errorMsg}</div>}
                            <div className="text-sm font-semibold w-full flex max-sm401:flex-col justify-end gap-3 md:gap-5">
                                <button className="bg-white hover:opacity-80 text-versich-light-blue border border-versich-light-blue py-2.5 md:py-3 px-8 md:px-10 rounded-lg" type="button" onClick={customCloseDialog} data-parent-id={personalDetailsEditDialog}>Cancel</button>
                                <button className="bg-versich-blue hover:bg-versich-blue-hover disabled:bg-versich-blue-hover text-white disabled:opacity-70 py-2.5 md:py-3 px-8 md:px-10 rounded-lg disabled:cursor-not-allowed" type="submit">Update</button>
                            </div>
                        </form>
                    </FormProvider>
                </div>
                {loading && <FormLoader />}
            </DialogBox>
        </div>
    )
}
const personalDetailsEditDialog = "personaldetails-edit-dialog"
export default PersonalDetails