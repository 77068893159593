import { useState, useEffect } from "react"
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs"
import { useFormContext } from "react-hook-form"

const InputTextField = ({ name, label, inputType, placeholder, hint, rules, onChange, defaultValue, readOnly }) => {
    const { register, formState, setValue, getValues, clearErrors } = useFormContext()
    const [showPassword, setShowPassword] = useState(false)
    const { errors } = formState

    useEffect(() => {
        getValues(name) && setShowPassword(true)
    }, [getValues, name])

    useEffect(() => {
        setValue(name, defaultValue)
    }, [defaultValue, name])

    // Remove errors on value change
    useEffect(() => {
        clearErrors(name)
    }, [defaultValue])

    const getInputType = () => {
        if (inputType === "password" && showPassword) {
            return "text"
        }
        return inputType
    }
    const handleChange = (e) => {
        setValue(name, e.target.value)
        onChange && onChange(e)
    }
    return (
        <div className="space-y-2">
            {label &&
                <div className="flex flex-wrap gap-2 items-center justify-between">
                    <label htmlFor={name} className="text-charcoal text-sm">{label}{rules?.required && <span className="text-red-500 ml-1">*</span>}</label>
                    {inputType === "password" && (
                        <button className="flex items-center space-x-1 text-xs" type="button" onClick={() => setShowPassword((prev) => !prev)}>
                            {showPassword
                                ? <BsFillEyeFill className="text-charcoal" />
                                : <BsFillEyeSlashFill className="text-charcoal" />
                            }
                            <span className="text-charcoal">{showPassword ? "Hide" : "Show"}</span>
                        </button>
                    )}
                </div>
            }
            <input
                className={"text-sm w-full h-10 py-2.5 px-3 border-[1px] shadow-input outline-none rounded-lg "
                    + (errors[name] ? "border-red-500 " : "border-gray-300 ")
                    + (readOnly ? "text-versich-light-gray cursor-not-allowed" : "focus-visible:border-versich-blue focus:shadow-inputFocus")
                }
                id={name}
                name={name}
                {...register(name, rules)}
                type={getInputType()}
                value={defaultValue}
                placeholder={placeholder}
                onChange={handleChange}
                readOnly={readOnly}
            />
            {hint &&
                <div className="text-charcoal text-sm text-left">{hint}</div>
            }
            {errors[name] &&
                <div className="text-sm text-red-500 text-left">
                    {errors[name].message}
                </div>
            }
        </div>
    )
}
export default InputTextField
