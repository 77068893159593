import { createAsyncThunk } from "@reduxjs/toolkit"

import * as api from "../api"
import { getUser } from "../actions/auth"
import apiErrorParser from "../utils/apiErrorParser"

export const getReviewAndReferencesAsync = createAsyncThunk("service/getReviewAndReferences", async () => {
    try {
        const { data } = await api.getReviewAndReferences()
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const askForReviewAndReferencesAsync = createAsyncThunk("account/askForReviewAndReferences",
    async ({ firstname, lastname, companyname, email, token }, { dispatch }) => {
        try {
            const { data } = await api.askForReviewAndReferences({ firstname, lastname, companyname, email, csrfmiddlewaretoken: token })
            if (data.status !== "success") {
                throw Error(JSON.stringify(data))
            }
            await dispatch(getUser())
            return data
        }
        catch (error) {
            throw apiErrorParser(error)
        }
    })

export const reviewAndReferencesSubmitAsync = createAsyncThunk("account/reviewAndReferencesSubmit",
    async ({ token, firstname, lastname, companyname, review, ratings, csrfToken }) => {
        try {
            const { data } = await api.reviewAndReferencesSubmit({ token, firstname, lastname, companyname, review, ratings, csrfmiddlewaretoken: csrfToken })
            if (data.status !== "success") {
                throw Error(JSON.stringify(data))
            }
            return data
        }
        catch (error) {
            throw apiErrorParser(error)
        }
    })