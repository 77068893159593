
export const getEventDate = (start, end, showYear) => {
    const startDate = new Date(start)
    const endDate = new Date(end)
    const startMonthName = startDate.toLocaleString("default", { month: "short" })
    const endMonthName = endDate.toLocaleString("default", { month: "short" })
    const startMonth = startDate.getMonth()
    const endMonth = endDate.getMonth()
    const startDateNumber = startDate.getDate()
    const endDateNumber = endDate.getDate()
    const startYear = startDate.getFullYear()
    const endYear = endDate.getFullYear()
    let eventDate = `${startMonthName} ${startDateNumber}`
    if (startYear !== endYear || showYear) {
        eventDate += `, ${startYear} - ${endMonthName} ${endDateNumber}, ${endYear}`
    }
    else if (startMonth !== endMonth) {
        eventDate += ` - ${endMonthName} ${endDateNumber}`
    }
    else if (startDateNumber !== endDateNumber) {
        eventDate += ` - ${endDateNumber}`
    }
    return eventDate
}