import { useSelector, useDispatch } from "react-redux"
import { useState } from "react"

import curvedEditIcon from "../../../assets/svgs/curved-edit.svg"
import CSRFTokenField from "../../Inputs/CSRFTokenField"
import { getInitials, titleCase } from "../../../utils"
import { BUSINESS } from "../../../assets/constants"
import { updateProfilePictureAsync } from "../../../slices/account"
import toastify from "../../../utils/toastify"

const maxSizeInMb = 5
const allowedFileTypes = ["image/webp", "image/png", "image/jpeg", "image/heif"]
const heifExtensions = [".heic", ".heif"]

const Profile = () => {
    const { user } = useSelector(state => state.auth)
    const [csrfToken, setCsrfToken] = useState("")

    const fullName = user.accountType === BUSINESS ? user.business.details.data.companyName : user.about.firstName + " " + user.about.lastName
    const initials = getInitials(fullName)

    const dispatch = useDispatch()
    const handleFileSelect = (e) => {
        if (e.target.files.length === 0) {
            return
        }
        const file = e.target.files[0]
        const fileSizeMb = (file.size / (1024 ** 2)).toFixed(2)
        if ((file.type !== "" && allowedFileTypes.includes(file.type) === false)
            || (file.type === ""
                && (
                    heifExtensions.map(ext => file.name.endsWith(ext)).includes(true) === false
                )
            )) {
            return toastify("Choose a valid image file with extension .jpeg, .png, .webp or .heic (heif)", "error", null)
        }
        else if (fileSizeMb > maxSizeInMb) {
            return toastify(`Selected image size is greater than ${maxSizeInMb}MB`, "warn", null)
        }
        dispatch(updateProfilePictureAsync({ file, token: csrfToken }))
            .unwrap()
            .then(data => {
                toastify(data.message, data.status, null)
            })
            .catch(error => {
                const data = JSON.parse(error?.message)
                toastify(data.message, data.status, null)
            })
    }
    return (
        <div className="md:col-span-2 xl:col-span-1">
            <div className="flex flex-col items-center justify-center gap-[25px] py-[42px] px-5 shadow-card5 rounded-lg">
                <label className="bg-[#F6F9FF] w-[135px] h-[135px] smallest:w-[150px] smallest:h-[150px] sm:w-[160px] sm:h-[160px] rounded-full cursor-pointer relative group">
                    {user.profile?.image
                        ? <img className="w-full h-full object-cover rounded-full" src={user.profile.image} alt="" />
                        : user.social?.image
                            ? <img className="w-full h-full object-cover rounded-full" src={user.social.image} alt="" />
                            : <div className="text-versich-darktext-color text-3xl md:text-4xl font-semibold flex justify-center items-center w-full h-full rounded-full">
                                {initials}
                            </div>
                    }
                    <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                    <input type="file" accept=".jpeg,.jpg,.png,.webp,.heic,.heif" hidden onChange={handleFileSelect} />
                    <button className="bg-[#FAFCFF] group-hover:bg-blue-100 flex items-center justify-center w-[35px] h-[35px] sm:w-10 sm:h-10 rounded-full absolute right-0 -bottom-[5px]">
                        <img className="w-[18px] h-[18px]" src={curvedEditIcon} alt="" />
                    </button>
                </label>
                <div className="text-versich-dark-blue text-center">
                    <div className="text-xl font-semibold">{fullName}</div>
                    <div className="text-sm mt-0.5">{titleCase(user.accountType)}</div>
                </div>
            </div>
        </div>
    )
}
export default Profile