import axios from "axios"

const API = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
})
const getReqConfig = (data) => {
    return {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-csrftoken": data.csrfmiddlewaretoken,
        },
    }
}

const getReqFileConfig = (data) => {
    return {
        headers: {
            "Content-Type": "multipart/form-data",
            "x-csrftoken": data.csrfmiddlewaretoken,
        }
    }
}

export const csrfToken = () => API.get("/csrf/")

// Auth
export const registerClient = (data) => API.post("/auth/register/client/", data, getReqConfig(data))
export const registerFreelancer = (data) => API.post("/auth/register/freelancer/", data, getReqConfig(data))
export const registerBusiness = (data) => API.post("/auth/register/business/", data, getReqConfig(data))
export const login = (data) => API.post("/auth/login/", data, getReqConfig(data))
export const getUser = () => API.get("/auth/me/")
export const logout = () => API.get("/auth/logout/")
export const verifyEmail = (token) => API.get(`/auth/verify/account/${token}/`)
export const resendVerificationEmail = (data) => API.post("/auth/verify/account/resend/email/", null, getReqConfig(data))
export const forgotPasswordSendLink = (data) => API.post("/auth/forgot-password/sendlink/email/", data, getReqConfig(data))
export const verifyResetPasswordLink = (data) => API.get(`/auth/verify/reset-password/${data.token}/`)
export const resetPassword = (data) => API.patch("/auth/reset-password/", data, getReqConfig(data))
export const changePassword = (data) => API.patch("/auth/change-password/", data, getReqConfig(data))

// Social Auth
export const authenticateGoogleUser = (searchQuery) => API.get(`/auth/social-accounts/google/callback/${searchQuery}`)
export const changeGoogleUser = (searchQuery) => API.get(`/auth/social-accounts/google/account-change/callback/${searchQuery}`)


// Service
export const searchService = (data) => API.post("/service/search/", data, getReqConfig(data))
export const getAllBusinessType = () => API.get("/service/business-type/all/")
export const getAllIndustryType = () => API.get("/service/industry-type/all/")
export const getAllProjectDuration = () => API.get("/service/project-duration/all/")
export const getAllCurrency = () => API.get("/service/currency/all/")
export const getAllEstimatedBudget = () => API.get("/service/estimated-budget/all/")
export const getAllHiringDecision = () => API.get("/service/hiring-decision/all/")
export const getAllBusinessSize = () => API.get("/service/business-size/all/")
export const websiteRequirementsWDAD = (params) => API.get(`/service/web-design-and-development/website-requirement/all/${params}`)
export const websiteNeedsWDAD = (params) => API.get(`/service/web-design-and-development/website-need/all/${params}`)
export const createRequestAsGuestWDAD = (data) => API.post("service/web-design-and-development/guest/request/new/", data, getReqConfig(data))
export const createRequestWDAD = (data) => API.post("service/web-design-and-development/request/new/", data, getReqConfig(data))
export const getRequestWDAD = (refId) => API.get(`service/web-design-and-development/request/${refId}/`)
export const getRequestTitleWDAD = (refId) => API.get(`service/web-design-and-development/request/${refId}/title/`)
export const mobileProjectTypesMA = (params) => API.get(`/service/mobile-app/mobile-projecttype/all/${params}`)
export const mobileMonetizationStrategyMA = (params) => API.get(`/service/mobile-app/monetization-strategy/all/${params}`)
export const mobileAppPlatformsMA = (params) => API.get(`/service/mobile-app/app-platform/all/${params}`)
export const mobileProjectNeedsMA = (params) => API.get(`/service/mobile-app/mobile-projectneeds/all/${params}`)
export const createRequestAsGuestMA = (data) => API.post("/service/mobile-app/guest/request/new/", data, getReqConfig(data))
export const createRequestMA = (data) => API.post("/service/mobile-app/request/new/", data, getReqConfig(data))
export const getRequestMA = (refId) => API.get(`/service/mobile-app/request/${refId}/`)
export const getRequestTitleMA = (refId) => API.get(`/service/mobile-app/request/${refId}/title/`)
export const businessServiceNeedsDAA = (params) => API.get(`/service/data-and-analytics/business/service-need/all/${params}`)
export const businessChoiceOfToolsDAA = (params) => API.get(`/service/data-and-analytics/business/choice-of-tool/all/${params}`)
export const businessCreateRequestAsGuestDAA = (data) => API.post("/service/data-and-analytics/business/guest/request/new/", data, getReqConfig(data))
export const businessCreateRequestDAA = (data) => API.post("/service/data-and-analytics/business/request/new/", data, getReqConfig(data))
export const businessGetRequestDAA = (refId) => API.get(`/service/data-and-analytics/business/request/${refId}/`)
export const businessGetRequestTitleDAA = (refId) => API.get(`/service/data-and-analytics/business/request/${refId}/title/`)
export const personalAssistanceNeedsDAA = (params) => API.get(`/service/data-and-analytics/personal/assistance-need/all/${params}`)
export const personalChoiceOfToolsDAA = (params) => API.get(`/service/data-and-analytics/personal/choice-of-tool/all/${params}`)
export const personalCreateRequestAsGuestDAA = (data) => API.post("/service/data-and-analytics/personal/guest/request/new/", data, getReqConfig(data))
export const personalCreateRequestDAA = (data) => API.post("/service/data-and-analytics/personal/request/new/", data, getReqConfig(data))
export const personalGetRequestDAA = (refId) => API.get(`/service/data-and-analytics/personal/request/${refId}/`)
export const personalGetRequestTitleDAA = (refId) => API.get(`/service/data-and-analytics/personal/request/${refId}/title/`)
export const softwareTypesSD = (params) => API.get(`/service/software-development/software-type/all/${params}`)
export const softwareNaturesSD = (params) => API.get(`/service/software-development/software-nature/all/${params}`)
export const operatingSystemsSD = (params) => API.get(`/service/software-development/operating-system/all/${params}`)
export const industryTypesSD = (params) => API.get(`/service/software-development/industry-type/all/${params}`)
export const serviceEngagementsSD = (params) => API.get(`/service/software-development/service-engagement/all/${params}`)
export const createRequestAsGuestSD = (data) => API.post("/service/software-development/guest/request/new/", data, getReqConfig(data))
export const createRequestSD = (data) => API.post("/service/software-development/request/new/", data, getReqConfig(data))
export const getRequestSD = (refId) => API.get(`/service/software-development/request/${refId}/`)
export const getRequestTitleSD = (refId) => API.get(`/service/software-development/request/${refId}/title/`)
export const FPandARevolutionServicesFT = (params) => API.get(`/service/finance-transformation/fp-and-a-revolution/service/all/${params}`)
export const FPandARevolutionChoiceOfToolsFT = (params) => API.get(`/service/finance-transformation/fp-and-a-revolution/choice-of-tool/all/${params}`)
export const FPandARevolutionCreateRequestAsGuestFT = (data) => API.post("/service/finance-transformation/fp-and-a-revolution/guest/request/new/", data, getReqConfig(data))
export const FPandARevolutionCreateRequestFT = (data) => API.post("/service/finance-transformation/fp-and-a-revolution/request/new/", data, getReqConfig(data))
export const FPandARevolutionGetRequestFT = (refId) => API.get(`/service/finance-transformation/fp-and-a-revolution/request/${refId}/`)
export const FPandARevolutionGetRequestTitleFT = (refId) => API.get(`/service/finance-transformation/fp-and-a-revolution/request/${refId}/title/`)
export const FRAAServices_FT = (params) => API.get(`/service/finance-transformation/finance-reporting-and-advanced-analytics/service/all/${params}`)
export const FRAAChoiceOfTools_FT = (params) => API.get(`/service/finance-transformation/finance-reporting-and-advanced-analytics/choice-of-tool/all/${params}`)
export const FRAACreateRequestAsGuestFT = (data) => API.post("/service/finance-transformation/finance-reporting-and-advanced-analytics/guest/request/new/", data, getReqConfig(data))
export const FRAACreateRequestFT = (data) => API.post("/service/finance-transformation/finance-reporting-and-advanced-analytics/request/new/", data, getReqConfig(data))
export const FRAAGetRequestFT = (refId) => API.get(`/service/finance-transformation/finance-reporting-and-advanced-analytics/request/${refId}/`)
export const FRAAGetRequestTitleFT = (refId) => API.get(`/service/finance-transformation/finance-reporting-and-advanced-analytics/request/${refId}/title/`)
export const EnterpriseChoiceOfTools_FT = (params) => API.get(`/service/finance-transformation/enterprise/choice-of-tool/all/${params}`)
export const EnterpriseCreateRequestAsGuest_FT = (data) => API.post("/service/finance-transformation/enterprise/guest/request/new/", data, getReqConfig(data))
export const EnterpriseCreateRequest_FT = (data) => API.post("/service/finance-transformation/enterprise/request/new/", data, getReqConfig(data))
export const EnterpriseGetRequestFT = (refId) => API.get(`/service/finance-transformation/enterprise/request/${refId}/`)
export const EnterpriseGetRequestTitleFT = (refId) => API.get(`/service/finance-transformation/enterprise/request/${refId}/title/`)
export const SystemAdministrationChoiceOfTools_FT = (params) => API.get(`/service/finance-transformation/system-administration/choice-of-tool/all/${params}`)
export const SystemAdministrationCreateRequestAsGuest_FT = (data) => API.post("/service/finance-transformation/system-administration/guest/request/new/", data, getReqConfig(data))
export const SystemAdministrationCreateRequest_FT = (data) => API.post("/service/finance-transformation/system-administration/request/new/", data, getReqConfig(data))
export const SystemAdministrationGetRequestFT = (refId) => API.get(`/service/finance-transformation/system-administration/request/${refId}/`)
export const SystemAdministrationGetRequestTitleFT = (refId) => API.get(`/service/finance-transformation/system-administration/request/${refId}/title/`)
export const DigitalTransformationServices_FT = (params) => API.get(`/service/finance-transformation/digital-transformation/service/all/${params}`)
export const DigitalTransformationCreateRequestAsGuest_FT = (data) => API.post("/service/finance-transformation/digital-transformation/guest/request/new/", data, getReqConfig(data))
export const DigitalTransformationCreateRequest_FT = (data) => API.post("/service/finance-transformation/digital-transformation/request/new/", data, getReqConfig(data))
export const DigitalTransformationGetRequestFT = (refId) => API.get(`/service/finance-transformation/digital-transformation/request/${refId}/`)
export const DigitalTransformationGetRequestTitleFT = (refId) => API.get(`/service/finance-transformation/digital-transformation/request/${refId}/title/`)
export const createRequestAsGuest_FT = (data) => API.post("/service/finance-transformation/guest/request/new/", data, getReqConfig(data))
export const createRequest_FT = (data) => API.post("/service/finance-transformation/request/new/", data, getReqConfig(data))
export const getRequestFT = (refId) => API.get(`/service/finance-transformation/request/${refId}/`)
export const getRequestTitleFT = (refId) => API.get(`/service/finance-transformation/request/${refId}/title/`)
export const expertsMLAI = (params) => API.get(`/service/machine-learning-and-ai/expert/all/${params}`)
export const createRequestAsGuestMLAI = (data) => API.post("/service/machine-learning-and-ai/guest/request/new/", data, getReqConfig(data))
export const createRequestMLAI = (data) => API.post("/service/machine-learning-and-ai/request/new/", data, getReqConfig(data))
export const getRequestMLAI = (refId) => API.get(`/service/machine-learning-and-ai/request/${refId}/`)
export const getRequestTitleMLAI = (refId) => API.get(`/service/machine-learning-and-ai/request/${refId}/title/`)
export const allUserRequest = () => API.get("/service/user-request/all/")
export const latestUserRequest = () => API.get("/service/user-request/latest/")


// Account
export const getAllCounties = () => API.get("/account/country/all/")
export const getCountryStates = (iso3) => API.get(`/account/country/${iso3}/states/all/`)
export const clientProfileCompletionScore = () => API.get("/account/client/profile-completion/score/")
export const clientAboutUpdate = (data) => API.put("/account/client/about/update/", data, getReqConfig(data))
export const freelancerProfileCompletionScore = () => API.get("/account/freelancer/individual/profile-completion/score/")
export const freelancerAboutUpdate = (data) => API.put("/account/freelancer/individual/about/update/", data, getReqConfig(data))
export const freelancerServiceProjectGetAllProjectSize = (params) => API.get(`/account/freelancer/individual/service/projects/project-size/all/${params}`)
export const freelancerAddServiceProject = (data) => API.post("/account/freelancer/individual/service/projects/add/", data, getReqFileConfig(data))
export const freelancerUpdateServiceProject = (projectId, data) => API.post(`/account/freelancer/individual/service/projects/${projectId}/update/`, data, getReqFileConfig(data))
export const freelancerServiceDetailsSet = (data) => API.post("/account/freelancer/individual/service-details/set/", data, getReqFileConfig(data))
export const freelancerVerifyAskForReviewLink = (data) => API.get(`/account/freelancer/individual/review-and-references/ask/verify-link/${data.token}/`)
export const allFreelancerServiceProject = (id) => API.get(`/account/freelancer/individual/service/${id}/projects/all/`)
export const getFreelancerServiceProjectDetails = (id) => API.get(`/account/freelancer/individual/service/projects/${id}/details/`)
export const getPublicFreelancerProfile = (id) => API.get(`/account/freelancer/individual/public/${id}/profile/`)
export const getPublicFreelancerServices = (id) => API.get(`/account/freelancer/individual/public/${id}/services/`)
export const getPublicFreelancerServiceProjects = (freelancerId, serviceId) => API.get(`/account/freelancer/individual/public/${freelancerId}/service/${serviceId}/projects/`)
export const getPublicFreelancerServiceProjectDetails = (id) => API.get(`/account/freelancer/individual/public/service/projects/${id}/details/`)
export const businessProfileCompletionScore = () => API.get("/account/freelancer/business/profile-completion/score/")
export const businessDetailsSet = (data) => API.post("/account/freelancer/business/details/set/", data, getReqConfig(data))
export const businessDetailsUpdate = (data) => API.put("/account/freelancer/business/details/update/", data, getReqConfig(data))
export const businessPersonalContactSet = (data) => API.post("/account/freelancer/business/personal-contact/set/", data, getReqConfig(data))
export const businessPersonalContactUpdate = (data) => API.put("/account/freelancer/business/personal-contact/update/", data, getReqConfig(data))
export const businessAboutGetAllService = (params) => API.get(`/account/freelancer/business/about/service/all/${params}`)
export const businessAboutGetAllTotalEmployee = (params) => API.get(`/account/freelancer/business/about/total-employee/all/${params}`)
export const businessAboutGetAllMinimumProjectSize = (params) => API.get(`/account/freelancer/business/about/minimum-project-size/all/${params}`)
export const businessAboutGetAllAverageHourlyRate = (params) => API.get(`/account/freelancer/business/about/average-hourly-rate/all/${params}`)
export const businessAboutSet = (data) => API.post("/account/freelancer/business/about/set/", data, getReqConfig(data))
export const businessAboutUpdate = (data) => API.put("/account/freelancer/business/about/update/", data, getReqConfig(data))
export const businessLocationSet = (data) => API.post("/account/freelancer/business/location/set/", data, getReqConfig(data))
export const businessLocationUpdate = (data) => API.put("/account/freelancer/business/location/update/", data, getReqConfig(data))
export const businessSocialProfilesSet = (data) => API.post("/account/freelancer/business/social-profiles/set/", data, getReqConfig(data))
export const businessSocialProfilesUpdate = (data) => API.put("/account/freelancer/business/social-profiles/update/", data, getReqConfig(data))
export const businessCompetitorsSet = (data) => API.post("/account/freelancer/business/competitors/set/", data, getReqConfig(data))
export const businessCompetitorsUpdate = (data) => API.put("/account/freelancer/business/competitors/update/", data, getReqConfig(data))
export const businessReviewAndReferencesSet = (data) => API.post("/account/freelancer/business/review-and-references/set/", data, getReqConfig(data))
export const businessVerifyAskForReviewLink = (data) => API.get(`/account/freelancer/business/review-and-references/ask/verify-link/${data.token}/`)
export const getPublicBusinessProfile = (id) => API.get(`/account/freelancer/business/public/profile/${id}/`)
export const freelancerPrimaryServiceSet = (data) => API.post("/account/freelancer/primary-service/set/", data, getReqConfig(data))
export const freelancerPrimaryServiceUpdate = (data) => API.put("/account/freelancer/primary-service/update/", data, getReqConfig(data))
export const freelancerLeadsLocationCreate = (data) => API.post("/account/freelancer/leads-location/create/", data, getReqConfig(data))
export const freelancerLeadsLocationUpdate = (data) => API.put("/account/freelancer/leads-location/update/", data, getReqConfig(data))
export const freelancerOtherRemainingServices = () => API.get("/account/freelancer/other-services/remaining/")
export const freelancerOtherServiceUpdate = (data) => API.patch("/account/freelancer/other-service/update/", data, getReqConfig(data))
export const serviceRelatedFreelancers = (id) => API.get(`/account/freelancer/related-to/service/${id}/`)
export const getReviewAndReferences = () => API.get("/account/freelancer/review-and-references/get/")
export const askForReviewAndReferences = (data) => API.post("/account/freelancer/review-and-references/ask/", data, getReqConfig(data))
export const reviewAndReferencesSubmit = (data) => API.patch("/account/freelancer/review-and-references/submit/", data, getReqConfig(data))

// Account (Settings)
export const updateProfilePicture = (data) => API.post("/account/update/profile/", data, getReqFileConfig(data))
export const checkEmailAndSendVerificationCode = (data) => API.post("/account/update/email/verification-code/send/", data, getReqConfig(data))
export const verifyCodeAndUpdateEmail = (data) => API.post("/account/update/email/verification-code/verify/", data, getReqConfig(data))
export const clientPersonalDetailsUpdate = (data) => API.put("/account/client/settings/personal-details/update/", data, getReqConfig(data))
export const clientProfileDetailsUpdate = (data) => API.put("/account/client/settings/profile-details/update/", data, getReqConfig(data))
export const freelancerPersonalDetailsUpdate = (data) => API.put("/account/freelancer/individual/settings/personal-details/update/", data, getReqConfig(data))
export const freelancerBusinessDetailsUpdate = (data) => API.put("/account/freelancer/individual/settings/business-details/update/", data, getReqConfig(data))


// Message
export const directMessageToUser = (data) => API.post("/message/client/direct-message/to-user/", data, getReqConfig(data))
export const allClientDirectMessageOfRequest = (id) => API.get(`/message/client/direct-message/all/request/${id}/`)
export const allFreelancerDirectMessage = () => API.get("/message/freelancer/direct-message/all/")
export const getFreelancerDirectMessage = (id) => API.get(`/message/freelancer/direct-message/${id}/`)
export const unlockFreelancerDirectMessage = (id) => API.get(`/message/freelancer/direct-message/${id}/unlock-message/`)
export const unlockFreelancerDirectMessageClient = (id) => API.get(`/message/freelancer/direct-message/${id}/unlock-client/`)
export const unlockFreelancerDirectMessageRequest = (id) => API.get(`/message/freelancer/direct-message/${id}/unlock-request/`)
export const toggleFreelancerDirectMessageReadStatus = (data) => API.patch("/message/freelancer/direct-message/read-status/toggle/", data, getReqConfig(data))
export const allBusinessDirectMessage = () => API.get("/message/business/direct-message/all/")
export const getBusinessDirectMessage = (id) => API.get(`/message/business/direct-message/${id}/`)
export const toggleBusinessDirectMessageReadStatus = (data) => API.patch("/message/business/direct-message/read-status/toggle/", data, getReqConfig(data))


// Payments
export const getBusinessPaidPlans = () => API.get("/payments/business/plans/")
export const createBusinessCheckoutSession = (data) => API.post("/payments/business/checkout/session/", data, getReqConfig(data))
export const verifyCheckoutSession = (data) => API.get(`/payments/checkout/${data.sessionId}/verify/${data.status}/`)
export const getFreelancerVersiGemsPlans = () => API.get("/payments/freelancer/plans/versigems/")
export const createFreelancerCheckoutSession = (data) => API.post("/payments/freelancer/checkout/session/", data, getReqConfig(data))