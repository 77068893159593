import { useSelector, useDispatch } from "react-redux"
import { useLocation, Link } from "react-router-dom"
import { useState, useEffect } from "react"
import { RxHamburgerMenu } from "react-icons/rx"
import { FaCrown } from "react-icons/fa"

import arrowDownIcon from "../../../assets/svgs/arrow-down.svg"
import settingIcon from "../../../assets/icons/dashboard/setting.svg"
import logoutIcon from "../../../assets/icons/dashboard/login.svg"
import { logoutUser } from "../../../actions/auth"
import { getInitials, titleCase } from "../../../utils"
import { BUSINESS, accountSettingsPath, dashboardPath } from "../../../assets/constants"
import { toggleSideNavbar, showProfileOptions, profileWrapperId, profileOptionsId } from "./utils"
import "./index.css"

const Header = () => {
    const { user } = useSelector((state) => state.auth)
    const location = useLocation()

    const fullName = user.accountType === BUSINESS ? user.business.details.data.companyName : user.about.firstName + " " + user.about.lastName
    const initials = getInitials(fullName)

    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 1080)
    useEffect(() => {
        const changeSmallScreen = () => setIsSmallScreen(window.innerWidth <= 1080)
        window.addEventListener("resize", changeSmallScreen)
        return () => {
            window.removeEventListener("resize", changeSmallScreen)
        }
    }, [])
    useEffect(() => {
        toggleSideNavbar(true)
    }, [isSmallScreen])

    const dispatch = useDispatch()
    const handleLogout = () => {
        dispatch(logoutUser())
    }
    return (
        <div id="dh-header" className="bg-white flex justify-between xl1080:justify-end p-5">
            {isSmallScreen &&
                <button className="w-10" onClick={toggleSideNavbar} type="button" title="Navigation">
                    <RxHamburgerMenu className="text-versich-blue text-xl" />
                </button>
            }
            <div id={profileWrapperId} className="inline-flex gap-x-2.5 items-center relative cursor-pointer" onClick={showProfileOptions}>
                {user.accountType === BUSINESS && user.plan && user.plan.name === "premium" && user.plan.status === "active" &&
                    location.pathname !== dashboardPath &&
                    <div className="bg-yellow-50 text-yellow-600 inline-flex items-center justify-center gap-2 mr-2 py-1 lg:py-1.5 px-1.5 lg:px-2 border border-yellow-50 smaller:border-yellow-300 rounded-lg cursor-default" title="Premium plan">
                        <FaCrown className="text-yellow-500 w-5 h-5 mb-auto" title="Premium plan" />
                        <span className="text-sm font-medium max-smaller:hidden">Premium</span>
                    </div>
                }

                {/* Avatar */}
                <div className="bg-[#F6F9FF] w-6 h-6 lg:h-8 lg:w-8 rounded-full">
                    {user.profile?.image
                        ? <img className="w-full h-full object-cover rounded-full" src={user.profile.image} alt="" />
                        : user.social?.image
                            ? <img className="w-full h-full object-cover rounded-full" src={user.social.image} alt="" />
                            : <div className="text-versich-darktext-color text-xs lg:text-sm font-semibold flex justify-center items-center w-full h-full rounded-full">
                                {initials}
                            </div>
                    }
                </div>
                <div className="flex-1 flex flex-col text-start text-versich-dark-blue font-semibold text-xs lg:text-sm" data-parent-id={profileWrapperId}>
                    <span data-parent-id={profileWrapperId}>{fullName}</span>
                    <span className="text-versich-blue-hover text-[10px]" data-parent-id={profileWrapperId}>{titleCase(user.accountType)}</span>
                </div>
                <img src={arrowDownIcon} alt="" data-parent-id={profileWrapperId} />
                <div id={profileOptionsId} className="absolute mt-1.5 top-full right-0 w-[200px] bg-white rounded-md shadow-selection z-10">
                    <div className="p-2" data-parent-id={profileOptionsId}>
                        <Link className={`hover:bg-versich-blue-hover/5 text-[#0A1215] text-xs lg:text-sm font-medium w-full flex items-center gap-2 p-2 rounded-lg cursor-pointer`} to={accountSettingsPath} data-parent-id={profileOptionsId}>
                            <img className="w-3.5 h-3.5 lg:w-4 lg:h-4" src={settingIcon} alt="" data-parent-id={profileOptionsId} />
                            <span data-parent-id={profileOptionsId}>Account Settings</span>
                        </Link>
                        <button className={`hover:bg-versich-blue-hover/5 text-[#0A1215] text-xs lg:text-sm font-medium w-full flex items-center gap-2 p-2 rounded-lg cursor-pointer`} onClick={handleLogout} data-parent-id={profileOptionsId}>
                            <img className="w-3.5 h-3.5 lg:w-4 lg:h-4" src={logoutIcon} alt="" data-parent-id={profileOptionsId} />
                            <span data-parent-id={profileOptionsId}>Logout</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Header