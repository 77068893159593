import { useSelector, useDispatch } from "react-redux"
import { useForm, FormProvider } from "react-hook-form"
import { useState, useEffect } from "react"

import curvedEditIcon from "../../../../assets/svgs/curved-edit.svg"
import DialogBox, { closeDialog, getDialog, openDialog } from "../../../DialogBox"
import FormLoader from "../../../loader/FormLoader"
import Close from "../../../svgs/Close"
import CSRFTokenField from "../../../Inputs/CSRFTokenField"
import InputText from "../../../Inputs/InputTextField"
import TextAreaField from "../../../Inputs/TextAreaField"
import PhoneNumberField, { validateNumber } from "../../../Inputs/PhoneNumberField"
import DropdownField from "../../../Inputs/DropdownField"
import toastify from "../../../../utils/toastify"
import { businessDetailsUpdateAsync } from "../../../../slices/freelancer/settings"
import { TOASTTYPE_ERROR, companySizes } from "../../../../assets/constants"

const BusinessDetails = () => {
    const { user } = useSelector((state) => state.auth)
    const methods = useForm()

    const [csrfToken, setCsrfToken] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [address, setAddress] = useState("")
    const [phoneCode, setPhoneCode] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [phoneNumberValid, setPhoneNumberValid] = useState(false)
    const [website, setWebsite] = useState("")
    const [companySize, setCompanySize] = useState("")
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    // Pre-fill Form
    const resetForm = () => {
        setCompanyName(user.about.companyName || "")
        setAddress(user.about.address)
        if (!user.about.phone) {
            return
        }
        const code = user.about.phone.code.replace("+", "")
        const fullPhoneNumber = code + user.about.phone.number
        setPhoneCode(code)
        setPhoneNumber(user.about.phone.number)
        setPhoneNumberValid(validateNumber(fullPhoneNumber))
        setWebsite(user.about.website || "")
        setCompanySize(user.about.companySize?.uid || "")
    }
    useEffect(() => {
        resetForm()
    }, [user])

    // Close dialog
    const customCloseDialog = (e) => {
        resetForm()
        closeDialog(e)
    }

    const dispatch = useDispatch()
    const onSubmit = async (data, event) => {
        try {
            const isValid = await methods.trigger()
            if (!isValid || !phoneNumberValid) {
                toastify("Form validation failed", TOASTTYPE_ERROR)
                return methods.reset()
            }
            const formData = {
                companyname: data.companyName,
                address: data.address,
                phonecode: `+${phoneCode}`,
                phonenumber: phoneNumber,
                website: data.website,
                companysize: companySize,
                token: csrfToken,
            }
            setLoading(true)
            await dispatch(businessDetailsUpdateAsync(formData))
                .unwrap()
                .then(() => {
                    const dialog = getDialog(event.target)
                    if (dialog) {
                        closeDialog(null, dialog)
                    }
                    methods.reset()
                    return setLoading(false)
                })
                .catch(error => {
                    const data = JSON.parse(error?.message)
                    return setErrorMsg(data.message)
                })
                .finally(() => setLoading(false))
        } catch (error) {
            console.error("Form submission error:", error)
        }
    }
    return (
        user.about.phone &&
        <div className="bg-white text-start p-4 sm:p-5 shadow-card5 rounded-lg">
            <div className="font-medium flex max-sm401:flex-col sm401:justify-between gap-x-3 gap-y-1.5 py-3 border-b border-[#EFEEEE99]">
                <span className="text-versich-dark-blue text-xl">Business Details</span>
                <button className="inline-flex items-center gap-1 hover:opacity-80" onClick={openDialog} data-parent-id={businessDetailsEditDialog}>
                    <img className="w-[18px] h-[18px]" src={curvedEditIcon} alt="" data-parent-id={businessDetailsEditDialog} />
                    <span className="text-versich-light-blue text-xs" data-parent-id={businessDetailsEditDialog}>Edit</span>
                </button>
            </div>
            <div className="text-versich-dark-blue my-5 space-y-8">
                <div className="grid grid-cols-1 sm:max-md:grid-cols-2 lg900:max-xl1080:grid-cols-2 xl:grid-cols-2 gap-x-3 gap-y-6">
                    <div className="text-sm">
                        <p className="font-medium opacity-80 mb-[7px]">Company Name</p>
                        <div className="font-semibold">{user.about.companyName || "-"}</div>
                    </div>
                    <div className="text-sm">
                        <p className="font-medium opacity-80 mb-[7px]">Company Size or No. of Employees</p>
                        <div className="font-semibold">{user.about.companySize.title} Employees</div>
                    </div>
                </div>
                <div className="grid grid-cols-1 sm:max-md:grid-cols-2 lg900:max-xl1080:grid-cols-2 xl:grid-cols-2 gap-x-3 gap-y-6">
                    <div className="text-sm">
                        <p className="font-medium opacity-80 mb-[7px]">Phone Number</p>
                        <div className="font-semibold">{user.about.phone.code} {user.about.phone.number}</div>
                    </div>
                    <div className="text-sm" style={{ overflowWrap: "anywhere" }}>
                        <p className="font-medium opacity-80 mb-[7px]">Website</p>
                        <div className="font-semibold">{user.about.website || "-"}</div>
                    </div>
                </div>
                <div className="text-sm">
                    <p className="font-medium opacity-80 mb-[7px]">Address</p>
                    <div className="font-semibold">{user.about.address}</div>
                </div>
            </div>
            <DialogBox
                id={businessDetailsEditDialog}
                className="backdrop:bg-[#1717253D] backdrop:backdrop-blur-sm max-sm:max-w-lg"
                toastStacked={false}
                hideClose={true}
                customCloseEvent={customCloseDialog}
            >
                <div className="space-y-[34px] p-2.5 md:p-4">
                    <div className="flex gap-1.5">
                        <div className="text-versich-dark-blue flex-1">
                            <div className="text-xl font-semibold">Business Details</div>
                            <div className="text-sm mt-0.5">Manage your business details</div>
                        </div>
                        <div id="dialog_closebtn" className="text-right">
                            <button type="button" className="m-1 p-1" onClick={customCloseDialog} data-parent-id={businessDetailsEditDialog}>
                                <Close className="w-4 h-4" />
                            </button>
                        </div>
                    </div>
                    <FormProvider {...methods}>
                        <form className="space-y-[34px]" method="dialog" onSubmit={methods.handleSubmit(onSubmit)} data-parent-id={businessDetailsEditDialog}>
                            <div className="space-y-3">
                                <InputText name="companyName" inputType="text" placeholder="e.g. Business Solution" label="Company Name" onChange={(e) => setCompanyName(e.target.value)} defaultValue={companyName} />
                                <TextAreaField name="address" label="Address" onChange={(e) => setAddress(e.target.value)} defaultValue={address} rules={{ required: "Address is required" }} />
                                <PhoneNumberField
                                    name="phonenumber"
                                    rules={{ required: "Phone number is required" }}
                                    setPhoneCode={setPhoneCode}
                                    setPhoneNumber={setPhoneNumber}
                                    setPhoneNumberValid={setPhoneNumberValid}
                                    value={phoneCode + phoneNumber}
                                />
                                <InputText name="website" inputType="url" placeholder="e.g. https://a2zsolutions.com" label="Website Address" onChange={(e) => setWebsite(e.target.value)} defaultValue={website} />
                                <DropdownField
                                    name="companySize"
                                    label="Company Size or No of Employees"
                                    options={companySizes}
                                    value={companySize}
                                    placeholder="Select number of Employee"
                                    rules={{ required: "Company size is required" }}
                                    onChange={(e) => setCompanySize(e.target.value)}
                                />
                                <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                            </div>
                            {errorMsg && <div className="text-sm text-red-500 text-left mt-8 mb-4">{errorMsg}</div>}
                            <div className="text-sm font-semibold w-full flex max-sm401:flex-col justify-end gap-3 md:gap-5">
                                <button className="bg-white hover:opacity-80 text-versich-light-blue border border-versich-light-blue py-2.5 md:py-3 px-8 md:px-10 rounded-lg" type="button" onClick={customCloseDialog} data-parent-id={businessDetailsEditDialog}>Cancel</button>
                                <button className="bg-versich-blue hover:bg-versich-blue-hover disabled:bg-versich-blue-hover text-white disabled:opacity-70 py-2.5 md:py-3 px-8 md:px-10 rounded-lg disabled:cursor-not-allowed" type="submit">Update</button>
                            </div>
                        </form>
                    </FormProvider>
                </div>
                {loading && <FormLoader />}
            </DialogBox>
        </div>
    )
}
const businessDetailsEditDialog = "businessdetails-edit-dialog"
export default BusinessDetails