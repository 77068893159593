import { useEffect, useState } from "react"

import fileuploadIcon from "../../assets/svgs/file-upload.svg"
import AddPlus from "../svgs/AddPlus"
import { uploadBlobImage } from "./utils"

const ImagePreviewInput = ({ name, maxSizeInMb, defaultUrl, setDefaultUrls, setFiles }) => {
    const [file, setFile] = useState(null)
    const [uploadError, setUploadError] = useState("")
    const [preview, setPreview] = useState(defaultUrl || null)

    // Track value change
    useEffect(() => {
        setPreview(defaultUrl)
    }, [defaultUrl])

    const handleFileSelect = (e) => {
        if (e.target.files.length > 0) {
            const file = e.target.files[0]
            const fileSizeMb = (file.size / (1024 ** 2)).toFixed(2)
            if ((file.type !== "" && allowedFileTypes.includes(file.type) === false)
                || (file.type === "" && file.name.endsWith(".heic") === false)) {
                setUploadError("Choose valid image file with extension .jpeg, .png or .heic")
            }
            else if (fileSizeMb < maxSizeInMb) {
                setUploadError("")
                setPreview(uploadBlobImage(file))
                return setFile(file)
            } else {
                setUploadError(`Selected image size is greater than ${maxSizeInMb}MB`)
            }
        }
        setFile(null)
    }
    useEffect(() => {
        file && setFiles(prev => [...prev, file])
    }, [file])

    // Remove selected image
    const removeImage = () => {
        file && setFiles(prev => prev.filter(
            f => !(f.name === file.name
                && f.size === file.size
                && f.type === file.type
            )
        ))
        setFile(null)
        setDefaultUrls(prev => prev.filter(u => u !== preview))
        URL.revokeObjectURL(preview)
        setPreview(null)
    }
    return (
        preview
            ? <div className="group relative rounded-[10px] overflow-hidden">
                <img className="aspect-square" src={preview} alt="" />
                <div className="group-hover:bg-majorelle-blue/30 hidden group-hover:flex items-center justify-center absolute inset-0 cursor-pointer" onClick={removeImage}>
                    <AddPlus className="[&>*]:stroke-versich-primary-bg w-14 h-14 rotate-45" />
                </div>
            </div>
            : <div>
                <label className="bg-[#F0F2FF] flex flex-col items-center justify-center gap-y-3 aspect-square py-2 px-4 rounded-[10px] cursor-pointer">
                    <input name={name} type="file" accept="image/*" hidden onChange={handleFileSelect} />
                    <img src={fileuploadIcon} alt="" />
                    <span className="text-xs text-center">Click to add image</span>
                </label>
                {uploadError !== "" && <p className="text-red-500 text-sm">{uploadError}</p>}
            </div>
    )
}
const allowedFileTypes = ["image/png", "image/jpeg"]
export default ImagePreviewInput