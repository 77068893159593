import { createAsyncThunk } from "@reduxjs/toolkit"

import * as api from "../../api"
import { getUser } from "../../actions/auth"
import apiErrorParser from "../../utils/apiErrorParser"

export const businessPersonalContactSetAsync = createAsyncThunk("account/businessPersonalContactSet",
    async ({ firstname, lastname, role, phonecode, phonenumber, token }, { dispatch }) => {
        try {
            const { data } = await api.businessPersonalContactSet({ firstname, lastname, role, phonecode, phonenumber, csrfmiddlewaretoken: token })
            if (data.status !== "success") {
                throw Error(JSON.stringify(data))
            }
            await dispatch(getUser())
            return data
        }
        catch (error) {
            throw apiErrorParser(error)
        }
    })

export const businessPersonalContactUpdateAsync = createAsyncThunk("account/businessPersonalContactUpdate",
    async ({ firstname, lastname, role, phonecode, phonenumber, token }, { dispatch }) => {
        try {
            const { data } = await api.businessPersonalContactUpdate({ firstname, lastname, role, phonecode, phonenumber, csrfmiddlewaretoken: token })
            if (data.status !== "success") {
                throw Error(JSON.stringify(data))
            }
            await dispatch(getUser())
            return data
        }
        catch (error) {
            throw apiErrorParser(error)
        }
    })