import sendIcon from "../../assets/svgs/send.svg"
import ProfileCopiedNotification from "./notifications/ProfileCopiedNotification"
import { copyTextToClipboard } from "../../utils"

const ShareProfileButton = ({ profilePath, userName, setNotifications }) => {

    // Copy profile link & show notification
    const copyProfileToClipBoard = () => {
        copyTextToClipboard(
            process.env.REACT_APP_CLIENT_URL + profilePath
        )
        const notification = <ProfileCopiedNotification profilePath={profilePath} userName={userName} hideNotification={hideNotification} />
        setNotifications(prev => [
            ...prev.filter(n => n.type.name !== notification.type.name),
            notification
        ])
    }

    // Remove notification
    const hideNotification = () => {
        setNotifications(prev => {
            const notification = <ProfileCopiedNotification profilePath={profilePath} userName={userName} hideNotification={hideNotification} />
            return prev.filter(n => n.type.name !== notification.type.name)
        })
    }
    return (
        <button className="bg-versich-blue hover:bg-versich-blue-hover text-white text-sm min-h-12 inline-flex items-center justify-center gap-2 py-2 lg:py-3 px-3.5 lg:px-5 border-2 border-versich-blue hover:border-versich-blue-hover rounded-[10px]" type="button" onClick={copyProfileToClipBoard}>
            <img src={sendIcon} alt="" />
            <span>Share Profile</span>
        </button>
    )
}
export default ShareProfileButton