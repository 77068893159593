import { useSelector } from "react-redux"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { FaCrown } from "react-icons/fa"

import DashboardLayout from "../../../components/Dashboard/DashboardLayout"
import Star from "../../../components/svgs/Star"
import ShareProfileButton from "../../../components/Dashboard/ShareProfileButton"
import Metrics from "../../../components/Dashboard/business/Metrics"
import About from "../../../components/Dashboard/business/About"
import OtherDetails from "../../../components/Dashboard/business/OtherDetails"
import Location from "../../../components/Dashboard/business/Location"
import SocialMedia from "../../../components/Dashboard/business/SocialMedia"
import Competitors from "../../../components/Dashboard/business/Competitors"
import Reviews from "../../../components/Dashboard/business/Reviews"
import { BusinessMenu } from "../../../components/Dashboard/constants"
import { businessPricingPath } from "../../../assets/constants"

const BusinessDashboard = () => {
    const { user } = useSelector((state) => state.auth)
    const [notifications, setNotifications] = useState([])
    const firstName = user.business.details.data.companyName.split(" ")[0]

    const navigate = useNavigate()
    const businessProfilePath = `/business/${user.business.details.data.companyId}/`
    return (
        <DashboardLayout navlinks={BusinessMenu} activePage="Dashboard">

            {/* All notification */}
            {notifications.length > 0 && notifications.map((notify, index) => (
                <div key={index}>
                    {notify}
                </div>
            ))}
            <div className="flex max-sm:flex-col sm:items-center gap-2.5">
                <div className="text-versich-dark-blue text-start flex-1 space-y-[2px]">
                    <div className="text-lg lg:text-xl font-semibold">Hello {firstName}!</div>
                    <div className="text-sm">Dashboard Overview</div>
                </div>
                <div className="text-sm font-semibold flex flex-wrap gap-2.5 py-1">
                    {user.plan && user.plan.name === "premium" && user.plan.status === "active"
                        ? <button className="bg-white hover:bg-yellow-50 text-yellow-500 inline-flex items-center justify-center gap-2 py-2 lg:py-3 px-4 lg:px-6 border-2 border-yellow-300 rounded-xl" type="button" onClick={() => navigate(businessPricingPath)}>
                            <FaCrown className="text-yellow-500 w-5 h-5 mb-auto" title="Premium plan" />
                            <span>Versi Premium</span>
                        </button>
                        : <button className="bg-white hover:bg-indigo-50/50 text-versich-light-blue inline-flex items-center justify-center gap-2 py-2 lg:py-3 px-4 lg:px-6 border-2 border-[#5DA6F4] rounded-xl" type="button" onClick={() => navigate(businessPricingPath)}>
                            <Star className="fill-[#5DA6F4] w-[22px] h-[22px]" title="Free plan" />
                            <span>Versi Starter</span>
                        </button>
                    }
                    <ShareProfileButton
                        profilePath={businessProfilePath}
                        userName={user.business.details.data.companyName}
                        setNotifications={setNotifications}
                    />
                </div>
            </div>
            <Metrics data={user.business.metrics} />
            <div className="grid md:grid-cols-2 gap-x-4 gap-y-3">
                <About data={user.business.about} />
                <OtherDetails data={user.business.about} />
                <Location data={user.business.location.data} />
                <SocialMedia data={user.business.socialProfiles.data} />
            </div>
            <Competitors data={user.business.competitors} />
            <Reviews />
        </DashboardLayout>
    )
}
export default BusinessDashboard