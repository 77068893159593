
const MiddleTextSeparator = ({ word }) => {
    return (
        <div className="flex items-center gap-5 justify-between px-2.5 middle_text_separator">
            <div className="bg-[#E0E0E0] h-[1px] w-full rounded-md" />
            <div className="text-[#616161] mts_word">{word}</div>
            <div className="bg-[#E0E0E0] h-[1px] w-full" />
        </div>
    )
}
export default MiddleTextSeparator