import { numify } from "numify"

import profileTickIcon from "../../../assets/svgs/profile-tick.svg"
import People from "../../svgs/People"

const Metrics = ({ data }) => {
    return (
        <div className="grid smaller:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-x-4 gap-y-3">
            <div className="bg-white text-left flex xl:max-largeLaptop:flex-col-reverse justify-between gap-4 py-6 px-5 border-b-[3px] border-[#7EC2FF] shadow-card3 rounded-lg">
                <div className="space-y-3">
                    <div className="text-[#6C7278] text-sm font-semibold">Total Views</div>
                    <div className="text-[#1A1C1E] text-2xl font-bold">{numify(data.views) || 0}</div>
                </div>
                <div>
                    <div className="bg-[#7EC2FF]/[0.15] inline-flex items-center justify-center min-w-8 h-8 rounded-[3px]">
                        <img className="w-5 h-5" src={profileTickIcon} alt="" />
                    </div>
                </div>
            </div>
            <div className="bg-white text-left flex xl:max-largeLaptop:flex-col-reverse justify-between gap-4 py-6 px-5 border-b-[3px] border-[#FFD66B] shadow-card3 rounded-lg">
                <div className="space-y-3">
                    <div className="text-[#6C7278] text-sm font-semibold">Total Interactions</div>
                    <div className="text-[#1A1C1E] text-2xl font-bold">{data.interactions || 0}</div>
                </div>
                <div>
                    <div className="bg-[#FFFAE1] inline-flex items-center justify-center min-w-8 h-8 rounded-[3px]">
                        <People className="w-5 h-5 [&>path]:fill-[#FFD66B]" />
                    </div>
                </div>
            </div>
            <div className="bg-white text-left flex xl:max-largeLaptop:flex-col-reverse justify-between gap-4 py-6 px-5 border-b-[3px] border-[#59B29F] shadow-card3 rounded-lg">
                <div className="space-y-3">
                    <div className="text-[#6C7278] text-sm font-semibold">Total Reviews</div>
                    <div className="text-[#1A1C1E] text-2xl font-bold">{data.reviews || 0}</div>
                </div>
                <div>
                    <div className="bg-[#4CBD9114] inline-flex items-center justify-center min-w-8 h-8 rounded-[3px]">
                        <People className="w-5 h-5 [&>path]:fill-[#59B29F]" />
                    </div>
                </div>
            </div>
            <div className="bg-white text-left flex xl:max-largeLaptop:flex-col-reverse justify-between gap-4 py-6 px-5 border-b-[3px] border-[#7EC2FF] shadow-card3 rounded-lg">
                <div className="space-y-3">
                    <div className="text-[#6C7278] text-sm font-semibold">Total Listings</div>
                    <div className="text-[#1A1C1E] text-2xl font-bold">{data.listings || 0}</div>
                </div>
                <div>
                    <div className="bg-[#7EC2FF]/[0.15] inline-flex items-center justify-center min-w-8 h-8 rounded-[3px]">
                        <img className="w-5 h-5" src={profileTickIcon} alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Metrics