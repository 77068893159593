import { useState, useEffect } from "react"
import { Link, useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import { BeatLoader } from "react-spinners"

import ErrorPage from "../../components/ErrorPage/ErrorPage"
import Navbar from "../../components/Navbar/Navbar"
import Meta from "../../components/Meta"
import internetIcon from "../../assets/svgs/internet.svg"
import MyServices from "../../components/Dashboard/freelancer/MyServices"
import About from "../../components/Dashboard/freelancer/About"
import Reviews from "../../components/Dashboard/business/Reviews"
import { getPublicFreelancerProfileAsync } from "../../slices/freelancer/public"
import { getInitials } from "../../utils"

const FreelancerProfile = () => {
    const { id } = useParams()
    const [user, setUser] = useState(undefined)
    const [metadata, setMetadata] = useState()

    const dispatch = useDispatch()
    const fetchFreelancerProfile = async (id) => {
        dispatch(getPublicFreelancerProfileAsync(id))
            .unwrap()
            .then((data) => {
                setUser(data.payload)
                const fullName = data.payload.about.firstName + " " + data.payload.about.lastName
                setMetadata({
                    title: fullName + " - Freelancer Profile",
                    description: `Public freelancer profile of ${fullName}`,
                    url: `${process.env.REACT_APP_CLIENT_URL}/freelancer/${id}/`
                })
            })
            .catch(() => setUser(null))
    }
    useEffect(() => {
        setMetadata({
            title: "Freelancer Profile",
            description: "Public freelancer profile",
            url: `${process.env.REACT_APP_CLIENT_URL}/freelancer/${id}/`
        })
        fetchFreelancerProfile(id)
    }, [id])
    return (
        user !== null
            ? <>
                <Navbar />
                {user === undefined
                    ? <div className="w-full h-80 flex justify-center items-center">
                        <BeatLoader loading={true} color="#1D88ED" size={10} />
                    </div>
                    : <>
                        {metadata && <Meta data={metadata} />}
                        <div className="bg-[#F5F7FF] py-8 px-8 md:px-16 lg:px-28 min-h-[calc(100vh-76px)] md:min-h-[calc(100vh-80px)]">
                            <div className="max_screen_w h-full">
                                <div className="flex flex-wrap items-center gap-2.5">
                                    <div className="flex-grow">
                                        {/* Avatar */}
                                        <div className="bg-white w-[135px] h-[135px] smallest:w-[150px] smallest:h-[150px] sm:w-[160px] sm:h-[160px] rounded-xl">
                                            {user.profile?.image
                                                ? <img className="w-full h-full object-cover rounded-xl" src={user.profile.image} alt="" />
                                                : <div className="text-versich-darktext-color text-3xl md:text-4xl font-semibold flex justify-center items-center w-full h-full shadow-card4 rounded-xl">
                                                    {getInitials(user.about.firstName + " " + user.about.lastName)}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {user.about.website &&
                                        <div className="text-sm font-semibold">
                                            <Link target="_blank" to={user.about.website} className="bg-white hover:text-versich-blue-hover text-versich-blue inline-flex items-center justify-center gap-2 border border-versich-blue py-1 lg:py-2 px-2 lg:px-4 rounded-lg">
                                                <img src={internetIcon} alt="" />
                                                <span>Visit Website</span>
                                            </Link>
                                        </div>
                                    }
                                </div>
                                <div className="mt-6 space-y-2.5">
                                    <div className="grid md:grid-cols-2 gap-x-4 gap-y-3">
                                        <MyServices data={user} freelancerId={id} />
                                        <About data={user.about} hideEmpty={true} />
                                    </div>
                                    {user.reviews && <Reviews data={user.reviews} />}
                                </div>
                            </div>
                        </div>
                    </>
                }
            </>
            : <ErrorPage status={404} />
    )
}
export default FreelancerProfile