import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { IoIosArrowDown } from "react-icons/io"

import { allCountriesAsync } from "../../slices/country"

const CountrySelectionField = ({ name, label, selectedCountry, onChange, regionError, readOnly }) => {
    const [countries, setCountries] = useState([])

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(allCountriesAsync())
            .then(data => {
                setCountries(data.payload)
            })
            .catch(() => { })
    }, [])
    return (
        <div className="space-y-2">
            {label &&
                <div className="flex items-center justify-between">
                    <label htmlFor={name} className="text-charcoal text-sm">{label}<span className="text-red-500 ml-1">*</span></label>
                </div>
            }
            <div className="relative">
                <select className={"bg-white text-sm w-full h-10 py-2.5 px-3 border-[1px] border-gray-300 shadow-input outline-none rounded-lg appearance-none "
                    + (selectedCountry === "" ? "text-versich-light-gray " : " ")
                    + (readOnly ? "text-versich-light-gray cursor-not-allowed" : "focus-visible:border-versich-blue focus:shadow-inputFocus")
                }
                    id={name}
                    name={name}
                    value={selectedCountry}
                    onChange={onChange}
                    disabled={readOnly}
                >
                    <option value="" hidden>Select Country</option>
                    {countries.map(country => (
                        <option className="text-versich-darktext-color" value={country.iso3} key={country.iso3}>{country.name}</option>
                    ))}
                </select>
                <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                    <IoIosArrowDown className="w-5 h-5 text-gray-400" />
                </div>
            </div>
            {regionError && !selectedCountry &&
                <p className="text-red-600 text-sm text-start">Please select a country.</p>
            }
        </div>
    )
}
export default CountrySelectionField