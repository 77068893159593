import { IoMdCloseCircle } from "react-icons/io"

const PurchasedVersiGemsNotification = ({ count, total, hideNotification }) => {
    return (
        <div className="bg-green-50/50 text-green-600 font-semibold text-start flex gap-4 justify-between w-full my-3.5 px-4 py-2 sm:px-6 sm:py-4 border border-green-200 shadow-sticker rounded-lg">
            <p>
                Purchase Complete! <span className="text-green-800 font-medium">You have successfully purchased</span> {count} VersiGems. <span className="text-green-800 font-medium">Your new balance is</span> {total} VersiGems.
            </p>
            <button className="hover:text-red-500 transition-colors" onClick={hideNotification}>
                <IoMdCloseCircle className="w-5 h-5" />
            </button>
        </div>
    )
}
export default PurchasedVersiGemsNotification