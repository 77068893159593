import AccountEmail from "./AccountEmail"
import AccountPassword from "./AccountPassword"
import SocialAccount from "./SocialAccount"

const AccountAndSecurity = ({ isSocial }) => {
    return (
        <div className="bg-white text-start p-4 sm:p-5 shadow-card5 rounded-lg">
            <div className="font-medium py-3 border-b border-[#EFEEEE99]">
                <span className="text-versich-dark-blue text-xl">Account and Security</span>
            </div>
            <div className="text-versich-dark-blue my-5 space-y-5">
                {
                    isSocial
                        ? <SocialAccount />
                        : <>
                            <AccountEmail />
                            <AccountPassword />
                        </>
                }
            </div>
        </div>
    )
}
export default AccountAndSecurity