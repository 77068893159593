import { useEffect } from "react"
import { useFormContext } from "react-hook-form"

const VerificationCodeField = ({ name, label, placeholder, hint, rules, onChange, defaultValue, readOnly, autoFocus }) => {
    const { register, formState, setValue, clearErrors } = useFormContext()
    const { errors } = formState

    useEffect(() => {
        setValue(name, defaultValue)
    }, [defaultValue, name])

    // Remove errors on value change
    useEffect(() => {
        clearErrors(name)
    }, [defaultValue])

    const handleChange = (e) => {
        setValue(name, e.target.value)
        onChange && onChange(e)
    }
    return (
        <div className="space-y-2">
            <div className="flex max-smaller:flex-col items-start gap-4">
                {label &&
                    <div className="flex flex-wrap gap-2 items-center justify-center smaller:mt-2.5">
                        <label htmlFor={name} className="text-charcoal text-sm">{label}{rules?.required && <span className="text-red-500 ml-1">*</span>}</label>
                    </div>
                }
                <div className="space-y-2">
                    <input
                        className={"text-versich-light-blue font-medium text-center h-10 py-2.5 px-3 border-[1px] shadow-input outline-none rounded-lg "
                            + (errors[name] ? "border-red-500 " : "border-gray-300 ")
                            + (readOnly ? "text-versich-light-gray cursor-not-allowed" : "focus-visible:border-versich-blue focus:shadow-inputFocus")
                        }
                        id={name}
                        name={name}
                        {...register(name, rules)}
                        type="text"
                        value={defaultValue}
                        placeholder={placeholder}
                        onChange={handleChange}
                        readOnly={readOnly}
                        autoFocus={autoFocus}
                    />
                    {hint &&
                        <div className="text-charcoal/70 text-sm text-left">{hint}</div>
                    }
                    {errors[name] &&
                        <div className="text-sm text-red-500 text-left">
                            {errors[name].message}
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
export default VerificationCodeField
