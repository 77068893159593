import * as api from "../api"
import apiErrorParser from "../utils/apiErrorParser"
import { getUser } from "./auth"

export const authenticateGoogleUser = (searchQuery) => async (dispatch) => {
    try {
        const { data } = await api.authenticateGoogleUser(searchQuery)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        await dispatch(getUser())
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
}

export const changeGoogleUser = (searchQuery) => async (dispatch) => {
    try {
        const { data } = await api.changeGoogleUser(searchQuery)
        await dispatch(getUser())
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
}