import { Link } from "react-router-dom"

import facebookIcon from "../../../assets/social-icons/facebook_color.svg"
import xIcon from "../../../assets/social-icons/x_color.svg"
import linkedinIcon from "../../../assets/social-icons/linkedin_color.svg"
import pinterestIcon from "../../../assets/social-icons/pinterest_color.svg"
import tiktokIcon from "../../../assets/social-icons/tiktok_color.svg"
import instagramIcon from "../../../assets/social-icons/instagram_color.svg"

export const LinkOrDiv = ({ children, href, className }) => (
    href
        ? <Link to={href} target="_blank" className={className}>
            {children}
        </Link>
        : <div className={className}>
            {children}
        </div>
)

const SocialMedia = ({ data, hideEmpty }) => {
    return (
        <div className="bg-white text-start p-4 sm:p-5 shadow-card4 rounded-lg">
            <div className="text-versich-dark-blue font-bold pb-4 border-b border-[#EDF0F5]">Social Media</div>
            <div className="grid grid-cols-1 lg900:max-xl1080:grid-cols-2 xl:grid-cols-2 gap-x-3 gap-y-6 mt-5 p-1">
                {((hideEmpty && data.facebook) || !hideEmpty) &&
                    <LinkOrDiv href={data.facebook} className={"flex gap-[26px] " + (data.facebook ? "group" : "opacity-60 cursor-not-allowed")}>
                        <div>
                            <div className="bg-brandeis-blue/[0.08] inline-flex items-center justify-center min-w-[43px] min-h-[43px] rounded-[10px]">
                                <img className="w-6 h-6" src={facebookIcon} alt="" />
                            </div>
                        </div>
                        <div className="text-versich-dark-blue text-sm w-[70%] overflow-hidden inline-flex flex-col">
                            <p className="font-semibold mb-[3px]">Facebook</p>
                            <div className="group-hover:text-versich-light-blue truncate">{data.facebook || "-"}</div>
                        </div>
                    </LinkOrDiv>
                }
                {((hideEmpty && data.x) || !hideEmpty) &&
                    <LinkOrDiv href={data.x} className={"flex gap-[26px] " + (data.x ? "group" : "opacity-60 cursor-not-allowed")}>
                        <div>
                            <div className="bg-brandeis-blue/[0.08] inline-flex items-center justify-center min-w-[43px] min-h-[43px] rounded-[10px]">
                                <img className="w-6 h-6" src={xIcon} alt="" />
                            </div>
                        </div>
                        <div className="text-versich-dark-blue text-sm w-[70%] overflow-hidden inline-flex flex-col">
                            <p className="font-semibold mb-[3px]">X</p>
                            <div className="group-hover:text-versich-light-blue truncate">{data.x || "-"}</div>
                        </div>
                    </LinkOrDiv>
                }
                {((hideEmpty && data.linkedin) || !hideEmpty) &&
                    <LinkOrDiv href={data.linkedin} className={"flex gap-[26px] " + (data.linkedin ? "group" : "opacity-60 cursor-not-allowed")}>
                        <div>
                            <div className="bg-brandeis-blue/[0.08] inline-flex items-center justify-center min-w-[43px] min-h-[43px] rounded-[10px]">
                                <img className="w-6 h-6" src={linkedinIcon} alt="" />
                            </div>
                        </div>
                        <div className="text-versich-dark-blue text-sm w-[70%] overflow-hidden inline-flex flex-col">
                            <p className="font-semibold mb-[3px]">Linkedin</p>
                            <div className="group-hover:text-versich-light-blue truncate">{data.linkedin || "-"}</div>
                        </div>
                    </LinkOrDiv>
                }
                {((hideEmpty && data.pinterest) || !hideEmpty) &&
                    <LinkOrDiv href={data.pinterest} className={"flex gap-[26px] " + (data.pinterest ? "group" : "opacity-60 cursor-not-allowed")}>
                        <div>
                            <div className="bg-brandeis-blue/[0.08] inline-flex items-center justify-center min-w-[43px] min-h-[43px] rounded-[10px]">
                                <img className="w-6 h-6" src={pinterestIcon} alt="" />
                            </div>
                        </div>
                        <div className="text-versich-dark-blue text-sm w-[70%] overflow-hidden inline-flex flex-col">
                            <p className="font-semibold mb-[3px]">Pinterest</p>
                            <div className="group-hover:text-versich-light-blue truncate">{data.pinterest || "-"}</div>
                        </div>
                    </LinkOrDiv>
                }
                {((hideEmpty && data.tiktok) || !hideEmpty) &&
                    <LinkOrDiv href={data.tiktok} className={"flex gap-[26px] " + (data.tiktok ? "group" : "opacity-60 cursor-not-allowed")}>
                        <div>
                            <div className="bg-brandeis-blue/[0.08] inline-flex items-center justify-center min-w-[43px] min-h-[43px] rounded-[10px]">
                                <img className="w-6 h-6" src={tiktokIcon} alt="" />
                            </div>
                        </div>
                        <div className="text-versich-dark-blue text-sm w-[70%] overflow-hidden inline-flex flex-col">
                            <p className="font-semibold mb-[3px]">Tiktok</p>
                            <div className="group-hover:text-versich-light-blue truncate">{data.tiktok || "-"}</div>
                        </div>
                    </LinkOrDiv>
                }
                {((hideEmpty && data.instagram) || !hideEmpty) &&
                    <LinkOrDiv href={data.instagram} className={"flex gap-[26px] " + (data.instagram ? "group" : "opacity-60 cursor-not-allowed")}>
                        <div>
                            <div className="bg-brandeis-blue/[0.08] inline-flex items-center justify-center min-w-[43px] min-h-[43px] rounded-[10px]">
                                <img className="w-6 h-6" src={instagramIcon} alt="" />
                            </div>
                        </div>
                        <div className="text-versich-dark-blue text-sm w-[70%] overflow-hidden inline-flex flex-col">
                            <p className="font-semibold mb-[3px]">Instagram</p>
                            <div className="group-hover:text-versich-light-blue truncate">{data.instagram || "-"}</div>
                        </div>
                    </LinkOrDiv>
                }
            </div>
        </div>
    )
}
export default SocialMedia