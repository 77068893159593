import { Link } from "react-router-dom"

import profileIcon from "../../../assets/icons/dashboard/profile.svg"
import chartIcon from "../../../assets/icons/dashboard/chart.svg"
import starIcon from "../../../assets/icons/dashboard/star.svg"
import tickSquareIcon from "../../../assets/icons/dashboard/tick-square.svg"

const Competitors = ({ data }) => {
    return (
        <div className="bg-white text-start p-4 sm:p-5 shadow-card4 rounded-lg">
            <div className="text-versich-dark-blue font-bold pb-4 border-b border-[#EDF0F5]">Competitors</div>
            <div>
                {data.map((competitor, index) => (
                    <div className="grid md:grid-cols-2 gap-x-6 gap-y-8 mt-5 pt-5 first-of-type:pt-0 border-t-2 first-of-type:border-t-0 border-[#EDF0F5]/50" key={index}>
                        <div className="flex gap-[26px]">
                            <div>
                                <div className="bg-brandeis-blue/[0.08] inline-flex items-center justify-center min-w-[43px] min-h-[43px] rounded-[10px]">
                                    <img className="w-6 h-6" src={profileIcon} alt="" />
                                </div>
                            </div>
                            <div className="text-versich-dark-blue text-sm">
                                <p className="font-semibold mb-[3px]">Company Name</p>
                                <div>{competitor.companyName}</div>
                            </div>
                        </div>
                        <Link to={competitor.website} className="flex gap-[26px] group">
                            <div>
                                <div className="bg-brandeis-blue/[0.08] inline-flex items-center justify-center min-w-[43px] min-h-[43px] rounded-[10px]">
                                    <img className="w-6 h-6" src={chartIcon} alt="" />
                                </div>
                            </div>
                            <div className="text-versich-dark-blue text-sm w-[70%] overflow-hidden">
                                <p className="font-semibold mb-[3px]">Competitor's Website</p>
                                <div className="group-hover:text-versich-light-blue truncate">{competitor.website}</div>
                            </div>
                        </Link>
                        <div className="flex gap-[26px]">
                            <div>
                                <div className="bg-brandeis-blue/[0.08] inline-flex items-center justify-center min-w-[43px] min-h-[43px] rounded-[10px]">
                                    <img className="w-6 h-6" src={starIcon} alt="" />
                                </div>
                            </div>
                            <div className="text-versich-dark-blue text-sm">
                                <p className="font-semibold mb-[3px]">Service you render they don't</p>
                                <div className="space-y-1.5">{competitor.uniqueServices.split("\n").map((desc, index) => <p key={index}>{desc}</p>)}</div>
                            </div>
                        </div>
                        <div className="flex gap-[26px]">
                            <div>
                                <div className="bg-brandeis-blue/[0.08] inline-flex items-center justify-center min-w-[43px] min-h-[43px] rounded-[10px]">
                                    <img className="w-6 h-6" src={tickSquareIcon} alt="" />
                                </div>
                            </div>
                            <div className="text-versich-dark-blue text-sm">
                                <p className="font-semibold mb-[3px]">Why you're better than them</p>
                                <div className="space-y-1.5">{competitor.whyBetter.split("\n").map((desc, index) => <p key={index}>{desc}</p>)}</div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
export default Competitors