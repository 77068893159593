import { useState, useEffect } from "react"

const About = ({ data }) => {
    const [showDesc, setShowDesc] = useState(null)

    useEffect(() => {
        const wordsInDesc = data.description.split(" ")
        if (wordsInDesc.length > 20) {
            setShowDesc(wordsInDesc.slice(0, 20).join(" "))
        }
        else setShowDesc(false)
    }, [])
    return (
        <div className="bg-white text-start p-4 sm:p-5 shadow-card4 rounded-lg">
            <div className="text-versich-dark-blue font-bold pb-4 border-b border-[#EDF0F5]">About Business</div>
            <div className="mt-5 space-y-4">
                <div>
                    <p className="text-versich-dark-blue text-sm font-semibold mb-2.5">What our business do</p>
                    <div className="flex gap-1 flex-wrap">
                        {data.services.map((service) => (
                            <span className="bg-[#F1F6FF] text-versich-light-blue text-[10px] font-medium py-1.5 px-2.5 rounded-md" key={service.uid}>
                                {service.uid === "other" && data.otherService ? data.otherService : service.title}
                            </span>
                        ))}
                    </div>
                </div>
                <div className="text-versich-dark-blue text-sm">
                    <p className="font-semibold mb-[3px]">Our Business Tagline</p>
                    <div>{data.tagline}</div>
                </div>
                <div className="text-versich-dark-blue text-sm">
                    <p className="font-semibold mb-[3px]">Business Description</p>
                    <div className="space-y-1.5">{
                        showDesc
                            ? showDesc
                            : data.description.split("\n").map((desc, index) => <p key={index}>{desc}</p>)
                    }</div>
                </div>
            </div>
            {showDesc &&
                <div className="flex justify-center mt-3">
                    <button className="text-versich-blue-hover hover:text-versich-blue text-sm font-medium" onClick={() => setShowDesc(false)}>Read more</button>
                </div>
            }
        </div>
    )
}
export default About