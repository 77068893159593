
const TextAreaFieldInput = ({ name, label, placeholder, required, onChange, defaultValue, error, readOnly }) => {
    return (
        <div className="space-y-2">
            {label &&
                <div className="flex flex-wrap gap-2 items-center justify-between">
                    <label htmlFor={name} className="text-versich-dark-blue text-base font-medium">{label}{required && <span className="text-red-500 ml-1">*</span>}</label>
                </div>
            }
            <textarea
                id={name}
                className={"text-sm font-medium w-full min-h-32 max-h-[384px] px-3 py-2 border-[1px] focus-visible:border-versich-blue shadow-input focus:shadow-inputFocus outline-none rounded-lg "
                    + (error ? "border-red-500" : "border-gray-300")}
                name={name}
                value={defaultValue}
                placeholder={placeholder}
                onChange={onChange}
                readOnly={readOnly}
            />
            {error &&
                <div className="text-sm text-red-500 text-left">
                    {required}
                </div>
            }
        </div>
    )
}
export default TextAreaFieldInput
