import { useSelector, useDispatch } from "react-redux"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

import Meta from "../../../../components/Meta"
import BrandBar from "../../../../components/Navbar/BrandBar"
import FormLoader from "../../../../components/loader/FormLoader"
import CSRFTokenField from "../../../../components/Inputs/CSRFTokenField"
import ServiceDetailsTile from "../../../../components/Services/ServiceDetailsTile"
import { dashboardPath, TOASTTIMER, TOASTTYPE_ERROR } from "../../../../assets/constants"
import { freelancerServiceDetailsSetAsync } from "../../../../slices/freelancer/serviceProject"
import toastify from "../../../../utils/toastify"

const metadata = {
    title: "Setup - Service Details",
    description: "Improve your chance by adding a description and related project photos",
    url: `${process.env.REACT_APP_CLIENT_URL}/auth/su/freelancer/service-details/`
}

const ServiceDetailsForm = () => {
    const { user } = useSelector((state) => state.auth)
    const [csrfToken, setCsrfToken] = useState("")
    const [serviceDetails, setServiceDetails] = useState(null)
    const [submitted, setSubmitted] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleSubmit = async (e) => {
        e.preventDefault()
        const submitBtn = e.target.querySelector("button[type=submit]")
        submitBtn.disabled = true

        if (serviceDetails) {
            setSubmitted(true)
            const emptyDetails = Object.keys(serviceDetails).filter(k =>
                serviceDetails[k].projectname === "" ||
                serviceDetails[k].description === "" ||
                serviceDetails[k].clientname === "" ||
                serviceDetails[k].projectsize === "" ||
                (serviceDetails[k].projectschedule.startDate === null || serviceDetails[k].projectschedule.endDate === null)
            )
            if (emptyDetails.length > 0) {
                setTimeout(() => {
                    submitBtn.disabled = false
                    setSubmitted(false)
                }, TOASTTIMER)
                return toastify("Fill required fields to continue.", TOASTTYPE_ERROR, submitBtn)
            }
            setLoading(true)
            await dispatch(freelancerServiceDetailsSetAsync({ token: csrfToken, serviceDetails }))
                .unwrap()
                .then(() => navigate(dashboardPath))
                .catch(error => {
                    const data = JSON.parse(error?.message)
                    return setErrorMsg(data.message)
                })
                .finally(() => {
                    setLoading(false)
                    submitBtn.disabled = false
                    setSubmitted(false)
                })
        }
        else navigate(dashboardPath)
    }
    return (
        <div>
            <Meta data={metadata} />
            <BrandBar />
            <div className="bg-white mb-12 py-10 md:py-14 px-3 sm:px-8 md:px-16 lg:px-32 xl:px-52">
                <form className="w-full max-w-[1080px] mx-auto px-4 smaller:px-12 xl:px-20 py-8 rounded-md shadow-formLight" onSubmit={handleSubmit}>
                    <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                    <div className="space-y-3 pb-6 border-b border-[#EFEEEE99]">
                        <h1 className="text-versich-dark-blue text-xl sm:text-2xl font-medium flex justify-center">Complete your listing process</h1>
                        <p className="text-versich-label text-xs sm:text-sm font-medium">Add details and descriptions to your selected listings</p>
                    </div>
                    <ServiceDetailsTile data={user.primaryService} submitted={submitted} setDetail={setServiceDetails} />
                    {user.otherServices && user.otherServices.map(service => (
                        <ServiceDetailsTile data={service} submitted={submitted} setDetail={setServiceDetails} key={service.uid} />
                    ))}
                    {errorMsg && <div className="text-sm text-red-500 text-left !mt-10">{errorMsg}</div>}
                    <div className="text-sm font-semibold w-full flex justify-end gap-5 py-6">
                        <button className="bg-white hover:opacity-80 text-versich-light-blue border border-versich-light-blue py-2 lg:py-3 px-6 lg:px-10 rounded-lg" type="button" onClick={() => navigate(dashboardPath)}>Back</button>
                        <button className="bg-versich-blue hover:bg-versich-blue-hover disabled:bg-versich-blue-hover text-white disabled:opacity-70 py-2 lg:py-3 px-6 lg:px-10 rounded-lg disabled:cursor-not-allowed" type="submit">Next</button>
                    </div>
                </form>
            </div>
            {loading && <FormLoader />}
        </div>
    )
}
export default ServiceDetailsForm
