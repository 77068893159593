import { useState, useEffect } from "react"

const TextAreaLimitFieldInput = ({ name, label, placeholder, required, onChange, defaultValue, totalChars = 1000, error }) => {
    const [value, setValue] = useState(defaultValue || "")
    const [charsLimitReached, setCharsLimitReached] = useState(false)

    // Track value change
    useEffect(() => {
        setValue(defaultValue)
    }, [defaultValue])

    const handleTextboxInput = (e) => {
        const newChars = e.target.value.length
        if (newChars > totalChars) {
            let allowedChars = e.target.value.slice(0, totalChars)
            e.target.value = allowedChars
            setValue(allowedChars)
            setCharsLimitReached(true)
        }
        else {
            setValue(e.target.value)
            setCharsLimitReached(false)
        }
        onChange(e)
    }
    return (
        <div className="space-y-2">
            {label &&
                <div className="flex flex-wrap gap-2 items-center justify-between">
                    <label htmlFor={name} className="text-versich-dark-blue text-base font-medium">{label}{required && <span className="text-red-500 ml-1">*</span>}</label>
                </div>
            }
            <textarea
                id={name}
                className={"text-sm font-medium w-full min-h-60 max-h-[600px] px-3 py-2 border focus-visible:border-versich-blue shadow-input focus:shadow-inputFocus outline-none rounded-lg "
                    + (error || charsLimitReached ? "border-red-500" : "border-gray-300")
                }
                name={name}
                value={defaultValue}
                placeholder={placeholder}
                onChange={handleTextboxInput}
            />
            <div className="flex justify-end">
                <span className={"text-sm font-medium " + (charsLimitReached ? "text-red-500" : "text-versich-label")}>{value.length}/{totalChars} Characters</span>
            </div>
            {error &&
                <div className="text-sm text-red-500 text-left">
                    {required}
                </div>
            }
        </div>
    )
}
export default TextAreaLimitFieldInput
