import * as api from "../api"

import { getUser } from "./auth"
import apiErrorParser from "../utils/apiErrorParser"

export const forgotPasswordSendLink = (email, token) => async () => {
    try {
        const { data } = await api.forgotPasswordSendLink({ email, csrfmiddlewaretoken: token })
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
}

export const verifyResetPasswordLink = (token, csrfToken) => async () => {
    try {
        const { data } = await api.verifyResetPasswordLink({ token, csrfmiddlewaretoken: csrfToken })
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
}

export const resetPassword = (token, password1, password2, csrfToken) => async () => {
    try {
        const { data } = await api.resetPassword({ token, password1, password2, csrfmiddlewaretoken: csrfToken })
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
}

export const changePassword = (password, password1, password2, csrfToken) => async (dispatch) => {
    try {
        const { data } = await api.changePassword({ password, password1, password2, csrfmiddlewaretoken: csrfToken })
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        await dispatch(getUser())
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
}