import { createAsyncThunk } from "@reduxjs/toolkit"

import * as api from "../../api"
import apiErrorParser from "../../utils/apiErrorParser"

export const getPublicBusinessProfileAsync = createAsyncThunk("account/getPublicBusinessProfile", async (companyid) => {
    try {
        const { data } = await api.getPublicBusinessProfile(companyid)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

