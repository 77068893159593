import { useForm, FormProvider } from "react-hook-form"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

import reviewIcon from "../../../../assets/svgs/review.svg"
import Meta from "../../../../components/Meta"
import BrandBar from "../../../../components/Navbar/BrandBar"
import FormLoader from "../../../../components/loader/FormLoader"
import CSRFTokenField from "../../../../components/Inputs/CSRFTokenField"
import InputText from "../../../../components/Inputs/InputTextField"
import TextAreaField from "../../../../components/Inputs/TextAreaField"
import RatingSelectionField, { ratingOptions } from "../../../../components/Inputs/RatingSelectionField"
import toastify from "../../../../utils/toastify"
import BusinessSetupTracker from "./BusinessSetupTracker"
import { TOASTTYPE_ERROR, businessPaths, businessPricingPath } from "../../../../assets/constants"
import { businessReviewAndReferencesSetAsync } from "../../../../slices/business/reviewAndReferences"

const metadata = {
    title: "Setup - Review and Reference",
    description: "Fill information about your business to list your business",
    url: `${process.env.REACT_APP_CLIENT_URL}/auth/su/business/review-and-references/`
}

const BusinessReviewForm = () => {
    const methods = useForm()

    const [csrfToken, setCsrfToken] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [review, setReview] = useState("")
    const [ratings, setRatings] = useState(0)
    const [ratingsError, setRatingsError] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const customHandleSubmit = (e) => {
        e.preventDefault()
        setRatingsError(ratingOptions.includes(ratings) === false)
        methods.handleSubmit(onSubmit)()
    }
    const onSubmit = async (data) => {
        try {
            const isValid = await methods.trigger()
            if (!isValid) {
                toastify("Form validation failed", TOASTTYPE_ERROR)
                return methods.reset()
            }
            if (ratingsError) {
                return methods.reset()
            }
            const formData = {
                firstname: data.firstName,
                lastname: data.lastName,
                companyname: data.companyName,
                email: data.email,
                review: data.review,
                ratings: ratings,
                token: csrfToken,
            }
            setLoading(true)
            await dispatch(businessReviewAndReferencesSetAsync(formData))
                .unwrap()
                .then(() => {
                    navigate(businessPricingPath)
                    methods.reset()
                })
                .catch(error => {
                    const data = JSON.parse(error?.message)
                    return setErrorMsg(data.message)
                })
                .finally(() => setLoading(false))
        } catch (error) {
            console.error("Form submission error:", error)
        }
    }
    return (
        <FormProvider {...methods}>
            <div className="bg-[#FBFBFF] min-h-svh">
                <Meta data={metadata} />
                <BrandBar />
                <div className="max_screen_w mb-12 py-8 md:py-10 px-4 md:px-8 lg:px-16">
                    <div className="space-y-3 pb-8 md:pb-10">
                        <h1 className="text-versich-dark-blue text-xl sm:text-2xl text-left font-medium">Business Listings</h1>
                        <p className="text-versich-dark-blue/80 text-xs sm:text-sm text-left font-medium">Complete details below to list your business</p>
                    </div>
                    <div className="flex max-md:flex-col-reverse gap-5">
                        <div className="bg-white w-full mx-auto px-4 smaller:px-8 xl:px-12 py-8 rounded-[11px] shadow-card2">
                            <div className="flex gap-3 pb-4 border-b border-[#EFEEEE99]">
                                <img src={reviewIcon} alt="" />
                                <div className="font-medium text-left space-y-[7px]">
                                    <h1 className="text-versich-dark-blue text-lg sm:text-xl">Review and References</h1>
                                    <p className="text-versich-dark-blue/80 text-xs sm:text-sm">Enter required details below</p>
                                </div>
                            </div>
                            <form onSubmit={customHandleSubmit}>
                                <div className="space-y-5 py-7">
                                    <div className="grid grid-cols-1 gap-x-3 gap-5 lg:grid-cols-2">
                                        <InputText name="firstName" inputType="text" placeholder="e.g. Steve" label="First Name" onChange={(e) => setFirstName(e.target.value)} defaultValue={firstName} rules={{ required: "First name is required" }} />
                                        <InputText name="lastName" inputType="text" placeholder="e.g. Rogers" label="Last Name" onChange={(e) => setLastName(e.target.value)} defaultValue={lastName} rules={{ required: "Last name is required" }} />
                                    </div>
                                    <div className="grid grid-cols-1 gap-x-3 gap-5 lg:grid-cols-2">
                                        <InputText name="companyName" inputType="text" placeholder="e.g. Business Solution" label="Company Name" onChange={(e) => setCompanyName(e.target.value)} defaultValue={companyName} rules={{ required: "Company name is required" }} />
                                        <InputText name="email" inputType="email" placeholder="e.g. ronald@a2zsolutions.com" label="Email Address" onChange={(e) => setEmail(e.target.value)} defaultValue={email} rules={{ required: "Email address is required" }} />
                                    </div>
                                    <TextAreaField
                                        name="review"
                                        label="Review"
                                        placeholder="e.g. Technology consulting with Excellence, Marketing business with digital accuracy"
                                        onChange={(e) => setReview(e.target.value)}
                                        defaultValue={review}
                                        rules={{ required: "Field is required" }}
                                    />
                                    <RatingSelectionField
                                        name="ratings"
                                        label="Ratings"
                                        ratings={ratings}
                                        setRatings={setRatings}
                                        error={ratingsError}
                                        setError={setRatingsError}
                                    />
                                    <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                                </div>
                                {errorMsg && <div className="text-sm text-red-500 text-left mt-4">{errorMsg}</div>}
                                <div className="text-sm font-semibold w-full flex justify-end gap-5 py-6">
                                    <button className="bg-columbia-blue hover:opacity-80 text-versich-light-blue border border-columbia-blue py-2 lg:py-3 px-6 lg:px-10 rounded-lg" type="button" onClick={() => navigate(businessPaths.competitors)}>Back</button>
                                    <button className="bg-white hover:opacity-80 text-versich-light-blue border border-versich-light-blue py-2 lg:py-3 px-6 lg:px-10 rounded-lg" type="button" onClick={() => navigate(businessPricingPath)}>Skip</button>
                                    <button className="bg-versich-blue hover:bg-versich-blue-hover text-white py-2 lg:py-3 px-6 lg:px-10 rounded-lg" type="submit">Submit</button>
                                </div>
                            </form>
                        </div>
                        <div>
                            <BusinessSetupTracker completed={[
                                "Business Details", "Personal Contact", "About Business",
                                "Business Location", "Social Profiles", "Competitors", "Review and References"
                            ]} />
                        </div>
                    </div>
                </div>
                {loading && <FormLoader />}
            </div>
        </FormProvider>
    )
}
export default BusinessReviewForm
