import { useSelector, useDispatch } from "react-redux"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

import competitorsIcon from "../../../../assets/svgs/competitor.svg"
import Meta from "../../../../components/Meta"
import BrandBar from "../../../../components/Navbar/BrandBar"
import FormLoader from "../../../../components/loader/FormLoader"
import QuickNote from "../../../../components/QuickNote"
import AddCompetitor from "../../../../components/Dashboard/accountSettings/business/competitors/AddCompetitor"
import CSRFTokenField from "../../../../components/Inputs/CSRFTokenField"
import toastify from "../../../../utils/toastify"
import BusinessSetupTracker from "./BusinessSetupTracker"
import { TOASTTIMER, TOASTTYPE_ERROR, businessPaths } from "../../../../assets/constants"
import { businessCompetitorsSetAsync } from "../../../../slices/business/competitors"

const metadata = {
    title: "Setup - Competitors",
    description: "Fill information about your known competitors",
    url: `${process.env.REACT_APP_CLIENT_URL}/auth/su/business/competitors/`
}

const BusinessCompetitorsForm = () => {
    const { user } = useSelector((state) => state.auth)

    const [csrfToken, setCsrfToken] = useState("")
    const [competitors, setCompetitors] = useState(null)
    const [submitted, setSubmitted] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleSubmit = async (e) => {
        e.preventDefault()
        const submitBtn = e.target.querySelector("button[type=submit]")
        submitBtn.disabled = true

        setSubmitted(true)
        const competitorKeys = Object.keys(competitors)
        const emptyDetails = competitorKeys.filter(k =>
            competitors[k].companyName === "" ||
            competitors[k].website === "" ||
            competitors[k].uniqueServices === "" ||
            competitors[k].whyBetter === ""
        )
        if (emptyDetails.length > 0) {
            setTimeout(() => {
                submitBtn.disabled = false
                setSubmitted(false)
            }, TOASTTIMER)
            return toastify("Fill required fields to continue.", TOASTTYPE_ERROR, submitBtn)
        }
        setLoading(true)
        const formData = {
            competitors: competitorKeys.map(k => {
                return {
                    company_name: competitors[k].companyName,
                    website: competitors[k].website,
                    unique_services: competitors[k].uniqueServices,
                    why_better: competitors[k].whyBetter
                }
            }),
            token: csrfToken
        }
        dispatch(businessCompetitorsSetAsync(formData))
            .unwrap()
            .then(() => navigate(businessPaths.reviewandreferences))
            .catch(error => {
                const data = JSON.parse(error?.message)
                return setErrorMsg(data.message)
            })
            .finally(() => {
                setLoading(false)
                submitBtn.disabled = false
                setSubmitted(false)
            })
    }
    return (
        <div className="bg-[#FBFBFF] min-h-svh">
            <Meta data={metadata} />
            <BrandBar />
            <div className="max_screen_w mb-12 py-8 md:py-10 px-4 md:px-8 lg:px-16">
                <div className="space-y-3 pb-8 md:pb-10">
                    <h1 className="text-versich-dark-blue text-xl sm:text-2xl text-left font-medium">Business Listings</h1>
                    <p className="text-versich-dark-blue/80 text-xs sm:text-sm text-left font-medium">Complete details below to list your business</p>
                </div>
                <div className="flex max-md:flex-col-reverse gap-5">
                    <div className="bg-white w-full mx-auto px-4 smaller:px-8 xl:px-12 py-8 rounded-[11px] shadow-card2">
                        <div className="flex gap-3 pb-4 border-b border-[#EFEEEE99]">
                            <img src={competitorsIcon} alt="" />
                            <div className="font-medium text-left space-y-[7px]">
                                <h1 className="text-versich-dark-blue text-lg sm:text-xl">Competitor</h1>
                                <p className="text-versich-dark-blue/80 text-xs sm:text-sm">Do you know your exisiting competitors in this field?</p>
                            </div>
                        </div>
                        <QuickNote className="max-smaller:flex-wrap md:max-lg:flex-wrap mt-4 mb-1" description="The competitors analysis would be used to give your business an edge over your competitors and marketed on our online networks/platform for added advantage over competitors" />
                        <form onSubmit={handleSubmit}>
                            <AddCompetitor maximum={4} minimum={1} submitted={submitted} competitors={user?.business?.competitors} setCompetitors={setCompetitors} />
                            <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                            {errorMsg && <div className="text-sm text-red-500 text-left mt-4">{errorMsg}</div>}
                            <div className="text-sm font-semibold w-full flex justify-end gap-5 py-6">
                                <button className="bg-white hover:opacity-80 text-versich-light-blue border border-versich-light-blue py-2 lg:py-3 px-6 lg:px-10 rounded-lg" type="button" onClick={() => navigate(businessPaths.socialprofiles)}>Back</button>
                                <button className="bg-versich-blue hover:bg-versich-blue-hover text-white py-2 lg:py-3 px-6 lg:px-10 rounded-lg" type="submit">Continue</button>
                            </div>
                        </form>
                    </div>
                    <div>
                        <BusinessSetupTracker completed={[
                            "Business Details", "Personal Contact", "About Business",
                            "Business Location", "Social Profiles", "Competitors"
                        ]} />
                    </div>
                </div>
            </div>
            {loading && <FormLoader />}
        </div>
    )
}
export default BusinessCompetitorsForm
