import { useParams, useNavigate, Link } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useState, useEffect } from "react"
import { BeatLoader } from "react-spinners"

import ErrorPage from "../../components/ErrorPage/ErrorPage"
import Navbar from "../../components/Navbar/Navbar"
import Meta from "../../components/Meta"
import chevronRightIcon from "../../assets/svgs/chevron-right.svg"
import noPictureIcon from "../../assets/images/no-picture-taking.png"
import curvyArrowIcon from "../../assets/svgs/full-curvyarrow-right.svg"
import emptyBoxIcon from "../../assets/images/empty-box.png"
import ServicesList from "../../assets/ServicesList"
import { freelancerPublicServicesPath } from "../../assets/constants"
import { getPublicFreelancerServiceProjectsAsync } from "../../slices/freelancer/public"

const ServiceCard = ({ data, servicePath }) => {
    const serviceProjectDetailsPath = `${servicePath}:sid/projects/:pid/details`.replace(":sid", data.service.uid).replace(":pid", data.id)
    return (
        <Link to={serviceProjectDetailsPath} className="w-full max-md:max-w-[460px] max-md:mx-auto p-3 shadow-card5 rounded-lg">
            <div className="bg-[#F6F9FF] flex items-end justify-center aspect-[4/3] rounded-lg overflow-hidden">
                <div className="max-w-[420px] w-11/12 aspect-[4/3] rounded-[19px] -mb-4 overflow-hidden">
                    {data.coverImage
                        ? <img className="w-full border border-brandeis-blue/[0.15] rounded-[19px]" src={data.coverImage} />
                        : <div className="inline-flex flex-col items-center justify-center w-full h-full border border-brandeis-blue/[0.15] rounded-[19px]" title="No images found">
                            <img className="w-32" src={noPictureIcon} />
                            <span className="text-versich-label text-sm">No images found</span>
                        </div>
                    }
                </div>
            </div>
            <div className="mt-4 px-2">
                <div>
                    <div className="flex justify-start">
                        <div className="bg-brandeis-blue/[0.08] text-versich-light-blue text-sm py-1.5 px-2.5 rounded-full">{data.service.title}</div>
                    </div>
                    <div className="text-versich-dark-blue mt-2.5">
                        <div className="text-xl font-semibold">{data.projectName}</div>
                        <div className="text-sm mt-0.5">{data.shortDesc}</div>
                    </div>
                </div>
                <div className="text-versich-label text-sm inline-flex items-center gap-[5px] mt-[14px]">
                    View details <img className="w-5 h-5" src={curvyArrowIcon} alt="" />
                </div>
            </div>
        </Link>
    )
}

const FreelancerServiceProjects = () => {
    const { id, sid } = useParams()
    const [user, setUser] = useState(undefined)
    const [metadata, setMetadata] = useState()

    // Set Service title
    const [service, setService] = useState(null)
    useEffect(() => {
        const services = ServicesList.filter(s => s.uid === sid)
        if (services.length > 0) {
            setService(services[0])
        }
    }, [id])

    const dispatch = useDispatch()
    const fetchFreelancerServiceProjects = async (id) => {
        dispatch(getPublicFreelancerServiceProjectsAsync({ freelancerId: id, serviceId: sid }))
            .unwrap()
            .then((data) => {
                setUser(data.payload)
                const fullName = data.payload.about.firstName + " " + data.payload.about.lastName
                setMetadata({
                    title: `${fullName} - ${service.name} Service Projects`,
                    description: `Public freelancer ${service.name.toLowerCase()} service projects of ${fullName}`,
                    url: `${process.env.REACT_APP_CLIENT_URL}/freelancer/${id}/services/${sid}/projects/`
                })
            })
            .catch(() => setUser(null))
    }

    useEffect(() => {
        if (!service) {
            return
        }
        setMetadata({
            title: `${service.name} Service Projects`,
            description: `Public freelancer ${service.name.toLowerCase()} service projects`,
            url: `${process.env.REACT_APP_CLIENT_URL}/freelancer/${id}/services/${sid}/projects/`
        })
        fetchFreelancerServiceProjects(id)
    }, [service, id])

    const navigate = useNavigate()
    const freelancerServicesPath = freelancerPublicServicesPath.replace(":uid", id)
    return service && (
        user !== null
            ? <div className="bg-[#F5F7FF] flex flex-col min-h-svh h-full">
                <Navbar />
                {user === undefined
                    ? <div className="w-full h-80 flex justify-center items-center">
                        <BeatLoader loading={true} color="#1D88ED" size={10} />
                    </div>
                    : <>
                        {metadata && <Meta data={metadata} />}
                        <div className="flex-grow flex flex-col py-8 px-8 md:px-16 lg:px-28">
                            <div className="max_screen_w w-full flex-grow flex flex-col gap-y-2.5">
                                <div className="space-y-1.5">
                                    <div className="flex justify-start pt-1">
                                        <button className="inline-flex items-center gap-0.5" onClick={() => navigate(freelancerServicesPath)}>
                                            <img src={chevronRightIcon} alt="" />
                                            <span className="text-versich-light-blue text-sm font-semibold">Back</span>
                                        </button>
                                    </div>
                                    <div className="text-versich-dark-blue text-start space-y-[2px]">
                                        <div className="text-lg lg:text-xl font-semibold">{service.name}</div>
                                        <div className="text-sm">See all projects handled under {service.name.toLowerCase()}</div>
                                    </div>
                                </div>
                                <div className="bg-white text-start flex-grow flex py-6 px-4 sm401:px-6 shadow-card4 rounded-lg">
                                    {user.projects && user.projects.length > 0
                                        ? <div className="w-full">
                                            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5">
                                                {user.projects.map((project, index) => <ServiceCard data={project} servicePath={freelancerServicesPath} key={index} />)}
                                            </div>
                                        </div>
                                        : <div className="w-full flex flex-col items-center justify-center p-4">
                                            <img className="w-[180px] sm:w-[220px] opacity-60" src={emptyBoxIcon} alt="no projects" />
                                            <span className="text-versich-label text-sm">No Projects</span>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
            : <ErrorPage status={404} />
    )
}
export default FreelancerServiceProjects