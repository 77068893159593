import { useState, useEffect } from "react"

import MiddleTextSeparator from "../../../../../pages/auth/socialAccounts/MiddleTextSeparator"
import TextFieldInput from "../../../../Services/TextFieldInput"
import TextAreaFieldInput from "../../../../Services/TextAreaFieldInput"
import AddPlus from "../../../../svgs/AddPlus"

const CompetitorFields = ({ data, submitted, position, minimum, fieldStatus, removeCompetitor, setCompetitors }) => {
    const [companyName, setCompanyName] = useState("")
    const [companyNameError, setCompanyNameError] = useState(false)
    const [website, setWebsite] = useState("")
    const [websiteError, setWebsiteError] = useState(false)
    const [uniqueServices, setUniqueServices] = useState("")
    const [uniqueServicesError, setUniqueServicesError] = useState(false)
    const [whyBetter, setWhyBetter] = useState("")
    const [whyBetterError, setWhyBetterError] = useState("")

    // Pre-fill fields
    useEffect(() => {
        if (data && data.length >= position) {
            const d = data[position - 1]
            setCompanyName(d.companyName)
            setWebsite(d.website)
            setUniqueServices(d.uniqueServices)
            setWhyBetter(d.whyBetter)
        }
    }, [data])

    // Track changes in competitors
    const updateCompetitors = (add) => {
        setCompetitors(preCompetitors => {
            let details
            if (add) {
                details = {
                    ...preCompetitors, [position]: {
                        companyName,
                        website,
                        uniqueServices,
                        whyBetter,
                    }
                }
            }
            else {
                let d = Object.entries({ ...preCompetitors }).filter(([key, value]) => parseInt(key) <= fieldStatus.count)
                if (d.length > 0) {
                    details = Object.fromEntries(d)
                }
            }
            return details
        })
    }
    useEffect(() => {
        updateCompetitors(true)
    }, [companyName, website, uniqueServices, whyBetter])

    // Add/Remove competitor
    useEffect(() => {
        updateCompetitors(fieldStatus.added)
    }, [fieldStatus])

    useEffect(() => {
        setCompanyNameError(submitted && companyName === "")
        setWebsiteError(submitted && website === "")
        setUniqueServicesError(submitted && uniqueServices === "")
        setWhyBetterError(submitted && whyBetter === "")
    }, [submitted])
    return (
        <div className="space-y-5 competitor">
            <MiddleTextSeparator word={
                <p className="w-[128px] mb-2.5">Competitor #{position}</p>
            } />
            <div className="grid grid-cols-1 gap-x-3 gap-5 lg:grid-cols-2">
                <TextFieldInput
                    name="companyName"
                    inputType="text"
                    placeholder="e.g. Business Solution"
                    label={<span className="text-sm">Competitor's Company Name</span>}
                    onChange={(e) => setCompanyName(e.target.value)}
                    defaultValue={companyName}
                    required="Company name is required"
                    error={companyNameError}
                />
                <TextFieldInput
                    name="website"
                    inputType="url"
                    placeholder="e.g. https://a2zsolutions.com"
                    label={<span className="text-sm">Competitor's Website</span>}
                    onChange={(e) => setWebsite(e.target.value)}
                    defaultValue={website}
                    required="Website is required"
                    error={websiteError}
                />
            </div>
            <TextAreaFieldInput
                name="uniqueServices"
                label={<span className="text-sm">Service you render they don't</span>}
                placeholder="Describe the unique services your business offers that competitors don’t"
                onChange={(e) => setUniqueServices(e.target.value)}
                defaultValue={uniqueServices}
                required="Field is required"
                error={uniqueServicesError}
            />
            <TextAreaFieldInput
                name="whyBetter"
                label={<span className="text-sm">Why you're better than them</span>}
                placeholder="e.g. Technology consulting with Excellence, Marketing business with digital accuracy"
                onChange={(e) => setWhyBetter(e.target.value)}
                defaultValue={whyBetter}
                required="Field is required"
                error={whyBetterError}
            />
            <div className={"hover:opacity-80 items-center justify-end gap-x-1.5 group cursor-pointer remove_button" + (
                position > minimum ? " flex" : " hidden"
            )} onClick={removeCompetitor}>
                <AddPlus className="[&>*]:stroke-red-500 rotate-45 group-hover:[&>*]:opacity-80 smaller:max-lg:w-5 smaller:max-lg:h-5 w-6 h-6 transition-transform" />
                <span className="text-red-500 text-sm font-medium">Remove Competitor</span>
            </div>
        </div>
    )
}
export default CompetitorFields