import { useState, useEffect } from "react"

import DashboardLayout from "../../../components/Dashboard/DashboardLayout"
import Profile from "../../../components/Dashboard/accountSettings/Profile"
import { SS_SAME_ACCOUNT_WARN, SS_ACCOUNT_CHANGE_ERROR, TOASTTIMER } from "../../../assets/constants"
import { BiErrorCircle } from "react-icons/bi"

const AccountSettingsLayout = ({ children, metadata, navlinks }) => {
    const [errorMsg, setErrorMsg] = useState(null)

    const handleError = (message, status, sessionKey) => {
        setErrorMsg({ message, status })
        setTimeout(() => {
            setErrorMsg(null)
        }, TOASTTIMER)
        window.sessionStorage.removeItem(sessionKey)
    }

    // OAuth2 account change error
    useEffect(() => {
        const changeError = window.sessionStorage.getItem(SS_ACCOUNT_CHANGE_ERROR)
        if (changeError) {
            handleError(changeError, "error", SS_ACCOUNT_CHANGE_ERROR)
        }
        const changeInfo = window.sessionStorage.getItem(SS_SAME_ACCOUNT_WARN)
        if (changeInfo) {
            handleError(changeInfo, "info", SS_SAME_ACCOUNT_WARN)
        }
    }, [])
    return (
        <DashboardLayout metadata={metadata} navlinks={navlinks} activePage="Account Settings">
            <div className="flex flex-col gap-y-4 h-full">
                <div className="text-versich-dark-blue text-start space-y-[2px]">
                    <div className="text-lg lg:text-xl font-semibold">Account Settings</div>
                    <div className="text-sm">Manage your profile here</div>
                </div>
                <div className="bg-white text-start flex-grow pt-6 pb-12 px-4 sm401:px-6 shadow-card4 rounded-lg">
                    {errorMsg &&
                        <div className="w-full pb-6 sm:pb-10 flex justify-center">
                            <p className={"font-semibold flex smaller:items-center justify-center gap-2 w-full px-6 py-1.5 sm401:px-12 sm401:py-2 border rounded-md "
                                + (errorMsg.status === "info" ? "bg-indigo-50 text-indigo-800 border-indigo-100" : "bg-orange-200 text-orange-900 border-orange-300")
                            }>
                                <BiErrorCircle className="w-6 h-6" />
                                <span>{errorMsg.message}</span>
                            </p>
                        </div>
                    }
                    <div className="grid md:grid-cols-5 xl:grid-cols-3 gap-x-4 gap-y-3">
                        <Profile />
                        {children}
                    </div>
                </div>
            </div>
        </DashboardLayout>
    )
}
export default AccountSettingsLayout