import { useSelector, useDispatch } from "react-redux"
import { useLocation, Navigate } from "react-router-dom"
import { useState, useEffect } from "react"
import { BeatLoader } from "react-spinners"

import AccountSettings from "./AccountSettings"
import { accountSettingsPath, SS_SAME_ACCOUNT_WARN, SS_ACCOUNT_CHANGE_ERROR } from "../../../assets/constants"
import { changeGoogleUser } from "../../../actions/socialAuth"
import toastify from "../../../utils/toastify"

const metadata = {
    title: "Google Account Change Verification",
    url: `${process.env.REACT_APP_CLIENT_URL}/dashboard/settings/social-accounts/verify/google-oauth2/change/`
}

const GoogleOAuthAccountChangeVerification = () => {
    const { user } = useSelector(state => state.auth)
    const [status, setStatus] = useState("verifying")
    const location = useLocation()

    const dispatch = useDispatch()
    useEffect(() => {
        let params = location.search
        if (user?.email) {
            params += `&accountEmail=${user.email}`
        }
        dispatch(changeGoogleUser(params))
            .then((data) => {
                if (data.status === "info") {
                    window.sessionStorage.setItem(SS_SAME_ACCOUNT_WARN, data.message)
                }
                else toastify(data.message, data.status, null)
                setStatus(null)
            })
            .catch(error => {
                const data = JSON.parse(error?.message)
                window.sessionStorage.setItem(SS_ACCOUNT_CHANGE_ERROR, data?.message)
                setStatus(null)
            })
        // eslint-disable-next-line
    }, [])
    return (
        status === null
            ? <Navigate to={accountSettingsPath} />
            : <>
                <AccountSettings metadata={metadata} />
                <div className="fixed inset-0 flex items-center justify-center bg-[#1717253D] backdrop-blur-sm z-20 ">
                    <div className="w-full h-screen absolute flex flex-col justify-center items-center gap-6">
                        <div className="max-w-24 sm:max-w-32 md:max-w-[180px]">
                            <img className="" src="https://cdn-icons-png.flaticon.com/512/300/300221.png" alt={status} />
                        </div>
                        <BeatLoader loading={true} color="#1D88ED" height={3} width="40%" />
                    </div>
                </div>
            </>
    )
}
export default GoogleOAuthAccountChangeVerification