import { useSelector } from "react-redux"
import { BsCheck2Circle } from "react-icons/bs"

import messageIcon from "../../../assets/icons/dashboard/message.svg"
import callingIcon from "../../../assets/icons/dashboard/calling.svg"
import discoveryIcon from "../../../assets/icons/dashboard/discovery.svg"
import linkedinIcon from "../../../assets/social-icons/linkedin_color.svg"
import { LinkOrDiv } from "../business/SocialMedia"

const About = () => {
    const { user } = useSelector(state => state.auth)
    return (
        <div className="bg-white text-start p-4 sm:p-5 shadow-card4 rounded-lg">
            <div className="text-versich-dark-blue font-bold pb-4 border-b border-[#EDF0F5]">About</div>
            <div className="grid grid-cols-1 gap-x-3 gap-y-6 mt-5 p-1">
                <div className="flex gap-[26px]">
                    <div>
                        <div className="bg-brandeis-blue/[0.08] inline-flex items-center justify-center min-w-[43px] min-h-[43px] rounded-[10px]">
                            <img className="w-6 h-6" src={messageIcon} alt="" />
                        </div>
                    </div>
                    <div className="text-versich-dark-blue text-sm" style={{ overflowWrap: "anywhere" }}>
                        <p className="font-semibold mb-[3px]">Email</p>
                        <div className="inline-flex flex-wrap items-center gap-1.5">{user.email} {user.verified && <BsCheck2Circle className="text-green-700" title="Email verified" />}</div>
                    </div>
                </div>
                <div className="flex gap-[26px]">
                    <div>
                        <div className="bg-brandeis-blue/[0.08] inline-flex items-center justify-center min-w-[43px] min-h-[43px] rounded-[10px]">
                            <img className="w-6 h-6" src={callingIcon} alt="" />
                        </div>
                    </div>
                    <div className="text-versich-dark-blue text-sm">
                        <p className="font-semibold mb-[3px]">Phone Number</p>
                        <div>{user.about.phone ? user.about.phone.code + " " + user.about.phone.number : "-"}</div>
                    </div>
                </div>
                <div className="flex gap-[26px]">
                    <div>
                        <div className="bg-brandeis-blue/[0.08] inline-flex items-center justify-center min-w-[43px] min-h-[43px] rounded-[10px]">
                            <img className="w-6 h-6" src={discoveryIcon} alt="" />
                        </div>
                    </div>
                    <div className="text-versich-dark-blue text-sm">
                        <p className="font-semibold mb-[3px]">Country</p>
                        <div>{user.about.country ? user.about.country.name : "-"}</div>
                    </div>
                </div>
                <LinkOrDiv href={user.about.linkedinUrl} className={"flex gap-[26px] " + (user.about.linkedinUrl ? "group" : "opacity-60 cursor-not-allowed")}>
                    <div>
                        <div className="bg-brandeis-blue/[0.08] inline-flex items-center justify-center min-w-[43px] min-h-[43px] rounded-[10px]">
                            <img className="w-6 h-6" src={linkedinIcon} alt="" />
                        </div>
                    </div>
                    <div className="text-versich-dark-blue text-sm w-[70%] overflow-hidden inline-flex flex-col">
                        <p className="font-semibold mb-[3px]">Linkedin</p>
                        <div className="group-hover:text-versich-light-blue truncate">{user.about.linkedinUrl || "-"}</div>
                    </div>
                </LinkOrDiv>
            </div>
        </div>
    )
}
export default About