import { createAsyncThunk } from "@reduxjs/toolkit"

import * as api from "../../api"
import { getUser } from "../../actions/auth"
import apiErrorParser from "../../utils/apiErrorParser"

export const businessCompetitorsSetAsync = createAsyncThunk("account/businessCompetitorsSet",
    async ({ competitors, token }, { dispatch }) => {
        try {
            const { data } = await api.businessCompetitorsSet({ competitors, csrfmiddlewaretoken: token })
            if (data.status !== "success") {
                throw Error(JSON.stringify(data))
            }
            await dispatch(getUser())
            return data
        }
        catch (error) {
            throw apiErrorParser(error)
        }
    })

export const businessCompetitorsUpdateAsync = createAsyncThunk("account/businessCompetitorsUpdate",
    async ({ companyname, website, uniqueservices, whybetter, token }, { dispatch }) => {
        try {
            const { data } = await api.businessCompetitorsUpdate({ companyname, website, uniqueservices, whybetter, csrfmiddlewaretoken: token })
            if (data.status !== "success") {
                throw Error(JSON.stringify(data))
            }
            await dispatch(getUser())
            return data
        }
        catch (error) {
            throw apiErrorParser(error)
        }
    })