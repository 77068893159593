import { createAsyncThunk } from "@reduxjs/toolkit"

import * as api from "../../api"
import { getUser } from "../../actions/auth"
import apiErrorParser from "../../utils/apiErrorParser"

export const businessAboutAllServiceAsync = createAsyncThunk("service/account/businessAboutAllService", async (params) => {
    try {
        const { data } = await api.businessAboutGetAllService(params)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const businessAboutAllTotalEmployeeAsync = createAsyncThunk("service/account/businessAboutAllTotalEmployee", async (params) => {
    try {
        const { data } = await api.businessAboutGetAllTotalEmployee(params)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const businessAboutAllMinimumProjectSizeAsync = createAsyncThunk("service/account/businessAboutAllMinimumProjectSize", async (params) => {
    try {
        const { data } = await api.businessAboutGetAllMinimumProjectSize(params)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const businessAboutAllAverageHourlyRateAsync = createAsyncThunk("service/account/businessAboutAllAverageHourlyRate", async (params) => {
    try {
        const { data } = await api.businessAboutGetAllAverageHourlyRate(params)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const businessAboutSetAsync = createAsyncThunk("account/businessAboutSet",
    async ({ services, otherService, tagline, description, totalEmployees, minProjectSize, averageHourlyRate, token }, { dispatch }) => {
        try {
            const { data } = await api.businessAboutSet({
                serviceIds: services.map(item => item.uid),
                otherService,
                tagline,
                description,
                totalEmployeesId: totalEmployees,
                minimumProjectSizeId: minProjectSize,
                averageHourlyRateId: averageHourlyRate,
                csrfmiddlewaretoken: token
            })
            if (data.status !== "success") {
                throw Error(JSON.stringify(data))
            }
            await dispatch(getUser())
            return data
        }
        catch (error) {
            throw apiErrorParser(error)
        }
    })

export const businessAboutUpdateAsync = createAsyncThunk("account/businessAboutUpdate",
    async ({ services, otherService, tagline, description, totalEmployees, minProjectSize, averageHourlyRate, token }, { dispatch }) => {
        try {
            const { data } = await api.businessAboutUpdate({
                serviceIds: services.map(item => item.uid),
                otherService,
                tagline,
                description,
                totalEmployeesId: totalEmployees,
                minimumProjectSizeId: minProjectSize,
                averageHourlyRateId: averageHourlyRate,
                csrfmiddlewaretoken: token
            })
            if (data.status !== "success") {
                throw Error(JSON.stringify(data))
            }
            await dispatch(getUser())
            return data
        }
        catch (error) {
            throw apiErrorParser(error)
        }
    })