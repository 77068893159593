import { createAsyncThunk } from "@reduxjs/toolkit"

import * as api from "../../api"
import { getUser } from "../../actions/auth"
import apiErrorParser from "../../utils/apiErrorParser"

export const personalDetailsUpdateAsync = createAsyncThunk("account/personalDetailsUpdate",
    async ({ firstname, lastname, token }, { dispatch }) => {
        try {
            const { data } = await api.clientPersonalDetailsUpdate({ firstname, lastname, csrfmiddlewaretoken: token })
            if (data.status !== "success") {
                throw Error(JSON.stringify(data))
            }
            await dispatch(getUser())
            return data
        }
        catch (error) {
            throw apiErrorParser(error)
        }
    })


export const profileDetailsUpdateAsync = createAsyncThunk("account/profileDetailsUpdate",
    async ({ phonecode, phonenumber, countryiso3, linkedin_url, token }, { dispatch }) => {
        try {
            const { data } = await api.clientProfileDetailsUpdate({ phonecode, phonenumber, countryiso3, linkedin_url, csrfmiddlewaretoken: token })
            if (data.status !== "success") {
                throw Error(JSON.stringify(data))
            }
            await dispatch(getUser())
            return data
        }
        catch (error) {
            throw apiErrorParser(error)
        }
    })