import { Link } from "react-router-dom"
import { LuCopyCheck } from "react-icons/lu"
import { IoMdCloseCircle } from "react-icons/io"

const ProfileCopiedNotification = ({ profilePath, userName, hideNotification }) => {
    return (
        <div className="bg-blue-50/50 text-versich-blue/80 font-semibold text-start flex gap-4 justify-between w-full my-3.5 px-4 py-2 sm:px-6 sm:py-4 border border-blue-200 shadow-sticker rounded-lg">
            <div className="inline-flex flex-wrap items-center gap-2">
                <LuCopyCheck className="w-5 h-5" />
                <div>
                    Profile successfully copied to clipboard! View it here: <Link className="text-versich-blue/90 hover:text-versich-blue-hover/90 underline" to={profilePath} target="_blank">{userName}'s Profile</Link>.
                </div>
            </div>
            <button className="hover:text-red-500 transition-colors" onClick={hideNotification}>
                <IoMdCloseCircle className="w-5 h-5" />
            </button>
        </div>
    )
}
export default ProfileCopiedNotification