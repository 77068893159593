import locationIcon from "../../../assets/icons/dashboard/location.svg"
import callingIcon from "../../../assets/icons/dashboard/calling.svg"
import discoveryIcon from "../../../assets/icons/dashboard/discovery.svg"

const Location = ({ data, hideEmpty }) => {
    return (
        <div className="bg-white text-start p-4 sm:p-5 shadow-card4 rounded-lg">
            <div className="text-versich-dark-blue font-bold pb-4 border-b border-[#EDF0F5]">Location</div>
            <div className="mt-5 p-1 space-y-6">
                <div className="flex gap-[26px]">
                    <div>
                        <div className="bg-brandeis-blue/[0.08] inline-flex items-center justify-center min-w-[43px] min-h-[43px] rounded-[10px]">
                            <img className="w-6 h-6" src={locationIcon} alt="" />
                        </div>
                    </div>
                    <div className="text-versich-dark-blue text-sm">
                        <p className="font-semibold mb-[3px]">Address Line 1</p>
                        <div>{data.headquarters.addressLine1}</div>
                    </div>
                </div>
                {((hideEmpty && data.headquarters.addressLine2) || !hideEmpty) &&
                    <div className="flex gap-[26px]">
                        <div>
                            <div className="bg-brandeis-blue/[0.08] inline-flex items-center justify-center min-w-[43px] min-h-[43px] rounded-[10px]">
                                <img className="w-6 h-6" src={locationIcon} alt="" />
                            </div>
                        </div>
                        <div className="text-versich-dark-blue text-sm">
                            <p className="font-semibold mb-[3px]">Address Line 2</p>
                            <div>{data.headquarters.addressLine2 || "-"}</div>
                        </div>
                    </div>
                }
                <div className="flex gap-[26px]">
                    <div>
                        <div className="bg-brandeis-blue/[0.08] inline-flex items-center justify-center min-w-[43px] min-h-[43px] rounded-[10px]">
                            <img className="w-6 h-6" src={callingIcon} alt="" />
                        </div>
                    </div>
                    <div className="text-versich-dark-blue text-sm">
                        <p className="font-semibold mb-[3px]">Phone Number</p>
                        <div>{data.headquarters.phone.code} {data.headquarters.phone.number}</div>
                    </div>
                </div>
                <div className="grid lg900:max-xl1080:grid-cols-2 xl:grid-cols-2 gap-x-3 gap-y-6">
                    <div className="flex gap-[26px]">
                        <div>
                            <div className="bg-brandeis-blue/[0.08] inline-flex items-center justify-center min-w-[43px] min-h-[43px] rounded-[10px]">
                                <img className="w-6 h-6" src={locationIcon} alt="" />
                            </div>
                        </div>
                        <div className="text-versich-dark-blue text-sm">
                            <p className="font-semibold mb-[3px]">City</p>
                            <div>{data.headquarters.city}</div>
                        </div>
                    </div>
                    <div className="flex gap-[26px]">
                        <div>
                            <div className="bg-brandeis-blue/[0.08] inline-flex items-center justify-center min-w-[43px] min-h-[43px] rounded-[10px]">
                                <img className="w-6 h-6" src={discoveryIcon} alt="" />
                            </div>
                        </div>
                        <div className="text-versich-dark-blue text-sm">
                            <p className="font-semibold mb-[3px]">Country</p>
                            <div>{data.headquarters.country.name}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Location