import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { BeatLoader } from "react-spinners"

import checkIcon from "../../assets/svgs/check.svg"
import cancelIcon from "../../assets/svgs/cancel.svg"
import ErrorPage from "../../components/ErrorPage/ErrorPage"
import Meta from "../../components/Meta"
import BrandBar from "../../components/Navbar/BrandBar"
import CSRFTokenField from "../../components/Inputs/CSRFTokenField"
import ConfirmButton from "../../components/Buttons/ConfirmButton"
import { getBusinessPaidPlansAsync, createBusinessCheckoutSessionAsync } from "../../slices/payments/business"
import { dashboardPath } from "../../assets/constants"
import "./pricing.css"

const metadata = {
    title: "Upgrade plan",
    description: "Account plan selection",
    url: `${process.env.REACT_APP_CLIENT_URL}/dashboard/pricing/`
}

const BusinessPricing = () => {
    const [csrfToken, setCsrfToken] = useState("")
    const [plans, setPlans] = useState(undefined)
    const [period, setPeriod] = useState("")
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getBusinessPaidPlansAsync())
            .unwrap()
            .then(data => {
                setPlans(data.payload)
                setPeriod(data.payload.monthly.interval)
            })
            .catch(() => setPlans(null))
    }, [])

    const navigate = useNavigate()
    const submitForm = (button) => {
        button.disabled = true
        setLoading(true)
        dispatch(createBusinessCheckoutSessionAsync({ period, token: csrfToken }))
            .unwrap()
            .then(data => {
                window.location.href = data.url
            })
            .catch((error) => {
                button.disabled = false
                const data = JSON.parse(error?.message)
                setErrorMsg(data.message)
                setLoading(false)
            })
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        const submitBtn = e.target.querySelector("button[type=submit]")
        submitForm(submitBtn)
    }
    return (
        plans !== null
            ? <div className="bg-[#FCFDFF] min-h-svh">
                <Meta data={metadata} />
                <BrandBar />
                <div className="flex flex-col overflow-hidden relative">
                    <div className="bg-versich-light-blue absolute w-full h-[25%] lg800:h-[40%]" />
                    <div className="bg-versich-light-blue absolute top-[25%] lg800:top-[39%] w-full h-[25%] lg800:h-[40%] [clip-path:polygon(0_0,_100%_0,_100%_80px,_0_10px)]" />
                    <div className="max_screen_w relative">
                        <div className="text-white text-center px-12 max-[400px]:px-6 pt-10 sm:pt-16">
                            <h2 className="text-2xl sm:text-3xl leading-normal font-medium">Upgrade Your Plan</h2>
                            <p className="text-white/70 mt-2 font-normal">Select your preferred pricing plan</p>
                        </div>
                        <div className="w-full pb-10 sm:pb-16 flex justify-center">
                            {plans?.monthly?.interval && plans?.yearly?.interval
                                ? <form className="px-12 max-[400px]:px-6 mx-0" onSubmit={handleSubmit}>
                                    <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                                    <label className="bg-white font-semibold max-smaller:w-full inline-flex gap-[5px] my-8 py-1 px-[5px] border-[1.5px] border-[#FAFAFA] selection:bg-transparent relative shadow-card rounded-lg cursor-pointer">
                                        <input name="period" type="checkbox" className="hidden peer" onChange={() => setPeriod(p => p === plans.monthly.interval ? plans.yearly.interval : plans.monthly.interval)} />
                                        <span className="bg-versich-light-blue peer-checked:bg-white text-white peer-checked:text-versich-light-blue max-smaller:flex-1 smaller:w-[180px] px-4 py-3 border border-white hover:border-versich-light-blue rounded-lg transition-colors duration-300">Monthly</span>
                                        <span className="bg-white peer-checked:bg-versich-light-blue peer-checked:text-white max-smaller:flex-1 smaller:w-[180px] px-4 py-3 border border-white hover:border-versich-light-blue rounded-lg transition-colors duration-300">Yearly</span>
                                    </label>
                                    <div className="grid grid-cols-1 lg800:grid-cols-2 gap-4 sm:gap-x-5">
                                        <div id="free-plan" className="lg800:col-start-1 lg800:col-end-2 max-lg800:row-start-2 sm401:min-w-[300px] lg900:min-w-[360px] text-center card">
                                            <div className="bg-[#5DA6F4] absolute top-0 left-0 w-full h-4 [clip-path:polygon(0_0,_100%_0,_100%_60%,_0_100%)]" />
                                            <div className="card-header">
                                                <div className="plan-price">Free</div>
                                                <div className="plan-meta">
                                                    <div className="plan-title">Versi Starter</div>
                                                    <div className="plan-description">Quite limited but only for starters</div>
                                                </div>
                                            </div>
                                            <div className="benefits">
                                                <div className="benefit-point">
                                                    <img className="w-6" src={checkIcon} alt="" />
                                                    <div className="benefit-pointdetail">Access to Dashboard</div>
                                                </div>
                                                <div className="benefit-point">
                                                    <img className="w-6" src={cancelIcon} alt="" />
                                                    <div className="benefit-pointdetail">View Client Direct Messages</div>
                                                </div>
                                                <div className="benefit-point">
                                                    <img className="w-6" src={cancelIcon} alt="" />
                                                    <div className="benefit-pointdetail">Profile Listed as Top Freelancer</div>
                                                </div>
                                                <div className="benefit-point">
                                                    <img className="w-6" src={cancelIcon} alt="" />
                                                    <div className="benefit-pointdetail">View Client Details</div>
                                                </div>
                                                <div className="benefit-point">
                                                    <img className="w-6" src={cancelIcon} alt="" />
                                                    <div className="benefit-pointdetail">Live Support</div>
                                                </div>
                                            </div>
                                            <div className="py-2.5 px-3">
                                                <ConfirmButton
                                                    className="bg-versich-light-blue hover:bg-transparent text-white hover:text-versich-light-blue disabled:bg-versich-light-blue/90 disabled:!text-white font-medium w-full py-2 lg:py-2.5 border-2 border-versich-light-blue rounded-lg transition-colors disabled:cursor-not-allowed"
                                                    type="button"
                                                    handleClick={() => navigate(dashboardPath)}
                                                >
                                                    Subscribe
                                                </ConfirmButton>
                                            </div>
                                        </div>
                                        <div id="premium-plan" className="max-lg800:row-start-1 sm401:min-w-[300px] lg900:min-w-[360px] text-center card">
                                            <div className="bg-[#FDB640] absolute top-0 left-0 w-full h-4 [clip-path:polygon(0_0,_100%_0,_100%_60%,_0_100%)]" />
                                            <div className="card-header">
                                                <div className="plan-price">
                                                    {period === plans.monthly.interval && <>
                                                        ${plans.monthly.amount}
                                                    </>}
                                                    {period === plans.yearly.interval && <>
                                                        ${plans.yearly.amount - plans.yearly.discount}
                                                        <sub className="text-[15px]"> <span className="line-through decoration-2">${plans.yearly.amount}</span></sub>
                                                    </>}
                                                </div>
                                                <div className="plan-meta">
                                                    <div className="plan-title">Versi Premium</div>
                                                    <div className="plan-description">Access to all features</div>
                                                </div>
                                            </div>
                                            <div className="benefits">
                                                <div className="benefit-point">
                                                    <img className="w-6" src={checkIcon} alt="" />
                                                    <div className="benefit-pointdetail">View Client Direct Messages</div>
                                                </div>
                                                <div className="benefit-point">
                                                    <img className="w-6" src={checkIcon} alt="" />
                                                    <div className="benefit-pointdetail">Profile Listed as Top Freelancer</div>
                                                </div>
                                                <div className="benefit-point">
                                                    <img className="w-6" src={checkIcon} alt="" />
                                                    <div className="benefit-pointdetail">View Client Details</div>
                                                </div>
                                                <div className="benefit-point">
                                                    <img className="w-6" src={checkIcon} alt="" />
                                                    <div className="benefit-pointdetail">Live Support</div>
                                                </div>
                                            </div>
                                            <div className="py-2.5 px-3">
                                                <ConfirmButton
                                                    className="bg-versich-light-blue hover:bg-transparent text-white hover:text-versich-light-blue disabled:bg-versich-light-blue/90 disabled:!text-white font-medium w-full py-2 lg:py-2.5 border-2 border-versich-light-blue rounded-lg transition-colors disabled:cursor-not-allowed"
                                                    type="submit"
                                                    loading={loading}
                                                >
                                                    Subscribe
                                                </ConfirmButton>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="text-red-500 text-start mt-2">{errorMsg}</p>
                                </form>
                                : <div className="w-full h-[300px] md:h-[400px] flex justify-center items-center">
                                    <BeatLoader loading={true} color="#1D88ED" size={10} />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            : <ErrorPage status={500} />
    )
}
export default BusinessPricing