import { useSelector, useDispatch } from "react-redux"
import { useForm, FormProvider } from "react-hook-form"
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { FaCheck } from "react-icons/fa"

import locationIcon from "../../../../assets/svgs/location.svg"
import Meta from "../../../../components/Meta"
import BrandBar from "../../../../components/Navbar/BrandBar"
import FormLoader from "../../../../components/loader/FormLoader"
import CSRFTokenField from "../../../../components/Inputs/CSRFTokenField"
import InputText from "../../../../components/Inputs/InputTextField"
import PhoneNumberField, { validateNumber } from "../../../../components/Inputs/PhoneNumberField"
import CountrySelectionField from "../../../../components/Inputs/CountrySelectionField"
import toastify from "../../../../utils/toastify"
import BusinessSetupTracker from "./BusinessSetupTracker"
import { TOASTTYPE_ERROR, businessPaths } from "../../../../assets/constants"
import { businessLocationSetAsync } from "../../../../slices/business/location"

const metadata = {
    title: "Setup - Business Location",
    description: "Fill information about your business to list your business",
    url: `${process.env.REACT_APP_CLIENT_URL}/auth/su/business/location/`
}

const BusinessLocationForm = () => {
    const { user } = useSelector((state) => state.auth)
    const methods = useForm()

    const [csrfToken, setCsrfToken] = useState("")
    const [hqAddressLine1, setHQAddressLine1] = useState("")
    const [hqAddressLine2, setHQAddressLine2] = useState("")
    const [hqPhoneCode, setHQPhoneCode] = useState("")
    const [hqPhoneNumber, setHQPhoneNumber] = useState("")
    const [hqPhoneNumberValid, setHQPhoneNumberValid] = useState(false)
    const [hqCity, setHQCity] = useState("")
    const [hqCountry, setHQCountry] = useState("")
    const [adAddressLine1, setADAddressLine1] = useState("")
    const [adAddressLine2, setADAddressLine2] = useState("")
    const [adPhoneCode, setADPhoneCode] = useState("")
    const [adPhoneNumber, setADPhoneNumber] = useState("")
    const [adPhoneNumberValid, setADPhoneNumberValid] = useState(false)
    const [adCity, setADCity] = useState("")
    const [adCountry, setADCountry] = useState("")
    const [regionError, setRegionError] = useState(false)
    const [sameAddress, setSameAddress] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    // Pre-fill form
    useEffect(() => {
        if (user?.business?.location?.data) {

            // Headquarters
            setHQAddressLine1(user.business.location.data.headquarters.addressLine1)
            setHQAddressLine2(user.business.location.data.headquarters.addressLine2)
            if (user.business.location.data.headquarters.phone) {
                const code = user.business.location.data.headquarters.phone.code.replace("+", "")
                const fullPhoneNumber = code + user.business.location.data.headquarters.phone.number
                setHQPhoneCode(code)
                setHQPhoneNumber(user.business.location.data.headquarters.phone.number)
                setHQPhoneNumberValid(validateNumber(fullPhoneNumber))
            }
            setHQCity(user.business.location.data.headquarters.city)
            setHQCountry(user.business.location.data.headquarters.country.iso3)

            // Additional
            setADAddressLine1(user.business.location.data.additional.addressLine1)
            setADAddressLine2(user.business.location.data.additional.addressLine2)
            if (user.business.location.data.additional.phone) {
                const code = user.business.location.data.additional.phone.code.replace("+", "")
                const fullPhoneNumber = code + user.business.location.data.additional.phone.number
                setADPhoneCode(code)
                setADPhoneNumber(user.business.location.data.additional.phone.number)
                setADPhoneNumberValid(validateNumber(fullPhoneNumber))
            }
            setADCity(user.business.location.data.additional.city)
            setADCountry(user.business.location.data.additional.country.iso3)
        }
    }, [user])

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const customHandleSubmit = (e) => {
        e.preventDefault()
        if (!hqCountry || !adCountry) {
            setRegionError(true)
        }
        methods.handleSubmit(onSubmit)()
    }
    const onSubmit = async (data) => {
        try {
            const isValid = await methods.trigger()
            if (!isValid || !hqPhoneNumberValid || (!sameAddress && !adPhoneNumberValid)) {
                toastify("Form validation failed", TOASTTYPE_ERROR)
                return methods.reset()
            }
            const formData = {
                hqaddressline1: data.hqAddressLine1,
                hqaddressline2: data.hqAddressLine2,
                hqphonecode: `+${hqPhoneCode}`,
                hqphonenumber: hqPhoneNumber,
                hqcity: data.hqCity,
                hqcountryiso3: hqCountry,
                ...(
                    sameAddress
                        ? {
                            adaddressline1: data.hqAddressLine1,
                            adaddressline2: data.hqAddressLine2,
                            adphonecode: `+${hqPhoneCode}`,
                            adphonenumber: hqPhoneNumber,
                            adcity: data.hqCity,
                            adcountryiso3: hqCountry,
                        }
                        : {
                            adaddressline1: data.adAddressLine1,
                            adaddressline2: data.adAddressLine2,
                            adphonecode: `+${adPhoneCode}`,
                            adphonenumber: adPhoneNumber,
                            adcity: data.adCity,
                            adcountryiso3: adCountry,
                        }
                ),
                token: csrfToken,
            }
            setLoading(true)
            await dispatch(businessLocationSetAsync(formData))
                .unwrap()
                .then(() => {
                    navigate(businessPaths.socialprofiles)
                    methods.reset()
                })
                .catch(error => {
                    const data = JSON.parse(error?.message)
                    return setErrorMsg(data.message)
                })
                .finally(() => setLoading(false))
        } catch (error) {
            console.error("Form submission error:", error)
        }
    }
    return (
        <FormProvider {...methods}>
            <div className="bg-[#FBFBFF] min-h-svh">
                <Meta data={metadata} />
                <BrandBar />
                <div className="max_screen_w mb-12 py-8 md:py-10 px-4 md:px-8 lg:px-16">
                    <div className="space-y-3 pb-8 md:pb-10">
                        <h1 className="text-versich-dark-blue text-xl sm:text-2xl text-left font-medium">Business Listings</h1>
                        <p className="text-versich-dark-blue/80 text-xs sm:text-sm text-left font-medium">Complete details below to list your business</p>
                    </div>
                    <div className="flex max-md:flex-col-reverse gap-5">
                        <div className="bg-white w-full mx-auto px-4 smaller:px-8 xl:px-12 py-8 rounded-[11px] shadow-card2">
                            <div className="flex gap-3 pb-4 border-b border-[#EFEEEE99]">
                                <img src={locationIcon} alt="" />
                                <div className="font-medium text-left space-y-[7px]">
                                    <h1 className="text-versich-dark-blue text-lg sm:text-xl">Business Locations</h1>
                                    <p className="text-versich-dark-blue/80 text-xs sm:text-sm">Enter required details below</p>
                                </div>
                            </div>
                            <form onSubmit={customHandleSubmit}>
                                <p className="text-versich-dark-blue text-sm text-left font-medium mt-4">Location - Headquarters</p>
                                <div className="space-y-5 py-7">
                                    <InputText name="hqAddressLine1" inputType="text" placeholder="Enter Address" label="Address Line 1" onChange={(e) => setHQAddressLine1(e.target.value)} defaultValue={hqAddressLine1} rules={{ required: "Address is required" }} />
                                    <InputText name="hqAddressLine2" inputType="text" placeholder="Enter Address" label="Address Line 2" onChange={(e) => setHQAddressLine2(e.target.value)} defaultValue={hqAddressLine2} />
                                    <PhoneNumberField
                                        name="hqphonenumber"
                                        rules={{ required: "Phone number is required" }}
                                        setPhoneCode={setHQPhoneCode}
                                        setPhoneNumber={setHQPhoneNumber}
                                        setPhoneNumberValid={setHQPhoneNumberValid}
                                        value={hqPhoneCode + hqPhoneNumber}
                                    />
                                    <div className="grid grid-cols-1 gap-x-3 gap-5 lg:grid-cols-2">
                                        <InputText name="hqCity" inputType="text" placeholder="Your City" label="City" onChange={(e) => setHQCity(e.target.value)} defaultValue={hqCity} rules={{ required: "City is required" }} />
                                        <CountrySelectionField name="hqCountry" label="Country" selectedCountry={hqCountry} onChange={(e) => setHQCountry(e.target.value)} regionError={regionError} />
                                    </div>
                                    <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                                </div>
                                <p className="text-versich-dark-blue text-sm text-left font-medium pt-5 border-t border-[#C7D0FB]">Additional Location</p>
                                <label className="w-full flex items-center gap-2 mt-5 selection:bg-transparent cursor-pointer group">
                                    <input className="appearance-none" name="sameaddress" type="checkbox" onChange={(e) => setSameAddress(checked => !checked)} />
                                    <div className="inline-flex items-center justify-center w-[18px] h-[18px] border-2 border-versich-blue rounded-md before:absolute before:w-1.5 before:h-1.5 before:group-hover:bg-versich-blue before:rounded-sm">
                                        {sameAddress && <FaCheck className="bg-white fill-versich-blue-hover w-2.5 h-2.5 relative" />}
                                    </div>
                                    <span className="text-versich-dark-blue text-sm font-medium">Same as Headquarter Address Above</span>
                                </label>
                                <div className="space-y-5 py-7">
                                    <InputText name="adAddressLine1" inputType="text" placeholder="Enter Address" label="Address Line 1" onChange={(e) => setADAddressLine1(e.target.value)} defaultValue={sameAddress ? hqAddressLine1 : adAddressLine1} rules={{ required: "Address is required" }} readOnly={sameAddress} />
                                    <InputText name="adAddressLine2" inputType="text" placeholder="Enter Address" label="Address Line 2" onChange={(e) => setADAddressLine2(e.target.value)} defaultValue={sameAddress ? hqAddressLine2 : adAddressLine2} readOnly={sameAddress} />
                                    <PhoneNumberField
                                        name="adphonenumber"
                                        rules={{ required: "Phone number is required" }}
                                        setPhoneCode={setADPhoneCode}
                                        setPhoneNumber={setADPhoneNumber}
                                        setPhoneNumberValid={setADPhoneNumberValid}
                                        value={
                                            sameAddress ? hqPhoneCode + hqPhoneNumber : adPhoneCode + adPhoneNumber
                                        }
                                    />
                                    <div className="grid grid-cols-1 gap-x-3 gap-5 lg:grid-cols-2">
                                        <InputText name="adCity" inputType="text" placeholder="Your City" label="City" onChange={(e) => setADCity(e.target.value)} defaultValue={sameAddress ? hqCity : adCity} rules={{ required: "City is required" }} readOnly={sameAddress} />
                                        <CountrySelectionField name="adCountry" label="Country" selectedCountry={sameAddress ? hqCountry : adCountry} onChange={(e) => setADCountry(e.target.value)} regionError={regionError} readOnly={sameAddress} />
                                    </div>
                                </div>
                                {errorMsg && <div className="text-sm text-red-500 text-left mt-4">{errorMsg}</div>}
                                <div className="text-sm font-semibold w-full flex justify-end gap-5 py-6">
                                    <button className="bg-white hover:opacity-80 text-versich-light-blue border border-versich-light-blue py-2 lg:py-3 px-6 lg:px-10 rounded-lg" type="button" onClick={() => navigate(businessPaths.about)}>Back</button>
                                    <button className="bg-versich-blue hover:bg-versich-blue-hover text-white py-2 lg:py-3 px-6 lg:px-10 rounded-lg" type="submit">Continue</button>
                                </div>
                            </form>
                        </div>
                        <div>
                            <BusinessSetupTracker completed={[
                                "Business Details", "Personal Contact", "About Business",
                                "Business Location"
                            ]} />
                        </div>
                    </div>
                </div>
                {loading && <FormLoader />}
            </div>
        </FormProvider>
    )
}
export default BusinessLocationForm
