import { useEffect, useState } from "react"
import Cookies from "js-cookie"

import PurchasedVersiGemsNotification from "../../../components/Dashboard/notifications/PurchasedVersiGemsNotification"
import { COOKIE_FREELANCER_VERSIGEMS_PURCHASE_COUNT } from "../../../assets/constants"

const PurchasedVersiGems = ({ totalVersiGems, setNotifications }) => {
    const [versiGemsPurchased, setVersiGemsPurchased] = useState(0)

    // Show/Hide purchased 'VersiGems'
    const hideNotification = () => {
        setNotifications(prev => {
            const notification = <PurchasedVersiGemsNotification count={versiGemsPurchased} total={totalVersiGems} hideNotification={hideNotification} />
            return prev.filter(n => n.type.name !== notification.type.name)
        })
    }
    useEffect(() => {
        const versiGemsCount = Cookies.get(COOKIE_FREELANCER_VERSIGEMS_PURCHASE_COUNT)
        if (versiGemsCount) {
            const notification = <PurchasedVersiGemsNotification count={versiGemsPurchased} total={totalVersiGems} hideNotification={hideNotification} />
            setNotifications(prev => [
                ...prev, notification
            ])
            setVersiGemsPurchased(versiGemsCount)
        }
        Cookies.remove(COOKIE_FREELANCER_VERSIGEMS_PURCHASE_COUNT)
    }, [])
    return
}
export default PurchasedVersiGems