import messageIcon from "../../assets/icons/dashboard/message.svg"
import callingIcon from "../../assets/icons/dashboard/calling.svg"

const HelpCard = () => {
    return (
        <div className="bg-white text-start p-4 sm:p-5 shadow-card4 rounded-lg">
            <div className="text-versich-dark-blue font-bold pb-4 border-b border-[#EDF0F5]">Help</div>
            <div className="mt-5 p-1 space-y-6">
                <div className="flex max-smaller:flex-col smaller:items-center gap-[26px] gap-y-3">
                    <div>
                        <div className="bg-brandeis-blue/[0.08] inline-flex items-center justify-center min-w-[43px] min-h-[43px] rounded-[10px]">
                            <img className="w-6 h-6" src={messageIcon} alt="" />
                        </div>
                    </div>
                    <div className="text-versich-dark-blue text-sm w-full">
                        <p className="w-[95%] overflow-hidden inline-flex gap-1 max-smaller:flex-col">Email<a className="text-versich-blue underline underline-offset-2 truncate" href="mailto:Hello@versimarket.com">Hello@versimarket.com</a></p>
                    </div>
                </div>
                <div className="flex max-smaller:flex-col smaller:items-center gap-[26px] gap-y-3">
                    <div>
                        <div className="bg-brandeis-blue/[0.08] inline-flex items-center justify-center min-w-[43px] min-h-[43px] rounded-[10px]">
                            <img className="w-6 h-6" src={callingIcon} alt="" />
                        </div>
                    </div>
                    <div className="text-versich-dark-blue text-sm">
                        <p>Call <a className="text-versich-blue underline underline-offset-2" href="tel:+447782350419">+44 7782 350419</a> open 24 hours a day, 7 days a week</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default HelpCard