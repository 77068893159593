import { useSelector, useDispatch } from "react-redux"
import { useForm, FormProvider } from "react-hook-form"
import { useState, useEffect } from "react"
import { CiLocationOn } from "react-icons/ci"

import curvedEditIcon from "../../../../assets/svgs/curved-edit.svg"
import DialogBox, { closeDialog, getDialog, openDialog } from "../../../DialogBox"
import FormLoader from "../../../loader/FormLoader"
import Close from "../../../svgs/Close"
import CSRFTokenField from "../../../Inputs/CSRFTokenField"
import RadioSelectionField from "../../../Inputs/RadioSelectionField"
import LocationSelectionField from "../../../Inputs/LocationSelectionField"
import { freelancerLeadsLocationUpdateAsync } from "../../../../slices/freelancer/leadsLocation"
import { TOASTTYPE_ERROR, regionCoverage, regionCoverageOptions } from "../../../../assets/constants"
import toastify from "../../../../utils/toastify"

const LeadsLocation = () => {
    const { user } = useSelector((state) => state.auth)
    const methods = useForm()

    const [csrfToken, setCsrfToken] = useState("")
    const [isWorldwide, setIsWorldwide] = useState(true)
    const [selectedCountry, setSelectedCountry] = useState("")
    const [selectedState, setSelectedState] = useState(undefined)
    const [regionError, setRegionError] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    // Pre-fill Form
    const resetForm = () => {
        if (user.leadsLocation) {
            setIsWorldwide(user.leadsLocation.coverage === regionCoverage.worldwide.value)
            setSelectedCountry(user.leadsLocation.country?.iso3 || "")
            setSelectedState(user.leadsLocation.state?.stateCode || "")
        }
    }
    useEffect(() => {
        resetForm()
    }, [user])

    // Close dialog
    const customCloseDialog = (e) => {
        resetForm()
        closeDialog(e)
    }

    const dispatch = useDispatch()
    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const isValid = await methods.trigger()
            if (!isValid) {
                toastify("Form validation failed", TOASTTYPE_ERROR)
                return methods.reset()
            }
            let formData
            if (isWorldwide) {
                formData = {
                    token: csrfToken,
                    coverage: regionCoverage.worldwide.value,
                }
            }
            else {
                if (!selectedCountry || selectedState === "") {
                    setRegionError(true)
                    return
                }
                formData = {
                    coverage: regionCoverage.custom.value,
                    countryiso3: selectedCountry,
                    statecode: selectedState,
                    token: csrfToken,
                }
            }
            setLoading(true)
            await dispatch(freelancerLeadsLocationUpdateAsync(formData))
                .unwrap()
                .then(() => {
                    const dialog = getDialog(e.target)
                    if (dialog) {
                        closeDialog(null, dialog)
                    }
                    methods.reset()
                    return setLoading(false)
                })
                .catch(error => {
                    const data = JSON.parse(error?.message)
                    return setErrorMsg(data.message)
                })
                .finally(() => {
                    setLoading(false)
                    setRegionError(false)
                })
        } catch (error) {
            console.error("Form submission error:", error)
        }
    }
    return (
        user.leadsLocation &&
        <div>
            <div className="text-sm">
                <div className="font-medium flex max-smallest:flex-col smallest:items-start smallest:justify-between gap-x-3 gap-y-1.5">
                    <span className="opacity-80">Location</span>
                    <button className="inline-flex items-center gap-1 hover:opacity-80" onClick={openDialog} data-parent-id={leadsLocationEditDialog}>
                        <img className="w-[18px] h-[18px]" src={curvedEditIcon} alt="" data-parent-id={leadsLocationEditDialog} />
                        <span className="text-versich-light-blue text-xs" data-parent-id={leadsLocationEditDialog}>Edit</span>
                    </button>
                </div>
                <div className="text-sm mt-2.5">
                    {user.leadsLocation.coverage === regionCoverage.worldwide.value
                        ? (<>
                            <p className="text-sm text-versich-label mb-1">You’re receiving customers from</p>
                            <div className="text-versich-dark-blue font-semibold flex items-center gap-x-1">
                                <CiLocationOn className="stroke-versich-dark-blue" />
                                <p className="text-sm">Worldwide</p>
                            </div>
                        </>)
                        : (<>
                            <p className="text-sm text-versich-label mb-1">You’re receiving customers within</p>
                            <div className="text-versich-dark-blue font-semibold flex items-center gap-x-1">
                                <CiLocationOn />
                                <p className="text-sm">{user.leadsLocation.state ? `${user.leadsLocation.state.name}, ` : ""}{user.leadsLocation.country.name}</p>
                            </div>
                        </>)
                    }
                </div>
            </div>
            <DialogBox
                id={leadsLocationEditDialog}
                className="backdrop:bg-[#1717253D] backdrop:backdrop-blur-sm max-sm:max-w-lg"
                toastStacked={false}
                hideClose={true}
                customCloseEvent={customCloseDialog}
            >
                <div className="space-y-[34px] p-2.5 md:p-4">
                    <div className="flex gap-1.5">
                        <div className="text-versich-dark-blue flex-1">
                            <div className="text-xl font-semibold">Choose your location to get leads</div>
                            <div className="text-sm mt-0.5">Manage your location to choose leads</div>
                        </div>
                        <div id="dialog_closebtn" className="text-right">
                            <button type="button" className="m-1 p-1" onClick={customCloseDialog} data-parent-id={leadsLocationEditDialog}>
                                <Close className="w-4 h-4" />
                            </button>
                        </div>
                    </div>
                    <FormProvider {...methods}>
                        <form className="space-y-[34px]" method="dialog" onSubmit={handleSubmit} data-parent-id={leadsLocationEditDialog}>
                            <div className="space-y-3">
                                <RadioSelectionField
                                    selected={
                                        isWorldwide
                                            ? regionCoverage.worldwide.value
                                            : regionCoverage.custom.value
                                    }
                                    options={regionCoverageOptions}
                                    onChange={(value) => {
                                        setIsWorldwide(value === regionCoverage.worldwide.value)
                                    }}
                                />
                                <LocationSelectionField
                                    notWorldwide={!isWorldwide}
                                    country={selectedCountry}
                                    state={selectedState}
                                    onLocationChange={(country, state) => {
                                        setSelectedCountry(country);
                                        setSelectedState(state)
                                    }}
                                    regionError={regionError}
                                />
                                <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                            </div>
                            {errorMsg && <div className="text-red-500 text-sm text-left mb-4">{errorMsg}</div>}
                            <div className="text-sm font-semibold w-full flex max-sm401:flex-col justify-end gap-3 md:gap-5">
                                <button className="bg-white hover:opacity-80 text-versich-light-blue border border-versich-light-blue py-2.5 md:py-3 px-8 md:px-10 rounded-lg" type="button" onClick={customCloseDialog} data-parent-id={leadsLocationEditDialog}>Cancel</button>
                                <button className="bg-versich-blue hover:bg-versich-blue-hover disabled:bg-versich-blue-hover text-white disabled:opacity-70 py-2.5 md:py-3 px-8 md:px-10 rounded-lg disabled:cursor-not-allowed" type="submit">Update</button>
                            </div>
                        </form>
                    </FormProvider>
                </div>
                {loading && <FormLoader />}
            </DialogBox>
        </div>
    )
}
const leadsLocationEditDialog = "leadlocation-edit-dialog"
export default LeadsLocation