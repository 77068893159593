import { createAsyncThunk } from "@reduxjs/toolkit"

import * as api from "../../api"
import apiErrorParser from "../../utils/apiErrorParser"

export const getPublicFreelancerProfileAsync = createAsyncThunk("account/getPublicFreelancerProfile", async (profileid) => {
    try {
        const { data } = await api.getPublicFreelancerProfile(profileid)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const getPublicFreelancerServicesAsync = createAsyncThunk("account/getPublicFreelancerServices", async (profileid) => {
    try {
        const { data } = await api.getPublicFreelancerServices(profileid)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const getPublicFreelancerServiceProjectsAsync = createAsyncThunk("account/getPublicFreelancerServiceProjects", async ({ freelancerId, serviceId }) => {
    try {
        const { data } = await api.getPublicFreelancerServiceProjects(freelancerId, serviceId)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const getPublicFreelancerServiceProjectDetailsAsync = createAsyncThunk("account/getPublicFreelancerServiceProjectDetails", async (id) => {
    try {
        const { data } = await api.getPublicFreelancerServiceProjectDetails(id)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

