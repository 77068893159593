import { useParams, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useState, useEffect } from "react"

import ErrorPage from "../../../../components/ErrorPage/ErrorPage"
import DashboardLayout from "../../../../components/Dashboard/DashboardLayout"
import FormLoader from "../../../../components/loader/FormLoader"
import CSRFTokenField from "../../../../components/Inputs/CSRFTokenField"
import TextFieldInput from "../../../../components/Services/TextFieldInput"
import TextAreaLimitFieldInput from "../../../../components/Services/TextAreaLimitFieldInput"
import DropdownFieldInput from "../../../../components/Services/DropdownFieldInput"
import DateRangeFieldInput from "../../../../components/Services/DateRangeFieldInput"
import ImagePreviewInput from "../../../../components/Services/ImagePreviewInput"
import { FreelancerMenu } from "../../../../components/Dashboard/constants"
import { privateServicesProjectDetailsPath, TOASTTIMER, TOASTTYPE_ERROR } from "../../../../assets/constants"
import { clearBlobImages } from "../../../../components/Services/utils"
import {
    freelancerServiceProjectAllProjectSizeAsync,
    getFreelancerServiceProjectDetailsAsync,
    freelancerUpdateServiceProjectAsync
} from "../../../../slices/freelancer/serviceProject"
import toastify from "../../../../utils/toastify"

const metadata = {
    title: "My Services - Edit Project",
}

const EditServiceProject = () => {
    const { id, pid } = useParams()
    const [project, setProject] = useState(undefined)
    const [csrfToken, setCsrfToken] = useState("")
    const [projectName, setProjectName] = useState("")
    const [projectNameError, setProjectNameError] = useState(false)
    const [desc, setDesc] = useState("")
    const [descError, setDescError] = useState(false)
    const [clientName, setClientName] = useState("")
    const [clientNameError, setClientNameError] = useState(false)
    const [projectSizeOptions, setProjectSizeOptions] = useState([])
    const [projectSize, setProjectSize] = useState("")
    const [projectSizeError, setProjectSizeError] = useState(false)
    const [projectSchedule, setProjectSchedule] = useState({ startDate: null, endDate: null })
    const [projectScheduleError, setProjectScheduleError] = useState(false)
    const [images, setImages] = useState([])
    const [previewUrls, setPreviewUrls] = useState([])
    const [submitted, setSubmitted] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    // Load dropdown values
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(freelancerServiceProjectAllProjectSizeAsync(`?orderby=id`))
            .unwrap()
            .then((data) => {
                setProjectSizeOptions(data.payload)
            })
    }, [])

    // Fill existing project details
    useEffect(() => {
        dispatch(getFreelancerServiceProjectDetailsAsync(pid))
            .unwrap()
            .then(data => {
                setProject(data.payload)
            })
            .catch(() => setProject(null))
    }, [])
    useEffect(() => {
        if (!project) {
            return
        }
        setProjectName(project.projectName)
        setDesc(project.description)
        setClientName(project.clientName)
        setProjectSize(project.projectSize.uid)
        setProjectSchedule({
            startDate: project.projectStart,
            endDate: project.projectEnd
        })
        setPreviewUrls(project.images)
    }, [project])

    useEffect(() => {
        setProjectNameError(submitted && projectName === "")
        setDescError(submitted && desc === "")
        setClientNameError(submitted && clientName === "")
        setProjectSizeError(submitted && projectSize === "")
        setProjectScheduleError(submitted && (projectSchedule.startDate === null || projectSchedule.endDate === null))
    }, [submitted])

    const navigate = useNavigate()
    const serviceProjectDetailsPath = privateServicesProjectDetailsPath.replace(":sid", id).replace(":pid", pid)
    const handleSubmit = async (e) => {
        e.preventDefault()
        const submitBtn = e.target.querySelector("button[type=submit]")
        submitBtn.disabled = true

        // Check for empty values
        setSubmitted(true)
        if (projectName === ""
            || desc === ""
            || clientName === ""
            || projectSize === ""
            || (projectSchedule.startDate === null || projectSchedule.endDate === null)) {
            setTimeout(() => {
                submitBtn.disabled = false
                setSubmitted(false)
            }, TOASTTIMER)
            return toastify("Fill required fields to continue.", TOASTTYPE_ERROR, submitBtn)
        }

        // Post request
        setLoading(true)
        const formData = {
            projectId: pid,
            serviceId: id,
            projectName,
            description: desc,
            clientName,
            projectSizeId: projectSize,
            projectStart: projectSchedule.startDate,
            projectEnd: projectSchedule.endDate,
            images,
            urls: previewUrls,
            token: csrfToken
        }
        await dispatch(freelancerUpdateServiceProjectAsync(formData))
            .unwrap()
            .then(() => navigate(serviceProjectDetailsPath))
            .catch(error => {
                const data = JSON.parse(error?.message)
                return setErrorMsg(data.message)
            })
            .finally(() => {
                setLoading(false)
                submitBtn.disabled = false
                setSubmitted(false)
            })
    }

    // Clear image urls
    useEffect(() => {
        return (
            clearBlobImages()
        )
    }, [])
    return (
        project === null
            ? <ErrorPage status={404} />
            : <DashboardLayout metadata={metadata} navlinks={FreelancerMenu} activePage="My Services">
                <div className="flex flex-col gap-y-2.5 h-full">
                    <div className="bg-white text-start flex-grow py-6 px-4 sm401:px-6 shadow-card4 rounded-lg">
                        <form className="max-w-[1920px] mx-auto sm:px-4 md:px-12 xl:px-20 pt-4 pb-8" onSubmit={handleSubmit}>
                            <div className="text-versich-dark-blue text-center py-4">
                                <div className="text-lg lg:text-xl font-semibold">Edit Project</div>
                                <div className="text-sm mt-1">Change and update project details</div>
                            </div>
                            <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                            <div className="mt-5">
                                <TextFieldInput
                                    name="projectName"
                                    inputType="text"
                                    placeholder="Enter project title"
                                    label="Project Name"
                                    onChange={(e) => setProjectName(e.target.value)}
                                    defaultValue={projectName}
                                    required="Project name is required"
                                    error={projectNameError}
                                />
                            </div>
                            <div className="mt-5">
                                <TextAreaLimitFieldInput
                                    name="description"
                                    label="Description"
                                    placeholder="What you did in the project"
                                    onChange={(e) => { setDescError(false); setDesc(e.target.value) }}
                                    defaultValue={desc}
                                    required="Please enter a description"
                                    error={descError}
                                />
                            </div>
                            <div className="grid grid-cols-1 gap-x-3 gap-5 md:grid-cols-2 mt-5">
                                <TextFieldInput
                                    name="clientName"
                                    inputType="text"
                                    placeholder="Enter client name"
                                    label="Client Name"
                                    onChange={(e) => setClientName(e.target.value)}
                                    defaultValue={clientName}
                                    required="Client name is required"
                                    error={clientNameError}
                                />
                                <DropdownFieldInput
                                    name="projectSize"
                                    label="Project Size"
                                    options={projectSizeOptions}
                                    value={projectSize}
                                    placeholder="Select"
                                    onChange={(e) => setProjectSize(e.target.value)}
                                    required="Project Size is required"
                                    error={projectSizeError}
                                />
                            </div>
                            <div className="mt-5">
                                <DateRangeFieldInput
                                    name="projectSchedule"
                                    label="Project Schedule"
                                    value={projectSchedule}
                                    placeholder="Select Duration"
                                    onChange={val => setProjectSchedule(val)}
                                    required="Project Schedule is required"
                                    error={projectScheduleError}
                                />
                            </div>
                            <div className="space-y-1">
                                <div className="text-versich-dark-blue block font-medium text-start">
                                    <span className="text-base inline-block mt-5 mb-2">Project Photos</span>
                                    <div className="grid grid-cols-2 lg800:grid-cols-4 gap-3">
                                        {[...Array(4)].map((_, index) => (
                                            <ImagePreviewInput name="projectphoto" maxSizeInMb={10} defaultUrl={previewUrls[index]} setDefaultUrls={setPreviewUrls} setFiles={setImages} key={index} />
                                        ))}
                                    </div>
                                </div>
                            </div>
                            {errorMsg && <div className="text-sm text-red-500 text-left mt-8 mb-4">{errorMsg}</div>}
                            <div className="text-sm font-semibold w-full flex max-sm401:flex-col justify-end gap-3 md:gap-5 py-6">
                                <button className="bg-white hover:opacity-80 text-versich-light-blue border border-versich-light-blue py-2.5 md:py-3 px-8 md:px-10 rounded-lg" type="button" onClick={() => navigate(serviceProjectDetailsPath)}>Cancel</button>
                                <button className="bg-versich-blue hover:bg-versich-blue-hover disabled:bg-versich-blue-hover text-white disabled:opacity-70 py-2.5 md:py-3 px-8 md:px-10 rounded-lg disabled:cursor-not-allowed" type="submit">Update</button>
                            </div>
                        </form>
                        {loading && <FormLoader />}
                    </div>
                </div>
            </DashboardLayout >
    )
}
export default EditServiceProject