import { useDispatch } from "react-redux"
import { useForm, FormProvider } from "react-hook-form"
import { useState, useEffect } from "react"
import { formatDistanceToNow } from "date-fns"
import { MdOutlineUpdate } from "react-icons/md"

import fullStarIcon from "../../../assets/svgs/review-star.svg"
import halfStarIcon from "../../../assets/svgs/review-star-half.svg"
import noStarIcon from "../../../assets/svgs/review-star-disabled.svg"
import noReviewsIcon from "../../../assets/images/no-reviews.png"
import AddPlus from "../../svgs/AddPlus"
import Close from "../../svgs/Close"
import DialogBox, { closeDialog, getDialog, openDialog } from "../../DialogBox"
import FormLoader from "../../loader/FormLoader"
import CSRFTokenField from "../../Inputs/CSRFTokenField"
import InputText from "../../Inputs/InputTextField"
import { getReviewAndReferencesAsync, askForReviewAndReferencesAsync } from "../../../slices/reviewAndReferences"
import { TOASTTYPE_ERROR } from "../../../assets/constants"
import toastify from "../../../utils/toastify"

const StarReviews = ({ total = 5, average }) => {
    return (
        <div className="inline-flex gap-2">
            {[...Array(total)].map((item, index) => (
                <img className="w-4 h-4 smaller:w-[18px] smaller:h-[18px] xl:w-[23px] xl:h-[23px]" src={
                    index + 1 <= average
                        ? fullStarIcon
                        : index >= average
                            ? noStarIcon
                            : halfStarIcon
                } alt={total} key={index} />
            ))}
        </div>
    )
}

const ReviewProgressBar = ({ rating, total }) => {
    const progress = rating === 0 || total === 0 ? 0 : (rating / total) * 100
    return (
        <div className="bg-[#FFF9F3] flex-1 h-3 rounded-[17px] overflow-hidden">
            <div className="bg-[#EFA005] h-full rounded-full" style={{ width: `${progress}%` }} />
        </div>
    )
}

const Reviews = ({ data }) => {
    const [reviews, setReviews] = useState(null)
    const methods = useForm()

    const [csrfToken, setCsrfToken] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [email, setEmail] = useState("")
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)
    const [newReview, setNewReview] = useState(false)

    // Load reviews
    const dispatch = useDispatch()
    const getReviews = () => {
        dispatch(getReviewAndReferencesAsync())
            .unwrap()
            .then(data => {
                setReviews(data.payload)
            })
            .catch(() => { })
    }
    useEffect(() => {
        data
            ? setReviews(data)
            : getReviews()
    }, [])

    // Re-load review
    useEffect(() => {
        if (newReview) {
            getReviews()
            setNewReview(false)
        }
    }, [newReview])

    // Pre-fill Form
    const resetForm = () => {
        setFirstName("")
        setLastName("")
        setCompanyName("")
        setEmail("")
    }

    // Close dialog
    const customCloseDialog = (e) => {
        resetForm()
        closeDialog(e)
    }

    const onSubmit = async (data, event) => {
        try {
            const isValid = await methods.trigger()
            if (!isValid) {
                toastify("Form validation failed", TOASTTYPE_ERROR)
                return methods.reset()
            }
            const formData = {
                firstname: data.firstName,
                lastname: data.lastName,
                companyname: data.companyName,
                email: data.businessEmail,
                token: csrfToken,
            }
            setLoading(true)
            await dispatch(askForReviewAndReferencesAsync(formData))
                .unwrap()
                .then((data) => {
                    setNewReview(true)
                    const dialog = getDialog(event.target)
                    if (dialog) {
                        closeDialog(null, dialog)
                    }
                    methods.reset()
                    setLoading(false)
                    return toastify(data.message, data.status, null)
                })
                .catch(error => {
                    const data = JSON.parse(error?.message)
                    return setErrorMsg(data.message)
                })
                .finally(() => setLoading(false))
        } catch (error) {
            console.error("Form submission error:", error)
        }
    }
    return (
        <div className="bg-white text-start p-4 sm:p-5 shadow-card4 rounded-lg">
            <div className="text-versich-dark-blue flex justify-between gap-4 pb-4 border-b border-[#EDF0F5]">
                <span className="font-bold">Reviews</span>
                {!data && <>
                    <button
                        className="text-versich-blue hover:text-versich-blue-hover text-sm font-bold flex items-center gap-1 disabled:cursor-not-allowed disabled:opacity-80 group"
                        type="button"
                        title="Ask for review"
                        onClick={openDialog}
                        data-parent-id={askForReviewDialog}
                    >
                        <AddPlus className="[&>*]:stroke-versich-blue group-hover:[&>*]:stroke-versich-blue-hover smaller:max-lg:w-[18px] smaller:max-lg:h-[18px] w-5 h-5" dataParentId={askForReviewDialog} />
                        <span className="max-smaller:hidden" data-parent-id={askForReviewDialog}>Ask for review</span>
                    </button>
                    <DialogBox
                        id={askForReviewDialog}
                        className="backdrop:bg-[#1717253D] backdrop:backdrop-blur-sm max-sm:max-w-lg"
                        toastStacked={false}
                        hideClose={true}
                        customCloseEvent={customCloseDialog}
                    >
                        <div className="space-y-[34px] p-2.5 md:p-4">
                            <div className="flex gap-1.5">
                                <div className="text-versich-dark-blue flex-1">
                                    <div className="text-xl font-semibold">Ask For Review</div>
                                    <div className="text-sm mt-0.5">Add your client details to send a review request</div>
                                </div>
                                <div id="dialog_closebtn" className="text-right">
                                    <button type="button" className="m-1 p-1" onClick={customCloseDialog} data-parent-id={askForReviewDialog}>
                                        <Close className="w-4 h-4" />
                                    </button>
                                </div>
                            </div>
                            <FormProvider {...methods}>
                                <form className="space-y-[34px]" method="dialog" onSubmit={methods.handleSubmit(onSubmit)} data-parent-id={askForReviewDialog}>
                                    <div className="space-y-3">
                                        <div className="grid grid-cols-1 gap-x-3 gap-5 lg:grid-cols-2">
                                            <InputText name="firstName" inputType="text" placeholder="e.g. Steve" label="First Name" onChange={(e) => setFirstName(e.target.value)} defaultValue={firstName} rules={{ required: "First name is required" }} />
                                            <InputText name="lastName" inputType="text" placeholder="e.g. Rogers" label="Last Name" onChange={(e) => setLastName(e.target.value)} defaultValue={lastName} rules={{ required: "Last name is required" }} />
                                        </div>
                                        <InputText name="companyName" inputType="text" placeholder="e.g. Business Solution" label="Company Name" onChange={(e) => setCompanyName(e.target.value)} defaultValue={companyName} />
                                        <InputText name="businessEmail" inputType="email" placeholder="e.g. ronald@a2zsolutions.com" label="Business Email Address" onChange={(e) => setEmail(e.target.value)} defaultValue={email} rules={{ required: "Email address is required" }} />
                                    </div>
                                    <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                                    {errorMsg && <div className="text-sm text-red-500 text-left mt-8 mb-4">{errorMsg}</div>}
                                    <div className="text-sm font-semibold w-full flex max-sm401:flex-col justify-end gap-3 md:gap-5">
                                        <button className="bg-versich-blue hover:bg-versich-blue-hover disabled:bg-versich-blue-hover text-white disabled:opacity-70 py-2.5 md:py-3 px-8 md:px-10 rounded-lg disabled:cursor-not-allowed" type="submit">Send Request</button>
                                    </div>
                                </form>
                            </FormProvider>
                        </div>
                        {loading && <FormLoader />}
                    </DialogBox>
                </>}
            </div>
            {reviews &&
                <div className="grid md:grid-cols-3 gap-x-6 gap-y-8 mt-5">
                    <div>
                        <div className="bg-[#F6F9FF] text-[#424242] flex flex-col items-center justify-center gap-2 py-8 px-6 rounded-xl">
                            <div className="text-[24px] xl:text-[32px] font-bold p-1">{reviews.average.toFixed(1)}/5.0</div>
                            <div className="inline-flex flex-wrap items-center justify-center gap-2">
                                <StarReviews average={reviews.average} />
                                <p className="text-[12px] xl:text-[18px] font-semibold">({reviews.total} reviews)</p>
                            </div>
                        </div>
                        <div className="my-5 space-y-[15px]">
                            <div className="flex items-center gap-[9px]">
                                <StarReviews average={5} />
                                <ReviewProgressBar rating={reviews.rating["5star"]} total={reviews.total} />
                            </div>
                            <div className="flex items-center gap-[9px]">
                                <StarReviews average={4} />
                                <ReviewProgressBar rating={reviews.rating["4star"]} total={reviews.total} />
                            </div>
                            <div className="flex items-center gap-[9px]">
                                <StarReviews average={3} />
                                <ReviewProgressBar rating={reviews.rating["3star"]} total={reviews.total} />
                            </div>
                            <div className="flex items-center gap-[9px]">
                                <StarReviews average={2} />
                                <ReviewProgressBar rating={reviews.rating["2star"]} total={reviews.total} />
                            </div>
                            <div className="flex items-center gap-[9px]">
                                <StarReviews average={1} />
                                <ReviewProgressBar rating={reviews.rating["1star"]} total={reviews.total} />
                            </div>
                        </div>
                    </div>
                    <div className="text-[#212121] md:col-span-2 space-y-[3px]">
                        {reviews.references.length > 0
                            ? reviews.references.map((ref, index) => (
                                <div className="text-sm space-y-3 p-4 border-b border-[#EEEEEE] last-of-type:border-b-0" key={index}>
                                    {ref.ratings
                                        ? <>
                                            <div className="flex max-smaller:flex-col smaller:items-center justify-between gap-y-1 gap-x-3">
                                                <p className="font-bold mb-[3px]">{ref.name}</p>
                                                <StarReviews average={ref.ratings} />
                                            </div>
                                            <div className="font-medium">{ref.review}</div>
                                            <div className="text-xs">{formatDistanceToNow(ref.updatedAt, { addSuffix: true })}</div>
                                        </>
                                        : <>
                                            <div className="font-bold mb-[3px]">{ref.name}</div>
                                            <div className="text-versich-light-gray flex items-center gap-1">
                                                <MdOutlineUpdate />
                                                <span>Your review request is awaiting response</span>
                                            </div>
                                        </>
                                    }
                                </div>
                            ))
                            : <div className="h-full flex items-center justify-center p-4">
                                <img className="w-[180px] opacity-60" src={noReviewsIcon} alt="no reviews" />
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
    )
}
const askForReviewDialog = "ask-for-review-dialog"
export default Reviews